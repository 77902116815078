import apiConfig from './config';
import helper from '../../utils/helper';

const login = (data, success, error) => {
  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/login`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const logout = (data, success, error) => {
  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/logout`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};


const refresh = (data, success, error) => {
  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/refresh`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

// const logout = () => {
  
// };

const user = (success, error) => {
  const token = helper.getToken();
  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getIpPublica = (success, error) => {
  return fetch(`https://api.ipify.org?format=jsonp?callback=?`, {
    headers: {
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseText)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const callback = (access, success, error) => {
  const data = access;
  const business_id = localStorage.getItem(apiConfig.constants.business_id) || null;
  return fetch(
    `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/login/callback?business_id=${business_id}`,
    {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getToken = (data, success, error) => {
  return fetch(`${apiConfig.constants.URL_API}/oauth/token`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const exportedObject = {
  login,
  refresh,
  logout,
  user,
  callback,
  getToken,
  getIpPublica
};

export default exportedObject;
