import { createStore, applyMiddleware } from 'redux';
import rootReducer from './data/reducer';
import throttle from 'lodash/throttle';
import LocalStorage from './LocalStorage';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';

const LOGGER = 'http://app.ccpll.org:logger';

const logger = (store) => (next) => (action) => {
  if (typeof action === 'object') {
    const serializedState = JSON.stringify(action);
    localStorage.setItem(LOGGER, serializedState);
    next(action);
  }
};

const store = createStore(rootReducer, LocalStorage.loadState(), applyMiddleware(thunk, logger, reduxImmutableStateInvariant()));

store.subscribe(
  throttle(() => {
    LocalStorage.saveState(store.getState());
  }, 1000)
);

export default store;
