import { combineReducers } from 'redux';

import { user } from './user/reducer';
import { configuracion } from './configuracion/reducer';
const rootReducer = combineReducers({
  user,
  configuracion,
});

export default rootReducer;
