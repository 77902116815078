import React, { Component } from 'react';
import { Modal } from 'antd';

import CuotaOrdinariaFormulario from './CuotaOrdinariaFormulario';

class CuotaOrdinariaFormularioModal extends Component {
  handleClose = () => {
    this.props.onCancel();
  };

  handleFormSubmit = (data) => {
    this.props.onFormSubmit(data);
  };


  render() {
    return (
      <div>
        <Modal
          title={this.props.cuota_ordinaria && this.props.cuota_ordinaria.id ? 'Editar Cuota Ordinaria' : 'Nueva Cuota Ordinaria'}
          visible={this.props.visible}
          onCancel={this.handleClose}
          width={500}
          footer={null}
          maskClosable={false}
        >
          <CuotaOrdinariaFormulario
            ref={this.setFormRef}
            onFormSubmit={this.handleFormSubmit}
            onFormCancel={this.handleClose}
            cuota_ordinaria={this.props.cuota_ordinaria}
            guardando={this.props.guardando}
            colegiado_id={this.props.colegiado_id}
            errores={this.props.errores}
          />
        </Modal>
      </div>
    );
  }
}

export default CuotaOrdinariaFormularioModal;
