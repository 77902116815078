import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexPerfil extends Component {
  render() {
    return (
      <div>
        <h2 className="page-header">Perfil Usuario</h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <p>Hola, {this.props.user?.name}</p>
                <div>
                  <b>Correo Eléctronico:</b> {this.props.user?.email}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPerfil);
