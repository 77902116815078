import React, { Component } from 'react';

// import { createStore } from 'redux';
import { Provider } from 'react-redux';
// import rootReducer from './redux/reducers';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Private from './Private/Private';
import Login from './Public/scenes/Login/Login';
import IndexMpv from './Public/scenes/Mpv/index';
import IndexHabilitarColegiatura from './Public/scenes/HabilitarColegiatura/index';
import Independiente from './Public/scenes/FondoMutual/independiente';
import Adelanto from './Public/scenes/FondoMutual/Adelanto';
import CambioBeneficiario from './Public/scenes/FondoMutual/CambioBeneficiario';
import RenunciaSeguroMutual from './Public/scenes/FondoMutual/RenunciaSeguroMutual';
import ShowPagoPendientes from './Private/scenes/PagoPendientes/show';
// HOC
import Authorization from './hoc/authorization';
// Store
import store from './store';
import './App.css';
import './main.less';

class App extends Component {
  render() {
    const AuthFull = Authorization('full');
    return (
      <Provider store={store}>
        <React.StrictMode>
          <Router>
            <Switch>
              <Route exact path="/auth/login" name="Página Inicio" component={Login} />
              <Route exact path="/mpv" name="Formulario Mesa de Partes" component={IndexMpv} />
              <Route
                exact
                path="/habilitar-colegiatura"
                name="Habilitación de Colegiatura"
                component={IndexHabilitarColegiatura}
              />
              <Route
                exact
                path="/habilitar-fondo-mutual/cambio-beneficiario"
                name="Habilitación de Cambio Beneficiario"
                component={CambioBeneficiario}
              />
              <Route exact path="/renuncia-seguro-mutual" name="Renuncia de seguro mutual" component={RenunciaSeguroMutual} />
              <Route
                exact
                path="/habilitar-fondo-mutual/independiente"
                name="Habilitación de Fondo Mutual Idependiente"
                component={Independiente}
              />
              <Route
                exact
                path="/habilitar-fondo-mutual/adelanto"
                name="Habilitación de Fondo Mutual Adelanto"
                component={Adelanto}
              />
              <Route
                path="/pago-pendiente/sinlogin/:id/show"
                name="Detalle Pago Pendientes"
                render={(props) => <ShowPagoPendientes {...props} sinlogin={true} />}
              />
              
              <Route path="/" name="Home" component={AuthFull(Private, 'full')} />
            </Switch>
          </Router>
        </React.StrictMode>
      </Provider>
    );
  }
}

export default App;
