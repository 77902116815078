import React, { Component } from 'react';
import { Table, message, Space, Button, Popconfirm } from 'antd';
import RolFormularioModal from '../../../../components/Roles/RolFormularioModal';
import RolService from '../../../../services/api/roles';
import { QuestionCircleOutlined } from '@ant-design/icons';

class IndexRoles extends Component {
  state = {
    cargando: false,
    roles: [],
    open_modal_rol: false,
    guardando: false,
    page: {
      number: 1,
      per_page: 15,
      order_column: 'name',
      order_type: 'desc',
      from: 0,
      to: 0,
      last_page: 0,
      total: 0,
      buscar_texto: '',
    },
  };

  setFormModal = (ref) => {
    this.formModalRef = ref;
  };

  componentWillUnmount() {
    this.unlisten();
  }

  // UNSAFE_componentWillMount() {
  //   this.unlisten = this.props.history.listen(this.handleChangeLocation);

  //   if (this.props.location && this.props.location.search) {
  //     const queryString = new URLSearchParams(this.props.location.search);
  //     if (queryString) {
  //       const buscar_texto = queryString.get('buscar_texto');
  //       const configPage = Object.assign({}, this.state.page);
  //       configPage.buscar_texto = buscar_texto;
  //       this.setState({
  //         page: configPage,
  //       });
  //     }
  //   }
  // }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleChangeLocation);

    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        const configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage,
        });
      }
    }

    this.cargarRoles();
  }

  cargarRoles = (path = undefined) => {
    this.setState({
      cargando: true,
    });

    if (!path || path === undefined) {
      path = `?page=${this.state.page.number}&per_page=${this.state.page.per_page}&order_column=${this.state.page.order_column}&order_type=${this.state.page.order_type}`;
      if (this.state.page && this.state.page.buscar_texto && this.state.page.buscar_texto !== '') {
        path = `${path}&buscar_texto=${this.state.page.buscar_texto}`;
      }
    }

    RolService.getRoles(
      path,
      (page) => {
        if (page && page.length > 0) {
          const configPage = Object.assign({}, this.state.page);
          configPage.from = page.from;
          configPage.to = page.to;
          configPage.last_page = page.last_page;
          configPage.total = page.total;
          configPage.number = page.current_page;
          configPage.per_page = parseInt(page.per_page);
          configPage.buscar_texto = page.buscar_texto ? page.buscar_texto : '';

          this.setState({
            cargando: false,
            roles: page,
            page: configPage,
          });
        } else {
          this.setState({
            roles: [],
            cargando: false,
          });
        }
      },
      (error, result) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleChangeLocation = (location, action) => {
    const queryString = new URLSearchParams(location.search);
    const buscar_texto = queryString.get('buscar_texto');

    if (buscar_texto && buscar_texto != null) {
      const page = Object.assign({}, this.state.page);

      page.buscar_texto = buscar_texto;

      this.setState({
        page,
      });

      setTimeout(() => {
        this.cargarRoles();
      }, 10);
    }
  };

  handleChangePerPage = (current, pageSize) => {
    const page = Object.assign({}, this.state.page);
    page.number = current;
    page.per_page = pageSize;
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    this.props.history.push(path);
    this.cargarRoles(path);
  };

  showTotal = (total) => {
    return `Total ${total} registros`;
  };

  handleEditarRol = (rol) => {
    this.setState({
      open_modal_rol: true,
      rol,
    });
  };

  handleCreateFormSubmit = (data) => {
    this.setState({ guardando: true });
    if (data.id) {
      RolService.updateRol(
        data,
        (response) => {
          if (response) {
            this.setState({
              guardando: false,
              open_modal_rol: false,
              response,
            });
            this.cargarRoles();
            message.success('Datos actualizados exitosamente');
          }
        },
        (error) => {
          if (error) {
            if (this.formModalRef) {
              this.formModalRef.onFormUploadValidators(error);
            }
            message.error(error.message);
            this.setState({
              guardando: false,
              open_modal_rol: true,
            });
          }
        }
      );
    } else {
      RolService.createRol(
        data,
        (response) => {
          if (response) {
            this.setState({
              guardando: false,
              open_modal_rol: false,
              response,
            });
            this.cargarRoles();
            message.success('Datos guardados exitosamente');
          }
        },
        (error) => {
          if (error) {
            if (this.formModalRef) {
              this.formModalRef.onFormUploadValidators(error);
            }
            message.error(error.message);
            this.setState({
              guardando: false,
              open_modal_rol: true,
            });
          }
        }
      );
    }
  };

  handleClickCloseMarcaModal = (e) => {
    this.setState({
      open_modal_rol: false,
    });
  };

  handleClickOpenRolFormularioModal = () => {
    this.setState({
      rol: {},
      open_modal_rol: true,
      buscar_texto: false,
    });
  };

  handleDelete = (rol_id) => {
    RolService.deleteRol(
      rol_id,
      (response) => {
        if (response) {
          this.setState({
            response,
          });
          this.cargarRoles();
          message.success('La rol seleccionada ha sido eliminada');
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
      }
    );
  };

  render() {
    const columns = [
      {
        title: 'Nombres',
        dataIndex: 'name',
        key: 'name',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => (
          <Space size="middle">
            <a onClick={() => this.handleEditarRol(record)}>Editar</a>
            <Popconfirm
              title={`¿Estas seguro que deseas eliminar ${record.nombre ? record.nombre : ''}？`}
              cancelText="Cancelar"
              okText="Eliminar"
              okType="danger"
              onConfirm={() => {
                this.handleDelete(record.id);
              }}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <div className="link">Eliminar</div>
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 className="page-header">Lista de Roles</h2>
          <div>
            <Button type="primary" size="large" onClick={this.handleClickOpenRolFormularioModal}>
              Nuevo Rol
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={this.state.roles}
                  pagination={false}
                  loading={this.state.cargando}
                  size="small"
                />
                {/* <div style={{ paddingTop: 12, textAlign: 'right' }}>
                  <Pagination
                    size="small"
                    showTotal={ this.showTotal }
                    total={ this.state.page.total }
                    current = { this.state.page.number }
                    pageSize = { this.state.page.per_page }
                    onChange={ this.handleChangePerPage }
                    onShowSizeChange={this.handleChangePerPage}
                    pageSizeOptions={[10,20,30,40]}
                    showQuickJumper
                    showSizeChanger
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {this.state.open_modal_rol ? (
          <RolFormularioModal
            visible={this.state.open_modal_rol}
            guardando={this.state.guardando}
            rol={this.state.rol}
            onFormSubmit={this.handleCreateFormSubmit}
            onCancel={this.handleClickCloseMarcaModal}
            ref={this.setFormModal}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default IndexRoles;
