import React, { Component } from 'react';
import AdelantoFormulario from '../../../components/FondoMutual/AdelantoFormulario';

class Adelanto extends Component {
  render() {
    return (
      <>
        <AdelantoFormulario />
      </>
    );
  }
}

export default Adelanto;
