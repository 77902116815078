import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DropdownFull from '../dropdown/Dropdown';
import NotificationService from '../../services/api/notificaciones';
import user_image from '../../assets/images/tuat.png';
import user_menu from '../../assets/JsonData/user_menus.json';
import configuraciones from '../../assets/JsonData/configuraciones.json';
import { Menu, Dropdown, message, Tooltip } from 'antd';
import LoginService from '../../services/api/login';
import './topnav.css';

class TopNav extends Component {
  state = {
    cargando: false,
    notificaciones: [],
    page: {
      number: 1,
      per_page: 15,
      order_column: 'created_at',
      order_type: 'desc',
      from: 0,
      to: 0,
      last_page: 0,
      total: 0,
      buscar_texto: '',
    },
  };

  componentDidMount() {
    this.setearTexto();

    this.setState({
      cargando: false,
    });
    NotificationService.getNotificaciones(
      '',
      (response) => {
        if (response) {
          this.setState({
            cargando: false,
            notificaciones: response.data,
          });
        }
      },
      (error) => {
        if (error) {
          this.setState({
            cargando: false,
          });
        }
      }
    );
  }

  renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
      <i className="bx bx-error"></i>
      <div>
        <b>
          <span>{item.title}</span>
        </b>
        <br />
        <span>{item.mensaje}</span>
      </div>
    </div>
  );

  renderUserToggle = (user, user_image) => (
    <div className="topnav__right-user" style={{ cursor: 'pointer' }}>
      <div className="topnav__right-user__image">
        <img src={user_image} alt="" />
      </div>
      <div className="topnav__right-user__name">{user.name}</div>
    </div>
  );

  renderConfiguracionToggle = () => (
    <div className="dropdown">
      <button className="dropdown__toggle">
        <i className="bx bx-cog"></i>
      </button>
    </div>
  );

  setearTexto = () => {
    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        const configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage,
        });
      }
    }
  };

  handleSearchClick = (value = '') => {
    const queryString = new URLSearchParams(this.props.location.search);
    if (queryString) {
      const per_page = queryString.get('per_page');
      const number_page = queryString.get('page');
      const order_column = queryString.get('order_column');
      const order_type = queryString.get('order_type');
      const buscar_texto = value;
      const page = Object.assign({}, this.state.page);

      page.number = number_page || this.state.page.number;
      page.per_page = per_page || this.state.page.per_page;
      page.order_column = order_column || this.state.page.order_column;
      page.order_type = order_type || this.state.page.order_type;
      page.buscar_texto = buscar_texto || this.state.page.buscar_texto;

      this.setState({ page });

      let path = `${this.props.location.pathname}?page=${number_page || this.state.page.number}&per_page=${
        per_page || this.state.page.per_page
      }&order_column=${order_column || this.state.page.order_column}&order_type=${order_type || this.state.page.order_type}`;

      if (buscar_texto && buscar_texto !== '') {
        path = `${path}&buscar_texto=${buscar_texto}`;
      }

      this.handleChangeLocation(path);
    }
  };

  handleChangeSearch = (value) => {
    this.handleChangeSearchTemp(value.target.value);
  }

  handleChangeSearchTemp = (value) => {
    const queryString = new URLSearchParams(this.props.location.search);
    if (queryString) {
      const per_page = queryString.get('per_page');
      const number_page = queryString.get('page');
      const order_column = queryString.get('order_column');
      const order_type = queryString.get('order_type');
      const buscar_texto = value;
      const page = Object.assign({}, this.state.page);

      page.number = number_page || this.state.page.number;
      page.per_page = per_page || this.state.page.per_page;
      page.order_column = order_column || this.state.page.order_column;
      page.order_type = order_type || this.state.page.order_type;
      page.buscar_texto = buscar_texto || this.state.page.buscar_texto;

      this.setState({ page });

      let path = `${this.props.location.pathname}?page=${number_page || this.state.page.number}&per_page=${
        per_page || this.state.page.per_page
      }&order_column=${order_column || this.state.page.order_column}&order_type=${order_type || this.state.page.order_type}`;

      if (buscar_texto && buscar_texto !== '') {
        path = `${path}&buscar_texto=${buscar_texto}`;
      }
      this.handleChangeLocation(path);
    }
  };

  handleChangeLocation = (path) => {
    this.props.history.push(path);
  };

  handleClickSalir = (props) => {
    this.handleClickLogout();
    props.history.push('/auth/login');
  };

  handleClickLogout = (props) => {
    const data = {
      mi_ip: localStorage.getItem('ip') || null
    };
  
    LoginService.logout(
      data,
      (success) => {
        if (success) {
          localStorage.clear();
          message.success('Session cerrado correctamente.');
          
        }
      },
      (error) => {
        if (error) {
          localStorage.clear();
          message.error(error.message);
        }
      }
    );
  };

  render() {
    const props = this.props;
    const menuPerfil = (
      <Menu>
        {user_menu.map((item, index) => {
          return (
            <Menu.Item key={index}>
              {item.id === 'cerra_sesion' ? (
                <div onClick={() => this.handleClickSalir(props)} style={{ cursor: 'pointer' }} key={index}>
                  <div style={{ padding: '0.5rem' }}>
                    <i style={{ fontSize: 16 }} className={item.icon}></i>
                    <span style={{ marginLeft: 6 }}>{item.content}</span>
                  </div>
                </div>
              ) : (
                <Link to={item.link} key={index}>
                  <div style={{ padding: '0.5rem' }}>
                    <i style={{ fontSize: 16 }} className={item.icon}></i>
                    <span style={{ marginLeft: 6 }}>{item.content}</span>
                  </div>
                </Link>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const menuConfiguracon = (
      <Menu>
        {configuraciones.map((item, index) => {
          return (
            <Menu.Item key={index}>
              <Link to={item.link} key={index}>
                <div style={{ padding: '0.5rem' }}>
                  <i style={{ fontSize: 16 }} className={item.icon}></i>
                  <span style={{ marginLeft: 6 }}>{item.content}</span>
                </div>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <div className="topnav">
        {/* <div className="topnav__search">
          <input
            type="text"
            placeholder="Buscar..."
            onChange={this.handleChangeSearch}
            value={
              this.props.location && this.props.location.search && this.state.page.buscar_texto
                ? this.state.page.buscar_texto
                : ''
            }
          />
          <i onClick={() => this.handleSearchClick('%')} className="bx bx-search" style={{cursor: 'pointer'}}></i>
        </div> */}
        <div></div>
        <div className="topnav__right">
          <div className="topnav__right-item">
            <Dropdown overlayClassName="auth__menu" overlay={menuPerfil} placement="bottomRight" arrow trigger={['click']}>
              {this.renderUserToggle(this.props.user, user_image)}
            </Dropdown>
          </div>
          <div className="topnav__right-item">
            <Dropdown overlayClassName="auth__menu" overlay={menuConfiguracon} placement="bottomRight" arrow trigger={['click']}>
              <Tooltip title="Configuración">{this.renderConfiguracionToggle()}</Tooltip>
            </Dropdown>
          </div>
          <div className="topnav__right-item">
            <DropdownFull
              icon="bx bx-bell"
              badge="12"
              contentData={this.state.notificaciones}
              renderItems={(item, index) => this.renderNotificationItem(item, index)}
              user_menu={user_menu}
              notificaciones={this.state.notificaciones}
              renderFooter={null}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default React.memo(TopNav);
