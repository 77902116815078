import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { message, Tabs, Row, Col, Tag, Table, Select, Form, Spin, Button, Alert } from 'antd';
import CuotaOrdinariaFormularioModal from '../../../components/PagosPendientes/CuotasOrdinaria/CuotaOrdinariaFormularioModal';
import CuotaFondoFormularioModal from '../../../components/PagosPendientes/CuotasFondo/CuotaFondoFormularioModal';
import FraccionarFormularioModal from '../../../components/Fraccionar/FraccionarFormularioModal';
import ColegiadoService from '../../../services/api/colegiados';
import CuotasOrdinariaService from '../../../services/api/cuotas_ordinaria';
import CuotasFondoService from '../../../services/api/cuotas_fondo';
import { CloseOutlined, PayCircleOutlined } from '@ant-design/icons';
import apiConfig from '../../../services/api/config';
import { cargarConfiguracion } from '../../../data/configuracion/actions';
import store from '../../../store';
import moment from 'moment';
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

class ShowPagoPendientes extends Component {
  state = {
    id: null,
    cargando: false,
    colegiado: {},
    destinatarios: [],
    cuotas_ordinaria: [],
    cuotas_fondo_mutual: [],
    cuota_ordinaria: {},
    cuota_fondo: {},
    active_tab: '1',
    open_modal_cuota_ordinaria: false,
    open_modal_cuota_fondo: false,
    open_modal_raccionar: false,
    guardando_cuota_ordinaria: false,
    guardando_cuota_fondo: false,
    index_cuota_ordinaria: '',
    index_cuota_fondo: '',
    selectedRowKeys: [],
    seleccionados: [],
    detalle_deuda_colegiado: [],
    detalle_pagos_colegiado: [],
    pres_pagar: 0,
    producto: {},
    ordenAscendente: false
  };

  UNSAFE_componentWillMount() {
    if (this.props.location) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const tipo_servicio_id = queryString.get('tipo_servicio_id');
        this.setState({
          tipo_servicio_id,
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.cargarColegiado(this.props.match.params.id);
    }

    if (this.props.sinlogin) {
      store.dispatch(cargarConfiguracion());
    }
  }

  cargarColegiado = (id) => {
    this.setState({
      cargando: true,
      id,
    });

    ColegiadoService.getColegiadoPagoPendiente(
      id,
      (colegiado) => {
        if (colegiado) {
          this.setState({
            cargando: false,
            colegiado,
            cuotas_ordinaria: colegiado?.cuotas_ordinaria,
            cuotas_fondo_mutual: colegiado?.cuotas_fondo_mutual,
            pres_pagar: 0
          }, () => {
            if (this.state.producto_id) {
              const producto = colegiado.productos_deuda_colegiado.find((item) => item.id === this.state.producto_id);
              this.setState({producto});
            }
            if (this.state.tipo_servicio_id) {
              let PROD_DESCRIPCION = '';
              let PROD_DESCRIPCION_TEMP = '';
              if (this.state.tipo_servicio_id === 'FondoMutualDeFallecido') {
                PROD_DESCRIPCION = 'FONDO MORTUORIO';
                PROD_DESCRIPCION_TEMP = 'FONDO MORTUORIO ';
              } else if (this.state.tipo_servicio_id === 'FondoMutual') {
                PROD_DESCRIPCION = 'FONDO MORTUORIO';
                PROD_DESCRIPCION_TEMP = 'FONDO MORTUORIO ';
              } else if (this.state.tipo_servicio_id === 'CuotaOrdinaria') {
                PROD_DESCRIPCION = 'CUOTA ORDINARIA';
                PROD_DESCRIPCION_TEMP = 'CUOTA ORDINARIA ';
              } else if (this.state.tipo_servicio_id === 'Fraccionamiento') {
                PROD_DESCRIPCION = 'FRACCIONAMIENTO';
              }
              if (colegiado.productos_deuda_colegiado && colegiado.productos_deuda_colegiado.length >0) {
                let producto = colegiado.productos_deuda_colegiado.find((item) => item.PROD_DESCRIPCION === PROD_DESCRIPCION);

                const producto_temp = colegiado.productos_deuda_colegiado.find((item) => item.PROD_DESCRIPCION === PROD_DESCRIPCION_TEMP);

                if (producto_temp && producto_temp.id) {
                  producto = producto_temp;
                }

                this.setState({
                    tipo_producto: producto.PROD_DESCRIPCION,
                    tipo_producto_temp: producto.PROD_DESCRIPCION,
                    producto_id: producto.id,
                    PERS_PERSONA_ID: producto.PERS_PERSONA_ID,
                    PROD_PRODUCTO_ID: producto.PROD_PRODUCTO_ID,
                });

                this.setState({producto}, () => {
                  this.cargarDetalleColegiado(true);
                });
              }
            }
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleCreateCuotaOrdinariaFormSubmit = (data) => {
    this.setState({ guardando_cuota_ordinaria: true });
    if (data.id) {
      CuotasOrdinariaService.updateCuotaOrdinaria(
        data,
        (response) => {
          if (response) {
            this.setState({
              guardando_cuota_ordinaria: false,
              open_modal_cuota_ordinaria: false,
              response,
            }, () => {
              this.changeCuotaOrdinaria(response, 'update', this.state.index_cuota_ordinaria);
            });
            message.success('Datos actualizados exitosamente');
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              guardando_cuota_ordinaria: false,
              open_modal_cuota_ordinaria: true,
            });
          }
        }
      );
    } else {
      CuotasOrdinariaService.createCuotaOrdinaria(
        data,
        (response) => {
          if (response) {
            this.setState({
              guardando_cuota_ordinaria: false,
              open_modal_cuota_ordinaria: false,
            }, () => {
              this.changeCuotaOrdinaria(response, 'create', 0);
            });
            // this.cargarUsers();
            message.success('Datos guardados exitosamente');
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              guardando_cuota_ordinaria: false,
              open_modal_cuota_ordinaria: true,
            });
          }
        }
      );
    }
  };


  handleCreateCuotaFondoFormSubmit = (data) => {
    this.setState({ guardando_cuota_fondo: true });
    if (data.id) {
      CuotasFondoService.updateCuotaFondo(
        data,
        (response) => {
          if (response) {
            this.setState({
              guardando_cuota_fondo: false,
              open_modal_cuota_fondo: false,
              response,
            }, () => {
              this.changeCuotaFondo(response, 'update', this.state.index_cuota_fondo);
            });
            message.success('Datos actualizados exitosamente');
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              guardando_cuota_fondo: false,
              open_modal_cuota_fondo: true,
            });
          }
        }
      );
    } else {
      CuotasFondoService.createCuotaFondo(
        data,
        (response) => {
          if (response) {
            this.setState({
              guardando_cuota_fondo: false,
              open_modal_cuota_fondo: false,
            }, () => {
              this.changeCuotaFondo(response, 'create', 0);
            });
            // this.cargarUsers();
            message.success('Datos guardados exitosamente');
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              guardando_cuota_fondo: false,
              open_modal_cuota_fondo: true,
            });
          }
        }
      );
    }
  };

  handleCreateFraccionarFormSubmit = (data) => {
    const seleccionados = [];
    if (this.state.seleccionados && this.state.seleccionados.length > 0) {
      this.state.seleccionados.map(item => {
        if (moment(item.fec_inicio, "DD-MM-YYYY").format('YYYYMMDD') <= moment().format('YYYYMMDD')) {
          seleccionados.push(item);
        }
        return null;
      })
    }

    data.seleccionados = seleccionados;
    data.colegiatura_id = this.state.colegiado.id;
    data.producto_id = this.state.producto_id;
    this.setState({ guardando_fraccionar: true });

    CuotasFondoService.createFraccionamiento(
      data,
      (response) => {
        if (response) {
          this.setState({
            guardando_fraccionar: false,
            open_modal_fraccionar: false,
            seleccionados: [],
            selectedRowKeys: []
          });

          this.cargarDetalleColegiado();
          message.success('Datos guardados exitosamente');
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
          this.setState({
            guardando_fraccionar: false,
            open_modal_fraccionar: true,
          });
        }
      }
    );
  };

  changeCuotaOrdinaria = (response, tipo, index = 0) => {
    const cuotas_ordinaria = [...this.state.cuotas_ordinaria];
    if (tipo === 'create') {
      cuotas_ordinaria.push(response);
      this.setState({
        cuotas_ordinaria
      });
    } else {
      cuotas_ordinaria[index] = response;
      this.setState({
        cuotas_ordinaria
      });
    }
  };

  changeCuotaFondo = (response, tipo, index = 0) => {
    const cuotas_fondo_mutual = [...this.state.cuotas_fondo_mutual];
    if (tipo === 'create') {
      cuotas_fondo_mutual.push(response);
      this.setState({
        cuotas_fondo_mutual
      });
    } else {
      cuotas_fondo_mutual[index] = response;
      this.setState({
        cuotas_fondo_mutual
      });
    }
  };

  handleOpenModalCuotaOrdinaria = () => {
    this.setState({
      open_modal_cuota_ordinaria: true
    });
  };

  handleClickCloseModalCuotaOrdinaria = (e) => {
    this.setState({
      open_modal_cuota_ordinaria: false,
    });
  };

  handleEditarCuotaOrdinaria = (cuota_ordinaria, index) => {
    this.setState({
      open_modal_cuota_ordinaria: true,
      cuota_ordinaria,
      index_cuota_ordinaria: index
    });
  };

  handleOpenModalCuotaFondo = () => {
    this.setState({
      open_modal_cuota_fondo: true
    });
  };

  handleClickCloseModalCuotaFondo = (e) => {
    this.setState({
      open_modal_cuota_fondo: false,
    });
  };

  handleEditarCuotaFondo = (cuota_fondo, index) => {
    this.setState({
      open_modal_cuota_fondo: true,
      cuota_fondo,
      index_cuota_fondo: index
    });
  };

  handleOpenModalFraccionar = () => {
    if (this.state.detalle_deuda_colegiado && this.state.seleccionados && this.state.detalle_deuda_colegiado.length !== this.state.seleccionados.length) {
      message.error('Debe seleccionar todas tus cuotas pendientes.');
      return true;
    }
    if (this.state.tipo_producto_temp === 'FRACCIONAMIENTO') {
      message.error('No se puede fraccionar es un producto de FRACCIONAMIENTO');
      return true;
    }
    if (this.state.seleccionados.length > 0) {
      this.setState({
        open_modal_fraccionar: true
      });  
    } else {
      message.error('Debe seleccionar registros de la tabla pendientes');
    }
    
  };

  handleClickCloseModalFraccionar = (e) => {
    this.setState({
      open_modal_fraccionar: false,
    });
  };

  handleChangeTipoProducto = (value, opt) => {
    const { producto } = opt.props;

    this.setState({
      tipo_producto: value,
      tipo_producto_temp: producto.PROD_DESCRIPCION,
      producto_id: producto.id,
      PERS_PERSONA_ID: producto.PERS_PERSONA_ID,
        PROD_PRODUCTO_ID: producto.PROD_PRODUCTO_ID,
      cargando_detalle: true,
      seleccionados: [],
      selectedRowKeys: [],
      pres_pagar: 0,
      producto
    }, () => {
        this.cargarDetalleColegiado();
    });
  }

  handleActualizar = () => {
    this.cargarDetalleColegiado();
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.cargarColegiado(this.props.match.params.id);
    }
  }

  cargarDetalleColegiado = (es_negocios = false) => {
    this.setState({
      cargando_detalle: true,
    });
    ColegiadoService.getColegiadoDetalle(
      {
        id: this.state.colegiado.id ? this.state.colegiado.id: null,
        PERS_PERSONA_ID: this.state.PERS_PERSONA_ID,
        PROD_PRODUCTO_ID: this.state.PROD_PRODUCTO_ID,
        producto_id: this.state.producto_id,
        es_negocios
      },
      (detalle) => {
        if (detalle) {
          this.setState({
            cargando_detalle: false,
            detalle_deuda_colegiado: detalle.detalle_deuda_colegiado,
            detalle_pagos_colegiado: detalle.detalle_pago_colegiado,
            selectedRowKeys: [],
            seleccionados : [],
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando_detalle: false,
          });
        }
      }
    );
  }

  onSelectChange = (selectedRowKeys, seleccionados) => {
    let ordenAscendente = true;
    for (let i = 0; i < selectedRowKeys.length; i++) { 
      if (selectedRowKeys[i] !== i) { 
        ordenAscendente = false;
        break; 
      } 
    }

    this.setState({ selectedRowKeys, seleccionados, ordenAscendente });
  };

  handleAdelantoEfectivo = () => {
    let url_producto = '';
    if (this.state.tipo_producto_temp === 'FONDO MORTUORIO ') {
      url_producto = this.props.configuracion?.adelanto_fondo_mortuorio;
    }
    if (this.state.tipo_producto_temp === 'FONDO MORTUORIO') {
      url_producto = this.props.configuracion?.adelanto_fondo_mortuorio;
    }

    const href = `${url_producto}&colegiado_id=${this.state.colegiado?.id}&numero_documento=${this.state.colegiado.numero_documento}&codigo_afiliado=${this.state.colegiado.codigo_afiliado}&colegio_producto_id=${this.state.producto_id}`;

    window.open(href);
  }

  handlePagoEfectivo = () => {
    const seleccionados = [];
    if (this.state.seleccionados && this.state.seleccionados.length > 0) {
      this.state.seleccionados.map((item) => {
        seleccionados.push({
          DEUDA: item.DEUDA,
          FEC_EMISION: item.FEC_EMISION,
          SERIE: item.SERIE,
          colegiatura_id: item.colegiatura_id,
          deleted_at: item.deleted_at,
          es_fraccionamiento_especial: item.es_fraccionamiento_especial,
          es_negocios: item.es_negocios,
          es_pagado: item.es_pagado,
          id: item.id,
          key: item.key,
          producto_id: item.producto_id,
          updated_at: item.updated_at,
        });
        return item;
      });
    }

    // if (seleccionados.length > 100) {
    //   message.error('Seleccionar menor a 100.');
    //   return true;
    // }

    if (this.state.pres_pagar > 0) {
      message.error('Para continuar, por favor actualiza.');
      return true;
    }
    if (!this.state.ordenAscendente) {
      message.error('Por favor seleccionar tus cuotas pendientes en orden.');
      return true;
    }
    if (this.state.seleccionados && this.state.seleccionados.length > 0) {
      const result = this.state.seleccionados.find(item => item.es_fraccionamiento_especial === 1);

      if(result && result.id) {
        if (this.state.seleccionados.length > 1) {
          message.error('Primero debe pagar el inicial del fraccionamiento.');
          return true;
        }
      }
    }

    if (this.state.seleccionados.length > 0) {

      let url_producto = '';
      if (this.state.tipo_producto_temp === 'CUOTA ORDINARIA') {
        url_producto = this.props.configuracion.cuota_ordinaria ? this.props.configuracion.cuota_ordinaria: null;
      } else if (this.state.tipo_producto_temp === 'FRACCIONAMIENTO') {
        url_producto = this.props.configuracion.fraccionamiento ? this.props.configuracion.fraccionamiento: null;
      } else if (this.state.tipo_producto_temp === 'FONDO MORTUORIO ') {
        url_producto = this.props.configuracion.fondo_mortuorio ? this.props.configuracion.fondo_mortuorio: null;
      } else if (this.state.tipo_producto_temp === 'FONDO MORTUORIO') {
        url_producto = this.props.configuracion.fondo_mortuorio ? this.props.configuracion.fondo_mortuorio: null;
      }

      ColegiadoService.createPagoCache({
        pendientes: seleccionados,
        id: this.state.colegiado?.id
      },  (detalle) => {
        console.log(detalle);
      });
      
      const href = `${url_producto}&colegiado_id=${this.state.colegiado?.id}&numero_documento=${this.state.colegiado.numero_documento}&colegio_producto_id=${this.state.producto_id}&sinlogin=${this.props.sinlogin}&pagar_por=${this.state.tipo_producto}&adelanto=${this.state.producto.ADELANTO ? this.state.producto.ADELANTO: 0}`;

      this.setState({
        pres_pagar: 1
      });
      window.open(href);
    } else {
      message.error('Debe seleccionar registros de la tabla pendientes');
    }
  }

  handleExportar = (tipo) => {
    const page = Object.assign({}, this.state.page);
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&estado=${this.state.estado}&de=${this.state.de_formato}&hasta=${this.state.hasta_formato}`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    const token =  localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    const url_exportar = `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/detalles-pago-colegiado/${this.state.producto_id}/xls${path}&authorization=${token}`;
    window.open(url_exportar);
  }

  render() {
    const { colegiado } = this.state;

    const detalle_deuda_colegiado = [];
    let total_pendiente = 0;
    if (this.state.detalle_deuda_colegiado && this.state.detalle_deuda_colegiado.length > 0) {
      this.state.detalle_deuda_colegiado.map((item, index) => {
        item.key = index;
        total_pendiente += parseFloat(item.DEUDA);
        detalle_deuda_colegiado.push(item);
        return null;
      });
    }

    let total_pago = 0;
    if (this.state.detalle_pagos_colegiado && this.state.detalle_pagos_colegiado.length > 0) {
      this.state.detalle_pagos_colegiado.map((item, index) => {
        total_pago += parseFloat(item.IMPORTE);
        return null;
      });
    }
    
    // const columns_cuota_ordinaria = [
    //   {
    //     title: 'Fecha Emisión',
    //     dataIndex: 'fecha_emision',
    //     key: 'fecha_emision',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Comprobante',
    //     dataIndex: 'comprobante',
    //     key: 'comprobante',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Monto',
    //     dataIndex: 'monto',
    //     key: 'monto',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Comentario',
    //     dataIndex: 'comentario',
    //     key: 'comentario',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Acción',
    //     dataIndex: 'action',
    //     key: 'action',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //     render: (text, record, index) => (
    //       <Space size="middle">
    //         <a style={{ color: '#0045ad' }} onClick={() => this.handleEditarCuotaOrdinaria(record, index)}>Editar</a>
    //       </Space>
    //     ),
    //   },
    // ];

    // const columns_cuota_fondo_mutual = [
    //   {
    //     title: 'Fecha Emisión',
    //     dataIndex: 'fecha_emision',
    //     key: 'fecha_emision',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Comprobante',
    //     dataIndex: 'comprobante',
    //     key: 'comprobante',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Monto',
    //     dataIndex: 'monto',
    //     key: 'monto',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Comentario',
    //     dataIndex: 'comentario',
    //     key: 'comentario',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //   },
    //   {
    //     title: 'Acción',
    //     dataIndex: 'action',
    //     key: 'action',
    //     responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    //     render: (text, record, index) => (
    //       <Space size="middle">
    //         <a style={{ color: '#0045ad' }} onClick={() => this.handleEditarCuotaFondo(record, index)}>Editar</a>
    //       </Space>
    //     ),
    //   },
    // ];

    const columns_ultimo_pago = [
      {
        title: 'SITUACIÓN',
        dataIndex: 'computed1',
        key: 'computed1',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'ÚLTIMO PAGO',
        dataIndex: 'Fecha_Pago_temp',
        key: 'Fecha_Pago_temp',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'ÚLTIMO VENCIMIENTO',
        dataIndex: 'Fecha_Vencimiento_temp',
        key: 'Fecha_Vencimiento_temp',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'ADELANTO',
        dataIndex: 'ADELANTO',
        key: 'ADELANTO',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
    ];

    let columns_pendiente = [];
    if (this.state.tipo_producto_temp === 'FRACCIONAMIENTO') {
      columns_pendiente = [
        {
          title: 'FEC. ORDEN',
          dataIndex: 'FEC_EMISION',
          key: 'FEC_EMISION',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'SERIE',
          dataIndex: 'SERIE',
          key: 'SERIE',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'NRO. CORRELATIVO',
          dataIndex: 'CORREL',
          key: 'CORREL',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'PRODUCTO',
          dataIndex: 'DESCRIPCION',
          key: 'DESCRIPCION',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: `TOTAL ${total_pendiente.toFixed(2)}`,
          dataIndex: 'DEUDA',
          key: 'DEUDA',
          className: 'text-right',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
      ];
    } else {
      columns_pendiente = [
        {
          title: 'FEC. ORDEN',
          dataIndex: 'fec_inicio',
          key: 'fec_inicio',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'FEC. VENCIMIENTO',
          dataIndex: 'FEC_EMISION',
          key: 'FEC_EMISION',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'SERIE',
          dataIndex: 'SERIE',
          key: 'SERIE',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'NRO. CORRELATIVO',
          dataIndex: 'CORREL',
          key: 'CORREL',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'PRODUCTO',
          dataIndex: 'DESCRIPCION',
          key: 'DESCRIPCION',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: `TOTAL ${total_pendiente.toFixed(2)}`,
          dataIndex: 'DEUDA',
          key: 'DEUDA',
          className: 'text-right',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
      ];
    }

    const columns_pago = [
      {
        title: 'AÑO',
        dataIndex: 'ANIO',
        key: 'ANIO',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'FECHA RECIBO',
        dataIndex: 'FECHA_EMISION',
        key: 'FECHA_EMISION',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'FECHA_VENCIMIENTO',
        key: 'FECHA_VENCIMIENTO',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'SERVICIO',
        dataIndex: 'SERVICIO',
        key: 'SERVICIO',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'IMPORTE DEUDA',
        dataIndex: 'IMPORTE_DEUDA',
        key: 'IMPORTE_DEUDA',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'SALDO',
        dataIndex: 'SALDO',
        key: 'SALDO',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'FECHA PAGO',
        dataIndex: 'FECHA_PAGO',
        key: 'FECHA_PAGO',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'COMPROBANTE',
        dataIndex: 'NRO_COMPROBANTE',
        key: 'NRO_COMPROBANTE',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: `IMPORTE PAGO ${total_pago.toFixed(2)}`,
        dataIndex: 'IMPORTE',
        key: 'IMPORTE',
        className: 'text-right',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    let importe_total = 0;
    if(this.state.seleccionados.length > 0) {
      this.state.seleccionados.map((item) => {
        if (moment(item.fec_inicio, "DD-MM-YYYY").format('YYYYMMDD') <= moment().format('YYYYMMDD')) {
          importe_total += parseFloat(item.DEUDA);
        }
        return null;
      })
    }

    return (
      <div>

        <Spin spinning={this.state.cargando}>
          {
            this.props.sinlogin ?
            <>
              <div className="colegiatura__header">
                <div style={{ maxWidth: 100, margin: 'auto' }}>
                  <img src="https://www.ccpll.org/assets/img/logo.png" alt="Logo" />
                </div>
              </div>
              <div style={{ maxWidth: 950, margin: 'auto', paddingTop: 16 }}>
                <Row>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <FormItem label="Producto" required={false}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        value={this.state.tipo_producto}
                        onChange={this.handleChangeTipoProducto}
                        disabled={true}
                        notFoundContent={'No se ha encontrado registros'}
                        id="tipo_producto"
                        autofocus="true"
                      >
                        {
                          colegiado &&
                          colegiado.productos_deuda_colegiado &&
                          colegiado.productos_deuda_colegiado.length > 0 &&
                          colegiado.productos_deuda_colegiado.map((item, index) => {
                            return (
                              <Option key={index} producto={item} value={`${item.PROD_DESCRIPCION} ${item.computed1 ? '-': ''} ${item.computed1}`}>
                                {item.PROD_DESCRIPCION} {item.computed1 ? '-': ''} {item.computed1} {item.es_negocios ? '': ''}
                              </Option>
                            );
                          })
                        }
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6, justifyContent: 'end' }}>
                      {
                        <Button onClick={this.handlePagoEfectivo} type="primary" shape="round" icon={<PayCircleOutlined />} size={null}>
                          Pagar 
                        </Button>
                      }
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <h2><b>Pendientes ({detalle_deuda_colegiado.length}):</b></h2>
                      {
                        !this.state.ordenAscendente && this.state.seleccionados.length > 0 &&
                        <Alert message="Por favor seleccionar tus cuotas pendientes en orden." type="error" showIcon />
                      }
                      <Table
                          style={{ marginTop: 12 }}
                          columns={columns_pendiente}
                          rowSelection={rowSelection}
                          rowKey={(record) => record.key}
                          dataSource={detalle_deuda_colegiado}
                          pagination={false}
                          size="small"
                          loading={this.state.cargando_detalle}
                          locale={{
                            emptyText: 'No se ha encontrado registros',
                          }}
                        />
                    </Col>
                </Row>
              </div>
            </>: 
            <>
              <div className="page-header">
                <h2 className="page-header">{colegiado.nombres ? colegiado.nombres : 'Detalle Gestión De Solicitudes De Colegiatura'}</h2>
                {/* <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6 }}>
                  {
                    this.state.tipo_producto_temp === 'FONDO MORTUORIO ' &&
                    <div>
                      <Button onClick={this.handleAdelantoEfectivo} type="primary" shape="round" icon={<PayCircleOutlined />} size={null}>
                        Generar Adelanto
                      </Button>
                    </div>
                  }
                  <div>
                    {
                      this.state.tipo_producto_temp && 
                      <Button onClick={this.handlePagoEfectivo} type="primary" shape="round" icon={<PayCircleOutlined />} size={null}>
                        Pagar 
                      </Button>
                    }
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card__body">
                      <div
                        style={{
                          position: 'absolute',
                          right: '48px',
                          fontSize: '20px',
                          zIndex: 10,
                        }}
                      >
                        <Link to={`/pago-pendientes`}>
                          <CloseOutlined />
                        </Link>
                      </div>
                      <Tabs defaultActiveKey={this.state.active_tab}>
                        <TabPane tab="Información General" key="1">
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="line-height-28">
                              <div>
                                <b>Estado: </b>
                                <Tag
                                  color={
                                    colegiado.estado_afiliado_formato === 'HABIL'
                                      ? 'green'
                                      : colegiado.estado_afiliado_formato === 'VITALICIO'
                                      ? 'red'
                                      : colegiado.estado_afiliado_formato === 'INHABILITADO POR DEUDA'
                                      ? 'blue'
                                      : ''
                                  }
                                >
                                  {colegiado.estado_afiliado_formato}
                                </Tag>
                              </div>
                              <div>
                                <b>RUC/DNI: </b> {colegiado.numero_documento}
                              </div>
                              <div>
                                <b>Nombres: </b>{' '}
                                {colegiado.nombres}
                              </div>
                              <div>
                                <b>Código Afiliado : </b> {colegiado.codigo_afiliado}
                              </div>
                              <div>
                                <b>Fecha Colegiatura: </b> {colegiado.fecha_colegiatura_1_temp}
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="Pagos Pendientes" key="2">
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <FormItem label="Producto" required={false}>
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  value={this.state.tipo_producto}
                                  onChange={this.handleChangeTipoProducto}
                                  disabled={this.state.es_duplicar}
                                  notFoundContent={'No se ha encontrado registros'}
                                  id="tipo_producto"
                                  autofocus="true"
                                >
                                  {
                                    colegiado &&
                                    colegiado.productos_deuda_colegiado &&
                                    colegiado.productos_deuda_colegiado.length > 0 &&
                                    colegiado.productos_deuda_colegiado.map((item, index) => {
                                      return (
                                        <Option key={index} producto={item} value={`${item.PROD_DESCRIPCION} ${item.computed1 ? '-': ''} ${item.computed1}`}>
                                          {item.PROD_DESCRIPCION} {item.computed1 ? '-': ''} {item.computed1} {item.es_negocios ? '': ''}
                                        </Option>
                                      );
                                    })
                                  }
                                </Select>
                              </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6, justifyContent: 'end' }}>
                                <Button type='primary' shape="round" loading={this.state.cargando_detalle} style={{ background: 'green', border: 'green' }} onClick={this.handleActualizar}>Actualizar</Button>
                                {
                                  this.state.tipo_producto_temp === 'CUOTA ORDINARIA' &&
                                  <Button type='primary' shape="round" onClick={this.handleOpenModalFraccionar}>Fraccionar</Button>
                                }
                                
                                {
                                  this.state.tipo_producto_temp === 'FONDO MORTUORIO' &&
                                    <Button onClick={this.handleAdelantoEfectivo} type="primary" shape="round" icon={<PayCircleOutlined />} size={null}>
                                      Generar Adelanto
                                    </Button>
                                }
                                {
                                  this.state.tipo_producto_temp === 'FONDO MORTUORIO ' &&
                                    <Button onClick={this.handleAdelantoEfectivo} type="primary" shape="round" icon={<PayCircleOutlined />} size={null}>
                                      Generar Adelanto
                                    </Button>
                                }
                                {
                                  this.state.tipo_producto_temp && 
                                  <Button onClick={this.handlePagoEfectivo} type="primary" shape="round" icon={<PayCircleOutlined />} size={null}>
                                    Pagar 
                                  </Button>
                                }
                            </div>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <h2><b>Último Pago:</b></h2>
                              <Table
                                  style={{ marginTop: 12 }}
                                  rowKey={(record) => record.id}
                                  columns={columns_ultimo_pago}
                                  dataSource={[this.state.producto]}
                                  pagination={false}
                                  size="small"
                                  locale={{
                                    emptyText: 'No se ha encontrado registros',
                                  }}
                                />
                            </Col>
                            
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <br/>
                              <h2><b>Pendientes ({detalle_deuda_colegiado.length}):</b></h2>
                              {
                                !this.state.ordenAscendente && this.state.seleccionados.length > 0 &&
                                <Alert message="Por favor seleccionar tus cuotas pendientes en orden." type="error" showIcon />
                              }
                              <Table
                                  style={{ marginTop: 12 }}
                                  columns={columns_pendiente}
                                  rowSelection={rowSelection}
                                  rowKey={(record) => record.key}
                                  dataSource={detalle_deuda_colegiado}
                                  pagination={false}
                                  size="small"
                                  loading={this.state.cargando_detalle}
                                  locale={{
                                    emptyText: 'No se ha encontrado registros',
                                  }}
                                />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="Historial de pagos" key="3">
                          <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <FormItem label="Producto" required={false}>
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  value={this.state.tipo_producto}
                                  onChange={this.handleChangeTipoProducto}
                                  disabled={this.state.es_duplicar}
                                  notFoundContent={'No se ha encontrado registros'}
                                  id="tipo_producto"
                                  autofocus="true"
                                >
                                  {
                                    colegiado &&
                                    colegiado.productos_deuda_colegiado &&
                                    colegiado.productos_deuda_colegiado.length > 0 &&
                                    colegiado.productos_deuda_colegiado.map((item, index) => {
                                      return (
                                        <Option key={index} producto={item} value={`${item.PROD_DESCRIPCION} ${item.computed1 ? '-': ''} ${item.computed1}`}>
                                          {item.PROD_DESCRIPCION} {item.computed1 ? '-': ''} {item.computed1} {item.es_negocios ? '': ''}
                                        </Option>
                                      );
                                    })
                                  }
                                </Select>
                              </FormItem>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                              <div style={{ display: 'flex', justifyContent: 'end', gap: 8 }}>
                              <Button type='primary' shape="round" loading={this.state.cargando_detalle} style={{ background: 'green', border: 'green' }} onClick={this.handleActualizar}>Actualizar</Button>
                              <Button type='primary' shape="round" onClick={() => this.handleExportar('xls')}>
                                Exportar XLS
                              </Button>
                              </div>
                            </Col>
                            
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <Table
                                  style={{ marginTop: 12 }}
                                  columns={columns_pago}
                                  rowKey={(record) => record.key}
                                  dataSource={this.state.detalle_pagos_colegiado}
                                  pagination={false}
                                  size="small"
                                  loading={this.state.cargando_detalle}
                                  locale={{
                                    emptyText: 'No se ha encontrado registros',
                                  }}
                                />
                            </Col>
                          </Row>
                        </TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </Spin>
        {
          this.state.open_modal_cuota_ordinaria ? (
          <CuotaOrdinariaFormularioModal
            visible={this.state.open_modal_cuota_ordinaria}
            guardando={this.state.guardando_cuota_ordinaria}
            cuota_ordinaria={this.state.cuota_ordinaria}
            onFormSubmit={this.handleCreateCuotaOrdinariaFormSubmit}
            onCancel={this.handleClickCloseModalCuotaOrdinaria}
            colegiado_id={ this.state.id }
            ref={this.setFormModal}
          />
          ) : (
            ''
          )
        }

        {
          this.state.open_modal_cuota_fondo ? (
          <CuotaFondoFormularioModal
            visible={this.state.open_modal_cuota_fondo}
            guardando={this.state.guardando_cuota_fondo}
            cuota_fondo={this.state.cuota_fondo}
            onFormSubmit={this.handleCreateCuotaFondoFormSubmit}
            onCancel={this.handleClickCloseModalCuotaFondo}
            colegiado_id={ this.state.id }
            ref={this.setFormModal}
          />
          ) : (
            ''
          )
        }
        {
          this.state.open_modal_fraccionar ? (
          <FraccionarFormularioModal
            visible={this.state.open_modal_fraccionar}
            guardando={this.state.guardando_fraccionar}
            fraccionar={this.state.fraccionar}
            onFormSubmit={this.handleCreateFraccionarFormSubmit}
            onCancel={this.handleClickCloseModalFraccionar}
            colegiado_id={ this.state.id }
            importe_total={importe_total}
            ref={this.setFormFraccionarModal}
          />
          ) : (
            ''
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
    configuracion: state && state.configuracion ? state.configuracion : {},
    permisos: state && state.user && state.user.permisos && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowPagoPendientes);
