import apiConfig from './config';

const getMesaPartesAtendidos = (queryString, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/dashboard/atenciones${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getColegiados = (queryString, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/dashboard/colegiados${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const exportedObject = {
  getMesaPartesAtendidos,
  getColegiados,
};

export default exportedObject;
