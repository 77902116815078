const constants = {
  URL_API: 'https://admin-ccpll.net',
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  ACCESS_TOKEN_EXPIRES_IN: 'expires_in',
  ACCESS_TOKEN_EXPIRES_AT: 'expires_at',
  USUARIO_ID: 'USUARIO_ID',
  API_VERSION: 'v1',
  URL_CUENTA_CONTASISCORP: 'https://cuenta.contasiscorp.com',
  URL_NEGOCIOS: 'https://b4e69e20-ae3f-11ee-adec-79a92a165e15.empresaonline.contasiscorp.com',
  EMPRESA_ID: '471d1600-ae40-11ee-b5d2-5f2c0e779fc8',
};

const exportedObject = {
  constants,
};

export default exportedObject;
