import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import ReciboPagoFormulario from '../../../components/ReciboPagos/ReciboPagoFormulario';
import ReciboPagoService from '../../../services/api/recibo_pagos';

class ShowReciboPagos extends Component {
  state = {
    id: null,
    cargando: false,
    recibo_pago: {},
    destinatarios: [],
    cc: [],
    colegiado_id: '',
    tipo_recibo_pago_id: '',
    es_cargado_page: false,
    colegiado_formato: '',
    cliente_id: '',
  };

  componentDidMount() {
    if (this.props.location) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const colegiado_id = queryString.get('colegiado_id');
        const tipo_recibo_pago_id = queryString.get('tipo_recibo_pago_id');
        const cliente_numero_documento = queryString.get('cliente_numero_documento');
        const cliente_nombres = queryString.get('cliente_nombres');
        const porcentaje = queryString.get('porcentaje');
        const colegiado_formato = queryString.get('colegiado_formato');
        const cliente_id = queryString.get('cliente_id');

        this.setState({
          colegiado_id,
          tipo_recibo_pago_id,
          cliente_numero_documento,
          cliente_nombres,
          porcentaje,
          es_cargado_page: true,
          colegiado_formato,
          cliente_id,
        });
      }
    }

    // if (this.props.match && this.props.match.params && this.props.match.params.id) {
    //   this.cargarReciboPago(this.props.match.params.id);
    // }
  }

  setForm = (ref) => {
    this.formRef = ref;
  };

  cargarReciboPago = (id) => {
    this.setState({
      cargando: true,
      id,
    });

    ReciboPagoService.getReciboPago(
      id,
      (recibo_pago) => {
        if (recibo_pago) {
          let subject = '';
          let body = '';
          let template = '';
          let destinatario_principal = [];
          const copias = [];

          subject = `Tu ticket de atención ${recibo_pago.ticket_formato}`;

          body = `Hola,<br/>

        Tu ticket <b>${recibo_pago.ticket_formato}</b> está 
        en atención.<br/><br/>
        
        Responsable será el<br/>
        Ejecutivo responsable de tu atención<br/><br/>
      
        En las próximas 24 horas se estará<br/>
        comunicando contigo para atender<br/>
        tu trámite.Te pedimos estar atento.<br/><br/>
        
        Por favor, no responder este mensaje, es remitido desde 
        una dirección de correo desatendido.`;

          const templateHeader = ``;
          const templateBody = `${body}`;
          const templateFooter = ``;

          template = `${templateHeader} ${templateBody} ${templateFooter}`;

          destinatario_principal = [...this.state.destinatarios, recibo_pago.correo_electronico];

          this.setState({
            cargando: false,
            recibo_pago,
            destinatarios: destinatario_principal,
            cc: copias,
            subject,
            template,
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  hanldeFormSubmitSave = (data) => {
    this.setState({ guardando: true });
    ReciboPagoService.createReciboPago(
      data,
      (recibo_pago) => {
        if (recibo_pago) {
          this.setState({
            guardando: false,
            recibo_pago,
          });
          this.props.history.push(`/recibo-pagos`);
          message.success('Recibo de pago creado correctamente');
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            guardando: false,
          });
        }
      }
    );
  };

  render() {
    const { recibo_pago } = this.state;
    return (
      <div>
        <h2 className="page-header">{recibo_pago.responsable ? recibo_pago.responsable : 'Detalle Recibo de Pago'}</h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                {this.state.es_cargado_page && (
                  <ReciboPagoFormulario
                    colegiado_id={this.state.colegiado_id}
                    tipo_recibo_pago_id={this.state.tipo_recibo_pago_id}
                    cliente_numero_documento={this.state.cliente_numero_documento}
                    cliente_nombres={this.state.cliente_nombres}
                    colegiado_formato={this.state.colegiado_formato}
                    cliente_id={this.state.cliente_id}
                    porcentaje={this.state.porcentaje}
                    user={this.props.user}
                    guardando={this.state.guardando}
                    onFormSubmit={this.hanldeFormSubmitSave}
                    ref={this.setFormModal}
                    props={this.props}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
    configuracion: state && state.configuracion ? state.configuracion : {},
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowReciboPagos);
