import apiConfig from './config';

const getEstadoCuentas = (queryString, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuentas${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getEstadoCuenta = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuentas/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getEstadoCuentaTemp = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuentas/${id}?tipo=LOCAL`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getCuentaColegio = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuentas-colegio/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const createEstadoCuenta = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuentas`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const updateEstadoCuenta = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuentas/${data.id}`, {
    method: 'put',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const deleteEstadoCuenta = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuentas/${id}`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const sendEmail = (id, data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key !== 'files') {
      if (typeof data[key] === 'object') {
        data[key].forEach((item, index) => {
          if (typeof item === 'object') {
            Object.keys(item).forEach((k) => {
              formData.append(`${key}[${index}][${k}]`, item[k]);
            });
          } else {
            formData.append(`${key}[${index}]`, item);
          }
        });
      } else {
        formData.append(key, data[key]);
      }
    } else if (key === 'pagos_pendientes' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        Object.keys(item).forEach((_key) => {
          formData.append(`pagos_pendientes[${index}][${_key}]`, item[_key]);

          if (data[key] && data[key].length > 0 && _key === 'files_dni') {
            data[key][index][_key].forEach((item, index) => {
              formData.append(`pagos_pendientes[${index}][${_key}][${index}]`, item);
            });
          }
        });
      });
    } else if (data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files[${index}]`, item);
      });
    }
  });

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/cuenta/${id}/email`, {
    method: 'post',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const importarCuentaXLS = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  const formData = new FormData();
  formData.append(`documento`, data.file);

  return fetch(`${apiConfig.constants.URL_API}/api/v1/cuentas-importar-xls`, {
    method: 'post',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const exportedObject = {
  getEstadoCuentas,
  getEstadoCuenta,
  getEstadoCuentaTemp,
  createEstadoCuenta,
  updateEstadoCuenta,
  deleteEstadoCuenta,
  getCuentaColegio,
  sendEmail,
  importarCuentaXLS
};

export default exportedObject;
