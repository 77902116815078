import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { message, Tabs, Row, Col, Tag, Timeline, Table, Popover, Tooltip } from 'antd';
import ColegiadoService from '../../../services/api/colegiados';
import ArchivoService from '../../../services/api/archivos';
import UserService from '../../../services/api/users';
import { LinkOutlined, CloudDownloadOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

class ShowFondoMutual extends Component {
  state = {
    id: null,
    cargando: false,
    colegiado: {},
    comprobante: {},
    destinatarios: [],
    cc: [],
    usuarios: [],
    active_tab: '1',
    cargando_documentos: false,
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.cargarColegiado(this.props.match.params.id);
      this.cargarColegiadoDocumentos(this.props.match.params.id);
    }
    this.cargarUsuarios();
  }

  // UNSAFE_componentWillMount() {
  //   if (
  //     this.props.match &&
  //     this.props.match.params &&
  //     this.props.match.params.id
  //   ) {
  //     this.cargarColegiado(this.props.match.params.id);
  //   }
  // }

  cargarUsuarios = () => {
    this.setState({ cargando_usuarios: true });
    UserService.getUsers(
      '',
      (response) => {
        if (response && response.data.length > 0) {
          this.setState({ usuarios: response.data });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
      }
    );
  };

  cargarColegiadoDocumentos = (id) => {
    this.setState({ cargando_documentos: true });
    ColegiadoService.getColegiadoDocumentos(
      id,
      (documentos) => {
        if (documentos) {
          this.setState({ documentos, cargando_documento: true });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
        this.setState({ cargando_documentos: false });
      }
    );
  };

  cargarColegiado = (id) => {
    this.setState({
      cargando: true,
      id,
    });

    ColegiadoService.getColegiado(
      id,
      (colegiado) => {
        if (colegiado) {
          let subject = '';
          let body = '';
          let template = '';
          let destinatario_principal = [];
          const copias = [];

          subject = `Habilitación de colegiatura`;

          body = `Hola,<br/>

        Tu ticket <b>${colegiado.ticket_formato}</b> está 
        en atención.<br/><br/>
        
        Responsable será el<br/>
        Ejecutivo responsable de tu atención<br/><br/>
      
        En las próximas 24 horas se estará<br/>
        comunicando contigo para atender<br/>
        tu trámite.Te pedimos estar atento.`;

          const templateHeader = ``;
          const templateBody = `${body}`;
          const templateFooter = ``;

          template = `${templateHeader} ${templateBody} ${templateFooter}`;

          destinatario_principal = [...this.state.destinatarios, colegiado.correo_electronico];

          this.setState({
            cargando: false,
            colegiado,
            destinatarios: destinatario_principal,
            cc: copias,
            subject,
            template,
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleFormSubmit = (email) => {
    // email.numero_ticket = this.state.colegiado && this.state.colegiado.ticket_formato ? this.state.colegiado.ticket_formato : null;
    // email.usuario = this.state.colegiado && this.state.colegiado.usuario_creacion_formato ? this.state.colegiado.usuario_creacion_formato : '-';
    this.setState({ guardando: true });
    ColegiadoService.sendEmail(
      this.state.id,
      email,
      (response) => {
        if (response) {
          message.success('Se envió el correo con éxito');
          this.setState({
            guardando: false,
            active_tab: '1',
            destinatarios: [],
          });
          this.cargarColegiado(this.state.id);
        }
      },
      (err) => {
        if (err) {
          this.setState({ guardando: false });
        }
      }
    );
  };

  onTabClick = (key) => {
    if (key === '4') {
      ColegiadoService.getStoreClienteFindCuenta(
        { numero_documento: this.state.colegiado.numero_documento },
        (response) => {
          if (response) {
            this.props.history.push(`/historial-cuentas/${response.id}/show?active_tab=2`);
          }
        },
        (error) => {
          if (error) {
            message.error('No se encontró la cuenta');
          }
        }
      );
    }
  };

  render() {
    const { colegiado } = this.state;
    const columns = [
      {
        title: 'Fecha Creación',
        dataIndex: 'created_at',
        key: 'created_at',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Número Documento',
        dataIndex: 'numero_documento',
        key: 'numero_documento',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Nombres',
        dataIndex: 'nombres',
        key: 'nombres',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Porcentaje',
        dataIndex: 'porcentaje',
        key: 'porcentaje',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return `${record.porcentaje || 0}%`;
        },
      },
      {
        title: 'Adjuntos',
        dataIndex: 'ticket_formato',
        key: 'ticket_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <Popover
                placement="bottomRight"
                content={
                  record.files &&
                  record.files.length > 0 &&
                  record.files.map((file, index) => {
                    return (
                      <div key={index}>
                        <p>
                          <CloudDownloadOutlined />{' '}
                          <a href={ArchivoService.getArchivoUrl(file.id)} target="_blank" rel="noreferrer">
                            {file.nombre_original}
                          </a>
                        </p>
                      </div>
                    );
                  })
                }
                title="Descargar Archivos"
              >
                <LinkOutlined />
              </Popover>
            </div>
          );
        },
      },
    ];

    if (colegiado.estado_afiliado_formato && colegiado.estado_afiliado_formato === 'BAJA DEFINITIVA POR FALLECIMIENTO') {
      columns.push({
        title: '',
        dataIndex: 'porcentaje',
        key: 'porcentaje',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <>
              {record.beneficio_recogido ? (
                <Tooltip title="Ya tiene su beneficio generado">
                  <a>Recibo Beneficio</a>
                </Tooltip>
              ) : (
                <Link
                  to={`/recibo-pagos/create?colegiado_id=${colegiado.id}&colegiado_formato=${colegiado.nombres}&cliente_id=${record.id}&cliente_numero_documento=${record.numero_documento}&cliente_nombres=${record.nombres}&porcentaje=${record.porcentaje}&tipo_recibo_pago_id=Beneficio de Fondo Mutual`}
                  style={{ textDecoration: 'underline' }}
                >
                  Recibo Beneficio
                </Link>
              )}
            </>
          );
        },
      });
    }

    const columns_adelanto = [
      {
        title: 'N° Recibo',
        dataIndex: 'serie_numero',
        key: 'serie_numero',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'RUC/DNI',
        dataIndex: 'cliente_numero_documento',
        key: 'cliente_numero_documento',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Nombres y Apellidos',
        dataIndex: 'cliente_formato',
        key: 'cliente_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Fecha Emisión',
        dataIndex: 'fecha_emision',
        key: 'fecha_emision',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Cajero',
        dataIndex: 'vendedor_formato',
        key: 'vendedor_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Monto de Adelanto',
        dataIndex: 'monto',
        key: 'monto',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Detalle',
        dataIndex: 'nombres',
        key: 'nombres',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <Link to={`/recibo-pagos/${record.id}/show`}>
                <EyeOutlined style={{ color: '#1890ff' }} />
              </Link>
            </div>
          );
        },
      },
    ];

    const columns_beneficio = [
      {
        title: 'N° Recibo',
        dataIndex: 'serie_numero',
        key: 'serie_numero',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'RUC/DNI',
        dataIndex: 'cliente_numero_documento',
        key: 'cliente_numero_documento',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Nombres y Apellidos',
        dataIndex: 'cliente_formato',
        key: 'cliente_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Fecha Emisión',
        dataIndex: 'fecha_emision',
        key: 'fecha_emision',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Cajero',
        dataIndex: 'vendedor_formato',
        key: 'vendedor_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Monto beneficio',
        dataIndex: 'monto',
        key: 'monto',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Detalle',
        dataIndex: 'nombres',
        key: 'nombres',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <Link to={`/recibo-pagos/${record.id}/show`}>
                <EyeOutlined style={{ color: '#1890ff' }} />
              </Link>
            </div>
          );
        },
      },
    ];
    console.log(this.props.match.path);
    return (
      <div>
        <div className="page-header">
          <h2>{colegiado.nombres ? colegiado.nombres : 'Detalle de Fondo Mutual'}</h2>
          {this.props.match && this.props.match.path !== '/fondo-mutuales/no-afiliados/:id/show' && (
            <div>
              {colegiado.estado_afiliado_formato === 'HABIL' && colegiado.estado_fondo_mutual_formato === 'Afiliado' && (
                <Link
                  to={`/recibo-pagos/create?colegiado_id=${colegiado.id}&cliente_id=${colegiado.id}&cliente_numero_documento=${colegiado.numero_documento}&cliente_nombres=${colegiado.nombres}&tipo_recibo_pago_id=Adelanto de Seguro Mutual`}
                  style={{ color: 'white' }}
                  className="ant-btn ant-btn-primary"
                >
                  GENERAR RECIBO DE ADELANTO
                </Link>
              )}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <div
                  style={{
                    position: 'absolute',
                    right: '48px',
                    fontSize: '20px',
                    zIndex: 10,
                  }}
                >
                  {this.props.match && this.props.match.path === '/fondo-mutuales/no-afiliados/:id/show' ? (
                    <Link to={`/fondo-mutuales/no-afiliados`}>
                      <CloseOutlined />
                    </Link>
                  ) : (
                    <Link to={`/fondo-mutuales`}>
                      <CloseOutlined />
                    </Link>
                  )}
                </div>
                <Tabs defaultActiveKey={this.state.active_tab} onTabClick={this.onTabClick}>
                  <TabPane tab="Información General" key="1">
                    <Row>
                      <Col xs={24} sm={24} md={9} lg={9} xl={9} className="line-height-28">
                        <div>
                          <b>Estado: </b>
                          <Tag
                            color={
                              colegiado.estado_fondo_mutual_formato === 'Afiliado'
                                ? 'green'
                                : colegiado.estado_fondo_mutual_formato === 'Renuncia'
                                ? 'red'
                                : colegiado.estado_fondo_mutual_formato === 'No afiliado'
                                ? 'blue'
                                : ''
                            }
                          >
                            {colegiado.estado_fondo_mutual_formato || 'No afiliado'}
                          </Tag>
                        </div>
                        <div>
                          <b>Estado de Colegiatura: </b>
                          <Tag
                            color={
                              colegiado.estado_afiliado_formato === 'INHABILITADO POR DEUDA'
                                ? 'blue'
                                : colegiado.estado_afiliado_formato === 'VITALICIO'
                                ? 'red'
                                : colegiado.estado_afiliado_formato === 'HABIL'
                                ? 'green'
                                : ''
                            }
                          >
                            {colegiado.estado_afiliado_formato}
                          </Tag>
                        </div>
                        <div>
                          <b>Tipo Documento: </b> {colegiado.tipo_documento_formato}
                        </div>
                        <div>
                          <b>Nombres: </b> {colegiado.nombres}
                        </div>
                        <div>
                          <b>Responsable: </b> {colegiado.responsable}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div>
                          <b>Correo: </b> {colegiado.correo_electronico}
                        </div>
                        <div>
                          <b>Fecha Afiliación: </b> {colegiado.fecha_afiliado_fondo_mutual}
                        </div>
                        <div>
                          <b>N° Documento: </b> {colegiado.numero_documento}
                        </div>
                        <div>
                          <b>Télefono: </b> {colegiado.telefono}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                        <Timeline mode="left">
                          {colegiado.historiales_fondo_mutual &&
                            colegiado.historiales_fondo_mutual.length > 0 &&
                            colegiado.historiales_fondo_mutual.map((item, index) => {
                              return (
                                <Timeline.Item
                                  key={index}
                                  label={item.fecha_formato}
                                  color={
                                    item.tipo_operacion === 'Afiliado'
                                      ? 'green'
                                      : item.tipo_operacion === 'No Afiliado'
                                      ? 'blue'
                                      : item.tipo_operacion === 'Renuncia'
                                      ? 'red'
                                      : ''
                                  }
                                >
                                  <Tag
                                    color={
                                      item.tipo_operacion === 'Afiliado'
                                        ? 'green'
                                        : item.tipo_operacion === 'No Afiliado'
                                        ? 'blue'
                                        : item.tipo_operacion === 'Renuncia'
                                        ? 'red'
                                        : ''
                                    }
                                  >
                                    {item.tipo_operacion}
                                  </Tag>
                                  <div>{item.comentario}</div>
                                </Timeline.Item>
                              );
                            })}
                        </Timeline>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b>ARCHIVOS ADJUNTOS DE NO AFILIACIÓN: </b>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.documentos &&
                        this.state.documentos.length > 0 &&
                        this.state.documentos.map((item, index) => {
                          if (item.modulo === 'no_afiliacion') {
                            return (
                              <Col key={index} xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="line-height-28">
                                  <div>
                                    <b>{item.modulo === 'no_afiliacion' ? 'Declaración Jurada no Afiliación' : 'Documento'}: </b>
                                  </div>
                                  <Tooltip title="Descargar">
                                    <div className="download__file">
                                      <a href={ArchivoService.getArchivoUrl(item.id)} target="_blank" rel="noreferrer">
                                        <CloudDownloadOutlined /> {item.nombre_original.substring(0, 20)}
                                      </a>
                                    </div>
                                  </Tooltip>
                                </div>
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b>ARCHIVOS ADJUNTOS DE AFILIACIÓN: </b>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.documentos &&
                        this.state.documentos.length > 0 &&
                        this.state.documentos.map((item, index) => {
                          if (item.modulo === 'declaracion_jurada' || item.modulo === 'poliza') {
                            return (
                              <Col key={index} xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="line-height-28">
                                  <div>
                                    <b>
                                      {item.modulo === 'titulo'
                                        ? 'Título'
                                        : item.modulo === 'fotografia'
                                        ? 'Fotografia'
                                        : item.modulo === 'poliza'
                                        ? 'Póliza de Seguro Mutual'
                                        : item.modulo === 'titular_poliza_decano'
                                        ? 'Solicitud del titular de la póliza dirigida al decano'
                                        : item.modulo === 'dni_titular_poliza'
                                        ? 'DNI del titular de la póliza (Ambos lados)'
                                        : item.modulo === 'poliza_original'
                                        ? 'Póliza original'
                                        : item.modulo === 'declaracion_jurada'
                                        ? 'Declaración Jurada'
                                        : item.modulo === 'dni'
                                        ? 'DNI'
                                        : 'Documento'}
                                      :{' '}
                                    </b>
                                  </div>
                                  <Tooltip title="Descargar">
                                    <div className="download__file">
                                      <a href={ArchivoService.getArchivoUrl(item.id)} target="_blank" rel="noreferrer">
                                        <CloudDownloadOutlined /> {item.nombre_original.substring(0, 20)}
                                      </a>
                                    </div>
                                  </Tooltip>
                                </div>
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b>ARCHIVOS ADJUNTOS DE CAMBIO BENEFICIARIO: </b>
                          </div>
                          <div>
                            <b>Solicitud:</b>
                          </div>
                          <div>{colegiado.solicitud_cambio_beneficiario ? colegiado.solicitud_cambio_beneficiario : ''}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.documentos &&
                        this.state.documentos.length > 0 &&
                        this.state.documentos.map((item, index) => {
                          if (
                            item.modulo === 'titular_poliza_decano' ||
                            item.modulo === 'poliza_original' ||
                            item.modulo === 'dni_titular_poliza'
                          ) {
                            return (
                              <Col key={index} xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="line-height-28">
                                  <div>
                                    <b>
                                      {item.modulo === 'titulo'
                                        ? 'Título'
                                        : item.modulo === 'fotografia'
                                        ? 'Fotografia'
                                        : item.modulo === 'poliza'
                                        ? 'Póliza de Seguro Mutual'
                                        : item.modulo === 'titular_poliza_decano'
                                        ? 'Solicitud del titular de la póliza dirigida al decano'
                                        : item.modulo === 'dni_titular_poliza'
                                        ? 'DNI del titular de la póliza (Ambos lados)'
                                        : item.modulo === 'poliza_original'
                                        ? 'Póliza original'
                                        : item.modulo === 'declaracion_jurada'
                                        ? 'Declaración Jurada'
                                        : item.modulo === 'dni'
                                        ? 'DNI'
                                        : 'Documento'}
                                      :{' '}
                                    </b>
                                  </div>
                                  <Tooltip title="Descargar">
                                    <div className="download__file">
                                      <a href={ArchivoService.getArchivoUrl(item.id)} target="_blank" rel="noreferrer">
                                        <CloudDownloadOutlined /> {item.nombre_original.substring(0, 20)}
                                      </a>
                                    </div>
                                  </Tooltip>
                                </div>
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b>ARCHIVOS ADJUNTOS DE RENUNCIA: </b>
                          </div>
                          <div>
                            <b>Solicitud:</b>
                          </div>
                          <div>{colegiado.solicitud_renuncia_fondo_mutual ? colegiado.solicitud_renuncia_fondo_mutual : ''}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.documentos &&
                        this.state.documentos.length > 0 &&
                        this.state.documentos.map((item, index) => {
                          if (
                            item.modulo === 'renuncia_titular_poliza_decano' ||
                            item.modulo === 'renuncia_poliza_original' ||
                            item.modulo === 'renuncia_dni_titular_poliza'
                          ) {
                            return (
                              <Col key={index} xs={24} sm={24} md={8} lg={8} xl={8}>
                                <div className="line-height-28">
                                  <div>
                                    <b>
                                      {item.modulo === 'titulo'
                                        ? 'Título'
                                        : item.modulo === 'fotografia'
                                        ? 'Fotografia'
                                        : item.modulo === 'poliza'
                                        ? 'Póliza de Seguro Mutual'
                                        : item.modulo === 'titular_poliza_decano'
                                        ? 'Solicitud del titular de la póliza dirigida al decano'
                                        : item.modulo === 'dni_titular_poliza'
                                        ? 'DNI del titular de la póliza (Ambos lados)'
                                        : item.modulo === 'poliza_original'
                                        ? 'Póliza original'
                                        : item.modulo === 'declaracion_jurada'
                                        ? 'Declaración Jurada'
                                        : item.modulo === 'dni'
                                        ? 'DNI'
                                        : 'Documento'}
                                      :{' '}
                                    </b>
                                  </div>
                                  <Tooltip title="Descargar">
                                    <div className="download__file">
                                      <a href={ArchivoService.getArchivoUrl(item.id)} target="_blank" rel="noreferrer">
                                        <CloudDownloadOutlined /> {item.nombre_original.substring(0, 20)}
                                      </a>
                                    </div>
                                  </Tooltip>
                                </div>
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Row>
                  </TabPane>
                  <TabPane tab="Beneficiarios" key="2">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                          rowKey={(record) => record.id}
                          columns={columns}
                          dataSource={colegiado.beneficiarios}
                          pagination={false}
                          loading={this.state.cargando}
                          size="small"
                          locale={{
                            emptyText: 'No se ha encontrado registros.',
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Cuenta Fondo Mutual" key="3">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <b>ADELANTO FONDO MUTUAL</b>
                        <br />
                        <br />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                          rowKey={(record) => record.id}
                          columns={columns_adelanto}
                          dataSource={colegiado.adelantos}
                          pagination={false}
                          loading={this.state.cargando}
                          size="small"
                          locale={{
                            emptyText: 'No se ha encontrado registros',
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <br />
                        <br />
                        <b>BENEFICIO FONDO MUTUAL</b>
                        <br />
                        <br />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                          rowKey={(record) => record.id}
                          columns={columns_beneficio}
                          dataSource={colegiado.beneficios}
                          pagination={false}
                          loading={this.state.cargando}
                          size="small"
                          locale={{
                            emptyText: 'No se ha encontrado registros',
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Cuotas Pendientes" key="4"></TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
    configuracion: state && state.configuracion ? state.configuracion : {},
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowFondoMutual);
