import React, { Component } from 'react';
import { Modal } from 'antd';

import CuotaFondoFormulario from './CuotaFondoFormulario';

class CuotaFondoFormularioModal extends Component {
  handleClose = () => {
    this.props.onCancel();
  };

  handleFormSubmit = (data) => {
    this.props.onFormSubmit(data);
  };


  render() {
    return (
      <div>
        <Modal
          title={this.props.cuota_fondo && this.props.cuota_fondo.id ? 'Editar Cuota Fondo Mutual' : 'Nueva Cuota Fondo Mutual'}
          visible={this.props.visible}
          onCancel={this.handleClose}
          width={500}
          footer={null}
          maskClosable={false}
        >
          <CuotaFondoFormulario
            ref={this.setFormRef}
            onFormSubmit={this.handleFormSubmit}
            onFormCancel={this.handleClose}
            cuota_fondo={this.props.cuota_fondo}
            guardando={this.props.guardando}
            colegiado_id={this.props.colegiado_id}
            errores={this.props.errores}
          />
        </Modal>
      </div>
    );
  }
}

export default CuotaFondoFormularioModal;
