import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import './Private.css';

import Sidebar from '../components/sidebar/Sidebar';
import TopNav from '../components/topnav/TopNav';

import Dashboard from '../pages/Dashboard';
import Customers from '../pages/Customers';
import IndexMesaParte from '../Private/scenes/MesaPartes/index';
import ShowMesaParte from '../Private/scenes/MesaPartes/show';
import IndexControlCitas from '../Private/scenes/ControlCitas/index';
import IndexGestionColegiaturas from '../Private/scenes/GestionColegiaturas/index';
import ShowGestionColegiatura from '../Private/scenes/GestionColegiaturas/show';
import IndexEstadoCuentas from '../Private/scenes/EstadoCuentas/index';
import IndexHistorialCuentas from '../Private/scenes/HistorialCuentas/index';
import ShowHistorialCuentas from '../Private/scenes/HistorialCuentas/show';
import IndexPagoPendientes from '../Private/scenes/PagoPendientes/index';
import ShowPagoPendientes from '../Private/scenes/PagoPendientes/show';
import IndexReciboPagos from '../Private/scenes/ReciboPagos/index';
import ShowReciboPagos from '../Private/scenes/ReciboPagos/show';
import CreateReciboPagos from '../Private/scenes/ReciboPagos/create';
import ShowEstadoCuenta from '../Private/scenes/EstadoCuentas/show';
import IndexUser from './scenes/Configuraciones/Users/index';
import IndexRol from '../Private/scenes/Configuraciones/Roles/index';
import IndexPerfil from '../Private/scenes/Configuraciones/Perfil/index';
import IndexProductosColegiatura from '../Private/scenes/Configuraciones/ProductosColegiatura/index';
import IndexFondoMutuales from '../Private/scenes/FondoMutuales/index';
import IndexNoFondoMutuales from '../Private/scenes/NoFondoMutuales/index';
import ShowFondoMutual from '../Private/scenes/FondoMutuales/show';
import IndexReportes from '../Private/scenes/Reportes/index';

import ThemeAction from '../redux/actions/ThemeAction';
import { cargarConfiguracion } from '../data/configuracion/actions';

const Layout = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const themeClass = localStorage.getItem('themeMode', 'theme-mode-light');
    const colorClass = localStorage.getItem('colorMode', 'theme-mode-light');
    dispatch(ThemeAction.setMode(themeClass));
    dispatch(ThemeAction.setColor(colorClass));

    cargandoConfiguracion();
  }, [dispatch]);

  const cargandoConfiguracion = () => {
    props.cargarConfiguracion();
  };

  return (
    <div className={`layout`}>
      <Sidebar {...props} />
      <div className="layout__content">
        <TopNav {...props} />
        <div className="layout__content-main">
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/mesa-partes/" exact component={Dashboard} />
            <Route path="/customers" component={Customers} />
            <Route path="/mesa-partes/tickets-atencion/:id/show" name="Detalle Compra" render={(props) => <ShowMesaParte {...props} />} />

            <Route path="/mesa-partes/tickets-atencion/:id?" name="Mesa Partes" render={(props) => <IndexMesaParte {...props} />} />

            <Route path="/control-citas/:id?" name="Control de Citas" render={(props) => <IndexControlCitas {...props} />} />

            <Route
              path="/fondo-mutuales/afiliados/:id/show"
              name="Fondo Mutual"
              render={(props) => <ShowFondoMutual {...props} />}
            />

            <Route
              path="/fondo-mutuales/afiliados/:id?"
              name="Fondo Mutual"
              render={(props) => <IndexFondoMutuales {...props} />}
            />

            <Route
              path="/fondo-mutuales/no-afiliados/:id/show"
              name="Fondo Mutual"
              render={(props) => <ShowFondoMutual {...props} />}
            />

            <Route
              path="/fondo-mutuales/no-afiliados/:id?"
              name="Fondo Mutual"
              render={(props) => <IndexNoFondoMutuales {...props} />}
            />

            <Route
              path="/reportes/:id?"
              name="Reportes"
              render={(props) => <IndexReportes {...props} />}
            />

            <Route
              path="/fondo-mutuales/recibo-pagos/:id/show"
              name="Detalle Recibo de Pago"
              render={(props) => <ShowReciboPagos {...props} />}
            />
            <Route
              path="/fondo-mutuales/recibo-pagos/create"
              name="Recibo de Pago"
              render={(props) => <CreateReciboPagos {...props} />}
            />
            <Route
              path="/fondo-mutuales/recibo-pagos/:id?"
              name="Recibo de Pago"
              render={(props) => <IndexReciboPagos {...props} />}
            />

            <Route
              path="/gestion-colegiaturas/:id/show"
              name="Detalle Gestión Colegiaturas"
              render={(props) => <ShowGestionColegiatura {...props} />}
            />

            <Route
              path="/gestion-colegiaturas/:id?"
              name="Gestión Colegiaturas"
              render={(props) => <IndexGestionColegiaturas {...props} />}
            />

            <Route path="/fondo-mutuales/:id/show" name="Fondo Mutual" render={(props) => <ShowFondoMutual {...props} />} />

            <Route path="/fondo-mutuales/:id?" name="Fondo Mutual" render={(props) => <IndexFondoMutuales {...props} />} />

            <Route
              path="/estado-cuentas/:id/show"
              name="Detalle Estado de Cuenta"
              render={(props) => <ShowEstadoCuenta {...props} />}
            />

            <Route path="/estado-cuentas/:id?" name="Estado de Cuenta" render={(props) => <IndexEstadoCuentas {...props} />} />

            <Route
              path="/historial-cuentas/:id/show"
              name="Detalle Historial de Cuenta"
              render={(props) => <ShowHistorialCuentas {...props} />}
            />
            <Route
              path="/historial-cuentas/:id?"
              name="Historial de Cuenta"
              render={(props) => <IndexHistorialCuentas {...props} />}
            />

            <Route
              path="/pago-pendiente/:id/show"
              name="Detalle Pago Pendientes"
              render={(props) => <ShowPagoPendientes {...props} />}
            />
            <Route
              path="/pago-pendientes/:id?"
              name="Pago Pendientes"
              render={(props) => <IndexPagoPendientes {...props} />}
            />

            <Route
              path="/recibo-pagos/:id/show"
              name="Detalle Recibo de Pago"
              render={(props) => <ShowReciboPagos {...props} />}
            />
            <Route path="/recibo-pagos/create" name="Recibo de Pago" render={(props) => <CreateReciboPagos {...props} />} />
            <Route path="/recibo-pagos/:id?" name="Recibo de Pago" render={(props) => <IndexReciboPagos {...props} />} />

            <Route path="/configuraciones/users/:id?" name="Users" render={(props) => <IndexUser {...props} />} />

            <Route path="/configuraciones/roles/:id?" name="Roles" render={(props) => <IndexRol {...props} />} />

            <Route path="/configuraciones/perfil/:id?" name="Perfil" render={(props) => <IndexPerfil {...props} />} />

            <Route
              path="/configuraciones/productos-colegiatura/:id?"
              name="Productos Pasarela"
              render={(props) => <IndexProductosColegiatura {...props} />}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cargarConfiguracion: () => dispatch(cargarConfiguracion()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
