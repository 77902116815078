import apiConfig from './config';

const getRuc = (ruc, success, error) => {
  return fetch(`${apiConfig.constants.URL_CUENTA_CONTASISCORP}/api/sunat-ruc/${ruc}`, {
    headers: {
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const exportedObject = {
  getRuc,
};

export default exportedObject;
