import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, message, Tag, Pagination, Alert } from 'antd';
import EstadoCuentaService from '../../../services/api/estado_cuentas';
import helper from '../../../utils/helper';
import { EyeOutlined } from '@ant-design/icons';


class IndexHistorialCuentas extends Component {
  state = {
    cargando: false,
    historial_estados: [],
    de_formato: '',
    hasta_formato: '',
    de: '',
    hasta: '',
    estado: '',
    page: {
      number: 1,
      per_page: 15,
      order_column: 'created_at',
      order_type: 'desc',
      from: 0,
      to: 0,
      last_page: 0,
      total: 0,
      buscar_texto: '',
    },
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleChangeLocation);

    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        const configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage,
        });
      }
    }

    this.cargarHistorialCuenta();
  }

  handleChangeSearch = (value) => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = value.target.value;
    this.setState({
      page: configPage,
      buscar_texto: value.target.value
    }, () => {
      this.cargarHistorialCuenta();
    });
  }

  handleChangeSearchIcon = () => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = this.state.buscar_texto;
    this.setState({
      page: configPage,
      buscar_texto: this.state.buscar_texto
    }, () => {
      this.cargarHistorialCuenta();
    });
  }
  

  cargarHistorialCuenta = (path = undefined) => {
    this.setState({
      cargando: true,
    });

    if (!path || path === undefined) {
      path = `?page=${this.state.page.number}&per_page=${this.state.page.per_page}&order_column=${this.state.page.order_column}&order_type=${this.state.page.order_type}&historial_cuenta=true&estado=${this.state.estado}&de=${this.state.de}&hasta=${this.state.hasta}`;
      if (this.state.page && this.state.page.buscar_texto && this.state.page.buscar_texto !== '') {
        path = `${path}&buscar_texto=${this.state.page.buscar_texto}`;
      }
    }

    EstadoCuentaService.getEstadoCuentas(
      path,
      (page) => {
        if (page && page.data && page.data.length > 0) {
          const configPage = Object.assign({}, this.state.page);
          configPage.from = page.from;
          configPage.to = page.to;
          configPage.last_page = page.last_page;
          configPage.total = page.total;
          configPage.number = page.current_page;
          configPage.per_page = parseInt(page.per_page);
          configPage.buscar_texto = page.buscar_texto ? page.buscar_texto : '';

          this.setState({
            cargando: false,
            historial_estados: page.data,
            page: configPage,
          });
        } else {
          this.setState({
            historial_estados: [],
            cargando: false,
          });
        }
      },
      (error, result) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleChangeLocation = (location, action) => {
    const queryString = new URLSearchParams(location.search);
    const buscar_texto = queryString.get('buscar_texto');

    if (buscar_texto && buscar_texto != null) {
      const page = Object.assign({}, this.state.page);

      page.buscar_texto = buscar_texto;

      this.setState({
        page,
      });

      setTimeout(() => {
        this.cargarHistorialCuenta();
      }, 10);
    }
  };

  handleChangePerPage = (current, pageSize) => {
    const page = Object.assign({}, this.state.page);
    page.number = current;
    page.per_page = pageSize;
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&historial_cuenta=true&estado=${this.state.estado}&de=${this.state.de}&hasta=${this.state.hasta}`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    this.props.history.push(path);
    this.cargarHistorialCuenta(path);
  };

  showTotal = (total) => {
    return `Total ${total} registros`;
  };

  handleExportar = (tipo) => {
    const page = Object.assign({}, this.state.page);
    page.number = current;
    page.per_page = pageSize;
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&historial_cuenta=true&estado=${this.state.estado}&de=${this.state.de}&hasta=${this.state.hasta}`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    const token =  null;
    const url_exportar = `${apiConfig.constants.URL_NEGOCIOS}/api/${apiConfig.constants.API_VERSION}/reporte/cobranzas/cuota-ordinaria/pdf${path}&authorization=${token}&empresa_id=${apiConfig.constants.EMPRESA_ID}`;
    window.open(url_exportar);
  }

  handleChangeFechaDesde = (e) => {
    this.setState({
      de: e,
      de_formato: e ? e.format('DD/MM/YYYY') : null,
    }, () => {
      this.cargarHistorialCuenta();
    });
  };

  handleChangeFechaHasta = (e) => {
    this.setState({
      hasta: e,
      hasta_formato: e ? e.format('DD/MM/YYYY') : null,
    }, () => {
      this.cargarHistorialCuenta();
    });
  };

  handleChange = (estado) => {
    this.setState({
      estado
    }, () => {
      this.cargarHistorialCuenta();
    });
  };

  render() {
    const columns = [
      {
        title: 'N°',
        dataIndex: 'name',
        key: 'name',
        responsive: ['xl'],
        width: 50,
        render: (text, record, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        title: 'RUC/DNI',
        dataIndex: 'numero_documento',
        key: 'numero_documento',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 120,
      },
      {
        title: 'Nombre del colegiado',
        dataIndex: 'razon_social',
        key: 'razon_social',
        responsive: ['lg', 'xl'],
        render: (text, record) => {
          return (
            <div>{text || `${record.nombre_1} ${record.nombre_2} ${record.apellido_paterno} ${record.apellido_materno}`}</div>
          );
        },
      },
      {
        title: 'Código Afiliado',
        dataIndex: 'codigo_afiliado',
        key: 'codigo_afiliado',
        responsive: ['lg', 'xl'],
        ellipsis: true,
      },
      {
        title: 'Fecha Colegiatura',
        dataIndex: 'fecha_colegiatura',
        key: 'fecha_colegiatura',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Estado',
        dataIndex: 'estado_afiliado_formato',
        key: 'estado_afiliado_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 150,
        render: (text) => (
          <Tag
            color={text === 'INHABILITADO POR DEUDA' ? 'blue' : text === 'VITALICIO' ? 'red' : text === 'HABIL' ? 'green' : ''}
          >
            {text}
          </Tag>
        ),
      },
      {
        title: 'Detalle',
        dataIndex: 'estado',
        key: 'estado',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 80,
        render: (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {helper.tienePermiso(this.props.permisos, 'Ver detalle gestion colegiatura') ? (
                <Link to={`/historial-cuentas/${record.id}/show`}>
                  <EyeOutlined style={{ color: '#1890ff' }} />
                </Link>
              ) : (
                <EyeOutlined style={{ color: '#b5b5b5' }} />
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <div className="page-header">
          <h2>Historial de Cuenta</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6 }}>
            <div className="topnav__search">
              <input
                type="text"
                placeholder="Buscar..."
                onChange={this.handleChangeSearch}
                value={ this.state.buscar_texto }
              />
              <i onClick={() => this.handleChangeSearchIcon()} className="bx bx-search" style={{cursor: 'pointer'}}></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {!helper.tienePermiso(this.props.permisos, 'Ver registro gestion colegiaturas') && (
                <Alert
                  message="Permiso"
                  description="No tiene permiso para ver lista de registro gestión colegiatura"
                  type="error"
                />
              )}
              <div className="card__body">
                <Table
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={this.state.historial_estados}
                  pagination={false}
                  loading={this.state.cargando}
                  size="small"
                />
                <div style={{ paddingTop: 12, textAlign: 'right' }}>
                  <Pagination
                    size="small"
                    showTotal={this.showTotal}
                    total={this.state.page.total}
                    current={this.state.page.number}
                    pageSize={this.state.page.per_page}
                    onChange={this.handleChangePerPage}
                    onShowSizeChange={this.handleChangePerPage}
                    pageSizeOptions={[10, 20, 30, 40]}
                    showQuickJumper
                    showSizeChanger
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexHistorialCuentas);
