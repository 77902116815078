import React, { Component } from 'react';
import { Form, Input, Button , InputNumber, DatePicker} from 'antd';
import moment from 'moment';
const FormItem = Form.Item;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 8 },
  },
};

class CuotaOrdinariaFormulario extends Component {
  state = {
    id: this.props.cuota_ordinaria && this.props.cuota_ordinaria.id ? this.props.cuota_ordinaria.id : '',
    fecha_emision: this.props.cuota_ordinaria && this.props.cuota_ordinaria.fecha_emision ? this.props.cuota_ordinaria.fecha_emision : moment().format('DD/MM/YYYY'),
    comprobante: this.props.cuota_ordinaria && this.props.cuota_ordinaria.comprobante ? this.props.cuota_ordinaria.comprobante : '',
    monto: this.props.cuota_ordinaria && this.props.cuota_ordinaria.monto ? this.props.cuota_ordinaria.monto : '',
    comentario: this.props.cuota_ordinaria && this.props.cuota_ordinaria.comentario ? this.props.cuota_ordinaria.comentario : ''
  };

  handleComprobante = (e) => {
    this.setState({
      comprobante: e.target.value,
    });
  };


  handleMonto = (e) => {
    this.setState({
      monto: e
    });
  };

  handleChangeFechaDocumento = (date) => {
    let fecha_emision = '';
    if (date) {
      fecha_emision = moment(date).format('DD/MM/YYYY');
    }
    this.setState({
      fecha_emision: fecha_emision,
    });
  };

  handleChangeComentario = (e) => {
    this.setState({
      comentario: e.target.value,
    });
  };

  handleSave = (data) => {
    this.props.onFormSubmit({
      id: this.state.id,
      fecha_emision: this.state.fecha_emision || '',
      comprobante: this.state.comprobante || '',
      monto: this.state.monto || 0,
      comentario: this.state.comentario || '',
      colegiado_id: this.props.colegiado_id || ''
    });
    this.nameInput.focus();
  };

  handleCancel = () => {
    this.props.onFormCancel();
  };

  render() {
    return (
      <Form>
        <FormItem
          {...formItemLayout}
          label="Comprobante"
        >
          <DatePicker
            value={
              this.state.fecha_emision && this.state.fecha_emision !== 'Invalid date'
                ? moment(this.state.fecha_emision, dateFormat)
                : null
            }
            format={dateFormat}
            id="fecha_emision"
            placeholder="DD/MM/YYYY"
            onChange={(e) => this.handleChangeFechaDocumento(e)}
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Comprobante"
        >
          <Input
            onChange={(e) => this.handleComprobante(e)}
            value={this.state.comprobante}
            autoComplete="off"
            id="comprobante"
            ref={(input) => {
              this.nameInput = input;
            }}
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Monto"
        >
          <InputNumber
            onChange={(e) => this.handleMonto(e)}
            value={this.state.monto}
            autoComplete="off"
            id="monto"
            min={1}
            style={{ width: '50%' }}
            ref={(input) => {
              this.nameInput = input;
            }}
          />
        </FormItem>
        <FormItem
        {...formItemLayout}
          label="Comentario"
        >
          <TextArea
            rows={5}
            showCount
            maxLength={2000}
            onChange={(e) => this.handleChangeComentario(e)}
            value={this.state.comentario}
            autoComplete="off"
            id="comentario"
          />
        </FormItem>

        
        <FormItem {...formItemLayoutWithOutLabel} label="">
          <Button type="primary" onClick={this.handleSave} htmlType="submit" loading={this.props.guardando}>
            Guardar
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={this.handleCancel}>
            Cancelar
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default CuotaOrdinariaFormulario;
