import React, { useState, useEffect } from 'react';
import { message, Menu, Dropdown, Popover } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import DashboardService from '../services/api/dashboard';
import Chart from 'react-apexcharts';

const Dashboard = () => {
  const [atendidos, setAtendidos] = useState([]);
  const [colegiados, setColegiados] = useState([]);
  const [filtro_colegiado, setFiltroColegiado] = useState('EsteAnio');
  const [filtro_atendido, setFiltroAtendido] = useState('EsteAnio');

  useEffect(() => {
    cargarAtendidos(filtro_atendido);
    cargarColegiados(filtro_colegiado);
  }, []);

  const cargarAtendidos = (filtro_atendido) => {
    DashboardService.getMesaPartesAtendidos(
      '?filtro_por=RankingVentaFecha.' + filtro_atendido,
      (response) => {
        if (response) {
          setAtendidos(response);
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
        }
      }
    );
  };

  const cargarColegiados = (filtro_colegiado) => {
    DashboardService.getColegiados(
      '?filtro_por=RankingVentaFecha.' + filtro_colegiado,
      (response) => {
        if (response) {
          setColegiados(response);
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
        }
      }
    );
  };

  atendidos.sort(function (a, b) {
    if (a.estado < b.estado) {
      return 1;
    }
    if (a.estado > b.estado) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  const series = [];
  const labels = [];
  const colores_atendidos = [];
  let cantidad_atendidos = 0;
  atendidos &&
    atendidos.length > 0 &&
    atendidos.map((item) => {
      series.push(item.cantidad);
      labels.push(item.estado);
      colores_atendidos.push(item.color);
      cantidad_atendidos += item.cantidad;
      return item;
    });

  const options = {
    chart: {
      width: 380,
      type: 'pie',
    },
    colors: colores_atendidos,
    labels: labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const array_colegiados = [];
  const array_no_colegiados = [];
  const array_tipo_documentos = [];
  const array_dias = [];
  colegiados &&
    colegiados.length > 0 &&
    colegiados.map((item) => {
      array_colegiados.push(item.colegiados);
      array_no_colegiados.push(item.no_colegiados);
      array_tipo_documentos.push(parseInt(item.tipo_documento_codigo));
      array_dias.push(item.fecha);
      return item;
    });

  const series1 = [
    {
      name: 'Colegiados',
      type: 'column',
      data: array_colegiados,
    },
    {
      name: 'No Colegiados',
      type: 'line',
      data: array_no_colegiados,
    },
    {
      name: 'Tipo Documento',
      type: 'line',
      data: array_tipo_documentos,
    },
  ];

  const options1 = {
    chart: {
      height: 350,
      type: 'line',
      defaultLocale: 'es',
      locales: [
        {
          name: 'es',
          options: {
            months: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              download: 'Descargar SVG',
              selection: 'Selección',
              selectionZoom: 'Selección Zoom',
              zoomIn: 'Acercarse',
              zoomOut: 'Disminuir el zoom',
              pan: 'Panorámica',
              reset: 'Restablecer zoom',
            },
          },
        },
      ],
    },
    stroke: {
      width: [0, 4],
    },
    title: {
      text: 'Colegiados',
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    labels: array_dias,
    xaxis: {
      type: 'datetime',
    },
    yaxis: [
      {
        title: {
          text: '',
        },
      },
      {
        opposite: true,
        title: {
          text: '',
        },
      },
    ],
  };

  const onClickFiltroAtencion = (filtro_por) => {
    setFiltroAtendido(filtro_por);
    cargarAtendidos(filtro_por);
  };

  const onClickFiltroColegiado = (filtro_por) => {
    setFiltroColegiado(filtro_por);
    cargarColegiados(filtro_por);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => onClickFiltroAtencion('Hoy')} className={`${filtro_atendido === 'Hoy' ? 'active__menu' : ''}`}>
        Hoy
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroAtencion('EstaSemana')}
        className={`${filtro_atendido === 'EstaSemana' ? 'active__menu' : ''}`}
      >
        Esta Semana
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroAtencion('SemanaAnterior')}
        className={`${filtro_atendido === 'SemanaAnterior' ? 'active__menu' : ''}`}
      >
        Semana Anterior
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroAtencion('EsteMes')}
        className={`${filtro_atendido === 'EsteMes' ? 'active__menu' : ''}`}
      >
        Este Mes
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroAtencion('MesAnterior')}
        className={`${filtro_atendido === 'MesAnterior' ? 'active__menu' : ''}`}
      >
        Mes Anterior
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroAtencion('EsteAnio')}
        className={`${filtro_atendido === 'EsteAnio' ? 'active__menu' : ''}`}
      >
        Este Año
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroAtencion('AnioAnterior')}
        className={`${filtro_atendido === 'AnioAnterior' ? 'active__menu' : ''}`}
      >
        Año anterior
      </Menu.Item>
    </Menu>
  );

  const menuColegiado = (
    <Menu>
      <Menu.Item
        onClick={() => onClickFiltroColegiado('EstHoy')}
        className={`${filtro_colegiado === 'EstHoy' ? 'active__menu' : ''}`}
      >
        Hoy
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroColegiado('EstEstaSemana')}
        className={`${filtro_colegiado === 'EstEstaSemana' ? 'active__menu' : ''}`}
      >
        Esta Semana
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroColegiado('EstSemanaAnterior')}
        className={`${filtro_colegiado === 'EstSemanaAnterior' ? 'active__menu' : ''}`}
      >
        Semana Anterior
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroColegiado('EstEsteMes')}
        className={`${filtro_colegiado === 'EstEsteMes' ? 'active__menu' : ''}`}
      >
        Este Mes
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroColegiado('EstMesAnterior')}
        className={`${filtro_colegiado === 'EstMesAnterior' ? 'active__menu' : ''}`}
      >
        Mes Anterior
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroColegiado('EstEsteAnio')}
        className={`${filtro_colegiado === 'EstEsteAnio' ? 'active__menu' : ''}`}
      >
        Este Año
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickFiltroColegiado('AnioAnterior')}
        className={`${filtro_colegiado === 'AnioAnterior' ? 'active__menu' : ''}`}
      >
        Año anterior
      </Menu.Item>
    </Menu>
  );

  const themeReducer = null;
  return (
    <div>
      <h2 className="page-header">Dashboard</h2>
      <div className="row">
        <div className="col-4">
          <div className="card" style={{ marginBottom: 32 }}>
            <div className="card__header">
              <h3>MESA DE PARTES ATENCIONES</h3>
              <div>
                <Dropdown overlay={menu} placement="bottomRight" danger>
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    {filtro_atendido === 'Hoy'
                      ? 'Hoy'
                      : filtro_atendido === 'EstaSemana'
                      ? 'Esta Semana'
                      : filtro_atendido === 'SemanaAnterior'
                      ? 'Semana Anterior'
                      : filtro_atendido === 'EsteMes'
                      ? 'Este Mes'
                      : filtro_atendido === 'MesAnterior'
                      ? 'Mes Anterior'
                      : filtro_atendido === 'EsteAnio'
                      ? 'Este Año'
                      : filtro_atendido === 'AnioAnterior'
                      ? 'Año anterior'
                      : ''}{' '}
                    <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="card__body">
              <Chart
                options={
                  themeReducer === 'theme-mode-dark'
                    ? {
                        ...options,
                        theme: { mode: 'dark' },
                      }
                    : {
                        ...options,
                        theme: { mode: 'light' },
                      }
                }
                series={series}
                type="pie"
              />
              <div className="card__body-alias">
                {atendidos &&
                  atendidos.length > 0 &&
                  atendidos.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.estado}: {item.cantidad}
                      </div>
                    );
                  })}
                <div style={{ textAlign: 'right' }}>TOTAL DOCUMENTO: {cantidad_atendidos}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5">
          <div className="card" style={{ marginBottom: 32 }}>
            <div className="card__header">
              <h3>
                ESTADISTICA DE COLEGIADOS &nbsp;&nbsp;
                <Popover
                  placement="bottomRight"
                  content={
                    <div>
                      <div>6 - RUC</div>
                      <div>1 - DNI</div>
                      <div>7 - PASAPORTE</div>
                      <div>4 - CARNET DE EXTRANJERIA</div>
                      <div>0 - PERMISO TEMPORAL DE PERMANENCIA</div>
                    </div>
                  }
                  title="Leyenda Tipo Doucmento"
                >
                  <span style={{ color: 'blue', cursor: 'pointer' }}>Leyenda Tipo Documento</span>
                </Popover>
              </h3>
              <div>
                <Dropdown overlay={menuColegiado} placement="bottomRight">
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    {filtro_colegiado === 'Hoy'
                      ? 'Hoy'
                      : filtro_colegiado === 'EstaSemana'
                      ? 'Esta Semana'
                      : filtro_colegiado === 'SemanaAnterior'
                      ? 'Semana Anterior'
                      : filtro_colegiado === 'EsteMes'
                      ? 'Este Mes'
                      : filtro_colegiado === 'MesAnterior'
                      ? 'Mes Anterior'
                      : filtro_colegiado === 'EsteAnio'
                      ? 'Este Año'
                      : filtro_colegiado === 'AnioAnterior'
                      ? 'Año anterior'
                      : ''}{' '}
                    <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
            <div className="card__body">
              <Chart
                options={
                  themeReducer === 'theme-mode-dark'
                    ? {
                        ...options1,
                        theme: { mode: 'dark' },
                      }
                    : {
                        ...options1,
                        theme: { mode: 'light' },
                      }
                }
                series={series1}
                type="line"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
