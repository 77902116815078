import React, { Component } from 'react';
import { Row, Col, Card, Form, Input, Button, message, Upload, Modal } from 'antd';
import HabilitarColegiadoService from '../../services/api/colegiados';
import RucService from '../../services/api/ruc';
import DniService from '../../services/api/dni';
import Helpers from '../../utils/helper';
import ApiConfig from '../../services/api/config';
import moment from 'moment';
import { UploadOutlined, HighlightOutlined, LoadingOutlined, LinkOutlined } from '@ant-design/icons';
import './styles.css';
const FormItem = Form.Item;
const { TextArea } = Input;

class RenunciaSeguroMutualFormulario extends Component {
  state = {
    guardando: false,
    files_renuncia_titular_poliza_decano: [],
    files_renuncia_dni_titular_poliza: [],
    files_renuncia_poliza_original: [],
    files_dni: [],
    uploading: false,
    buscando_numero_documento: false,
    beneficiarios: [{ files_dni: [], loading: false, es_nuevo_file: false }],
    es_afiliado_fondo_mutual: true,
    codigo_afiliado: '',
    es_afiliado_fondo_mutual_negocios: false,
    estado_fondo_mutual_formato: 'Renuncia',
    fecha_afiliado_fondo_mutual: moment().format('DD/MM/YYYY'),
    cuenta_id: '',
    solicitud_renuncia_fondo_mutual: '',
    validators: {
      files_renuncia_titular_poliza_decano: {
        rules: [
          // {
          //   test: (value) => {
          //     let valid = false;
          //     if (value && value.length > 0) {
          //       valid = true;
          //     }
          //     return valid;
          //   },
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_renuncia_dni_titular_poliza: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (value && value.length > 0) {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_renuncia_poliza_original: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (value && value.length > 0) {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      nombres: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length + 1 <= 300,
            message: 'El campo acepta 300 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      solicitud_renuncia_fondo_mutual: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length + 1 <= 2000,
            message: 'El campo acepta 2000 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      numero_documento: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[0-9 ]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => value.trim().length < 12,
            message: 'El campo acepta 11 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      codigo_afiliado: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_dni: {
        rules: [
          // {
          //   test: (value) => {
          //     let valid = false;
          //     if (value && value.length > 0) {
          //       valid = true;
          //     }
          //     return valid;
          //   },
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  handleChangeNumeroDocumento = (e, fieldName) => {
    this.setState(
      {
        numero_documento: e.target.value,
      },
      () => {
        // if (this.state.numero_documento.trim().length === 8) {
        //   this.buscarDni();
        // } else if (this.state.numero_documento.trim().length === 11) {
        //   this.buscarRuc();
        // }
      }
    );

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeNombres = (e, fieldName) => {
    this.setState({
      nombres: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeSolicitudRenunciaFondoMutual = (e, fieldName) => {
    this.setState({
      solicitud_renuncia_fondo_mutual: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeCodigoAfiliacion = (e, fieldName) => {
    this.setState({
      codigo_afiliado: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeNombresItem = (e, fieldName, index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].nombres = e.target.value;
    beneficiarios[index].es_modificado = true;
    this.setState({
      beneficiarios,
    });

    this.uploadValidators('beneficiarios', beneficiarios);
  };

  handleChangeNumeroDocumentoItem = (e, fieldName, index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].numero_documento = e.target.value;
    beneficiarios[index].es_modificado = true;
    this.setState(
      {
        beneficiarios,
      },
      () => {
        if (beneficiarios[index].numero_documento.trim().length === 8) {
          this.buscarDniItem(index);
        }
        // else if (beneficiarios[index].numero_documento.trim().length === 11) {
        //   this.buscarRucItem(index);
        // }
      }
    );

    this.uploadValidators('beneficiarios', beneficiarios);
  };

  handleClickBuscarDocumentoItem = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    if (beneficiarios[index].numero_documento) {
      if (beneficiarios[index].numero_documento.trim().length === 8) {
        this.buscarDniItem(index);
        // } else if (beneficiarios[index].numero_documento.trim().length === 11) {
        //   this.buscarRucItem(index);
      } else {
        message.error('Número documento no cumple para buscar en RENIEC)');
      }
    } else {
      message.error('Número documento no cumple para buscar en RENIEC)');
    }
  };

  handleClickBuscarDocumento = () => {
    if (this.state.numero_documento) {
      if (this.state.numero_documento.trim().length === 8) {
        this.buscarDni();
      }
      if (this.state.numero_documento.trim().length === 11) {
        this.buscarRuc();
      }
    } else {
      message.error('Por favor número documento no cumplete para buscar en (SUNAT O RENIEC)');
    }
  };

  handleAceptar = () => {
    window.location.reload(true);
  };

  buscarRucItem = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].loading = true;
    this.setState({ beneficiarios });
    RucService.getRuc(
      beneficiarios[index].numero_documento,
      (response) => {
        if (response && response.success) {
          beneficiarios[index].nombres = `${response.data.razonSocial}`;
          beneficiarios[index].nombres_temp = `${response.data.razonSocial}`;
          beneficiarios[index].loading = false;
          this.setState(
            {
              buscando_numero_documento: false,
              beneficiarios,
            },
            () => {
              this.uploadValidators('beneficiarios', beneficiarios);
            }
          );
        }
        if (!response.success) {
          beneficiarios[index].loading = false;
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      },
      (err) => {
        if (err) {
          beneficiarios[index].loading = false;
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      }
    );
  };

  buscarDniItem = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].loading = true;
    this.setState({ beneficiarios });
    DniService.getDni(
      beneficiarios[index].numero_documento,
      (response) => {
        if (response && response.success) {
          beneficiarios[
            index
          ].nombres = `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`;
          beneficiarios[
            index
          ].nombres_temp = `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`;
          beneficiarios[index].loading = false;
          this.setState(
            {
              buscando_numero_documento: false,
              beneficiarios,
            },
            () => {
              this.uploadValidators('beneficiarios', beneficiarios);
            }
          );
        } else if (!response.success) {
          beneficiarios[index].loading = false;
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      },
      (err) => {
        if (err) {
          beneficiarios[index].loading = false;
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      }
    );
  };

  buscarRuc = () => {
    this.setState({ buscando_numero_documento: true });
    RucService.getRuc(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.razonSocial}`,
            nombres_temp: `${response.data.razonSocial}`,
          });
        }
        if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  buscarDni = () => {
    this.setState({ buscando_numero_documento: true });
    DniService.getDni(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
            nombres_temp: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
          });
        } else if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  handleSend = () => {
    const valid = this.isFormValid();
    if (valid) {
      this.setState({ guardando: true });
      const data = this.state;
      data.id = this.state.habilitado.id;

      HabilitarColegiadoService.updateColegiado(
        data,
        (response) => {
          if (response) {
            Modal.success({
              title: 'Formulario enviado correctamente',
              content: <div style={{ fontSize: '13px' }}>{`Datos actualizados correctamente.`}</div>,
              onOk: this.handleAceptar,
            });
          }
          this.setState({ guardando: false });
        },
        (err) => {
          if (err) {
            message.error(err.message);
          }
          this.setState({ guardando: false });
        }
      );
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleLimpiar = () => {
    this.setState(
      {
        numero_documento: null,
        guardando: false,
        files_renuncia_titular_poliza_decano: [],
        files_renuncia_dni_titular_poliza: [],
        files_renuncia_poliza_original: [],
        files_dni: [],
        uploading: false,
        buscando_numero_documento: false,
      },
      () => {
        this.handleAceptar();
      }
    );
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  handleUpload = () => {
    const { files } = this.state;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files[]', file);
    });
    this.setState({
      uploading: true,
    });
    // You can use any AJAX library you like
    fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        this.setState({
          files: [],
        });
        message.success('upload successfully.');
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        this.setState({
          uploading: false,
        });
      });
  };

  onClickCeremonia = () => {
    window.open(`${ApiConfig.constants.URL_API}/ceremonia.pdf`, '_blank');
  };

  onClickDeclaracionJurada = () => {
    window.open(`${ApiConfig.constants.URL_API}/declaracion_jurada.pdf`, '_blank');
  };

  onClickPoliza = () => {
    window.open(`${ApiConfig.constants.URL_API}/poliza.pdf`, '_blank');
  };

  handleClickAgregar = () => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios.push({ files_dni: [], loading: false });
    this.setState({ beneficiarios });
  };

  handleClickEliminar = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios.splice(index, 1);
    this.setState({ beneficiarios });
  };

  getFormatoHTMLDeErrores = (validator) => {
    if (validator && !validator.valid && validator.errors.length > 0) {
      const errors = validator.errors.map((info, index) => <li key={index}>{info}</li>);
      return <ul className="errores">{errors}</ul>;
    }
    return '';
  };

  handleClickValidar = () => {
    this.buscarCuentaNO();
  };

  buscarCuentaNO = () => {
    if (this.state.numero_documento) {
      this.setState({ validando_codigo_afiliado: true });
      const data = this.state;
      HabilitarColegiadoService.getStoreClienteFind(
        data,
        (response) => {
          if (response) {
            this.setState(
              {
                validando_codigo_afiliado: false,
                es_afiliado_fondo_mutual_negocios: response.es_afiliado_fondo_mutual,
                cuenta_id: response.id ? response.id : '',
              },
              () => {
                if (response.cuenta_existe) {
                  this.bucarColegioadoHabilitado();
                } else {
                  message.error('Colegiado no existe.');
                }
              }
            );
          } else {
            this.setState({ validando_codigo_afiliado: false });
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              validando_codigo_afiliado: false,
            });
          }
        }
      );
    } else {
      message.error('Ingrese número documento.');
    }
  };

  bucarColegioadoHabilitado = () => {
    if (this.state.numero_documento) {
      this.setState({ validando_codigo_afiliado: true });
      const data = this.state;
      HabilitarColegiadoService.getFindColegiadoHabilitado(
        data.numero_documento,
        (response) => {
          if (response) {
            this.setState({
              validando_codigo_afiliado: false,
              nombres: response.nombres,
              beneficiarios: response.beneficiarios,
              habilitado: response,
            });
          } else {
            this.setState({ validando_codigo_afiliado: false });
            message.error('No se encontro al colegiado.');
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              validando_codigo_afiliado: false,
            });
          }
        }
      );
    } else {
      message.error('Ingrese número documento.');
    }
  };

  render() {
    const { uploading, files_renuncia_titular_poliza_decano, files_renuncia_dni_titular_poliza, files_renuncia_poliza_original } =
      this.state;
    const props_titular_poliza_decano = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_renuncia_titular_poliza_decano.indexOf(file);
          const newFileList = state.files_renuncia_titular_poliza_decano.slice();
          newFileList.splice(index, 1);
          return {
            files_renuncia_titular_poliza_decano: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_renuncia_titular_poliza_decano.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_renuncia_titular_poliza_decano: [...state.files_renuncia_titular_poliza_decano, file],
            }),
            () => {
              this.uploadValidators('files_renuncia_titular_poliza_decano', this.state.files_renuncia_titular_poliza_decano);
            }
          );
        }
        return false;
      },
      files_renuncia_titular_poliza_decano,
    };

    const props_dni_titular_poliza = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_renuncia_dni_titular_poliza.indexOf(file);
          const newFileList = state.files_renuncia_dni_titular_poliza.slice();
          newFileList.splice(index, 1);
          return {
            files_renuncia_dni_titular_poliza: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_renuncia_dni_titular_poliza.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_renuncia_dni_titular_poliza: [...state.files_renuncia_dni_titular_poliza, file],
            }),
            () => {
              this.uploadValidators('files_renuncia_dni_titular_poliza', this.state.files_renuncia_dni_titular_poliza);
            }
          );
        }
        return false;
      },
      files_renuncia_dni_titular_poliza,
    };

    const props_poliza_original = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_renuncia_poliza_original.indexOf(file);
          const newFileList = state.files_renuncia_poliza_original.slice();
          newFileList.splice(index, 1);
          return {
            files_renuncia_poliza_original: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_renuncia_poliza_original.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_renuncia_poliza_original: [...state.files_renuncia_poliza_original, file],
            }),
            () => {
              this.uploadValidators('files_renuncia_poliza_original', this.state.files_renuncia_poliza_original);
            }
          );
        }
        return false;
      },
      files_renuncia_poliza_original,
    };

    return (
      <>
        <div className="colegiatura__header">
          <div className="container-sm">
            <img src="https://www.ccpll.org/assets/img/logo.png" alt="Logo" />
          </div>
        </div>
        <div className="container-sm">
          <Card
            className="colegiatura"
            type="inner"
            title={
              <div>
                <HighlightOutlined /> &nbsp; Ingresa tus datos
              </div>
            }
            extra={null}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem
                    label="Número Documento"
                    validateStatus={this.state.validators.numero_documento.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <Input
                      onChange={(e) => this.handleChangeNumeroDocumento(e, 'numero_documento')}
                      value={this.state.numero_documento}
                      autoComplete="off"
                      id="numero_documento"
                      placeholder="Ingrese la número documento."
                    />

                    {this.mostrarErroresValidacion('numero_documento')}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem
                    label="Código Afiliación"
                    validateStatus={this.state.validators.codigo_afiliado.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <Input.Group compact>
                      <Input
                        onChange={(e) => this.handleChangeCodigoAfiliacion(e, 'codigo_afiliado')}
                        style={{ width: `calc(100% - ${this.state.validando_codigo_afiliado ? '98.5px' : '76.5px'})` }}
                        value={this.state.codigo_afiliado}
                        autoComplete="off"
                        id="codigo_afiliado"
                        placeholder="Ingrese Código Afiliación."
                      />
                      <Button type="primary" onClick={this.handleClickValidar} loading={this.state.validando_codigo_afiliado}>
                        Validar
                      </Button>
                    </Input.Group>

                    {this.mostrarErroresValidacion('codigo_afiliado')}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem
                    label="Nombre Completo"
                    validateStatus={this.state.validators.nombres.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <Input
                      showCount
                      maxLength={300}
                      onChange={(e) => this.handleChangeNombres(e, 'nombres')}
                      value={this.state.nombres}
                      autoComplete="off"
                      id="nombres"
                      placeholder="Ingrese su nombre completo."
                    />
                    {this.mostrarErroresValidacion('nombres')}
                  </FormItem>
                </Col>
              </Row>
            </Form>

            <Card
              className="colegiatura-file"
              type="inner"
              title={
                <div>
                  <LinkOutlined /> &nbsp; Adjuntar Archivos
                </div>
              }
              extra={null}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form layout="vertical">
                    <FormItem
                      label="Solicitud dirigida al decano:"
                      validateStatus={this.state.validators.solicitud_renuncia_fondo_mutual.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={true}
                    >
                      <TextArea
                        rows={3}
                        showCount
                        maxLength={2000}
                        onChange={(e) => this.handleChangeSolicitudRenunciaFondoMutual(e, 'solicitud_renuncia_fondo_mutual')}
                        value={this.state.solicitud_renuncia_fondo_mutual}
                        autoComplete="off"
                        id="solicitud_renuncia_fondo_mutual"
                        placeholder="Ingrese solicitud renuncia."
                      />
                      {this.mostrarErroresValidacion('solicitud_renuncia_fondo_mutual')}
                    </FormItem>
                  </Form>
                </Col>
                <div style={{ paddingTop: 140 }}></div>
                <Col xs={24} sm={24} md={15} lg={15} xl={15} style={{ display: 'none' }}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> Solicitud del titular de la póliza dirigida al decano:
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>1 archivo, máximo 2MB</div>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} style={{ display: 'none' }}>
                  <Upload {...props_titular_poliza_decano}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_renuncia_titular_poliza_decano')}
                </Col>
                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> DNI del titular de la póliza (Ambos lados):
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>1 archivo, máximo 2MB</div>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                  <Upload {...props_dni_titular_poliza}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_renuncia_dni_titular_poliza')}
                </Col>
                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> Póliza original (Ambos Lados):
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>1 archivo, máximo 2MB</div>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                  <Upload {...props_poliza_original}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_renuncia_poliza_original')}
                </Col>
              </Row>
            </Card>
            <br />
            <div className="m-pl-16">
              <Button type="primary" onClick={this.handleSend} htmlType="submit" loading={this.state.guardando} size="large">
                Continuar
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleLimpiar} size="large">
                Limpiar
              </Button>
            </div>
          </Card>
          <br />
        </div>
      </>
    );
  }
}

export default RenunciaSeguroMutualFormulario;
