import React, { Component } from 'react';
import IndependienteFormulario from '../../../components/FondoMutual/IndependienteFormulario';

class Independiente extends Component {
  render() {
    return (
      <>
        <IndependienteFormulario />
      </>
    );
  }
}

export default Independiente;
