import React, { Component } from 'react';
import { message, Button, Row, Col } from 'antd';
import ReciboPagoService from '../../../services/api/recibo_pagos';
import ImprimirModal from '../../../components/ImprimirModal/ImprimirModal';
import Close from './img/Close.svg';
import apiConfig from '../../../services/api/config';

import './styles.css';

class ShowReciboPagos extends Component {
  state = {
    id: null,
    cargando: false,
    recibo_pago: {},
    destinatarios: [],
    cc: [],
    openImprimirModal: false,
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.cargarReciboPago(this.props.match.params.id);
    }
  }

  cargarReciboPago = (id) => {
    this.setState({
      cargando: true,
      id,
    });

    ReciboPagoService.getReciboPago(
      id,
      (recibo_pago) => {
        if (recibo_pago) {
          let subject = '';
          let body = '';
          let template = '';
          let destinatario_principal = [];
          const copias = [];

          subject = `Tu ticket de atención ${recibo_pago.ticket_formato}`;

          body = `Hola,<br/>

        Tu ticket <b>${recibo_pago.ticket_formato}</b> está 
        en atención.<br/><br/>
        
        Responsable será el<br/>
        Ejecutivo responsable de tu atención<br/><br/>
      
        En las próximas 24 horas se estará<br/>
        comunicando contigo para atender<br/>
        tu trámite.Te pedimos estar atento.<br/><br/>
        
        Por favor, no responder este mensaje, es remitido desde 
        una dirección de correo desatendido.`;

          const templateHeader = ``;
          const templateBody = `${body}`;
          const templateFooter = ``;

          template = `${templateHeader} ${templateBody} ${templateFooter}`;

          destinatario_principal = [...this.state.destinatarios, recibo_pago.correo_electronico];

          this.setState({
            cargando: false,
            recibo_pago,
            destinatarios: destinatario_principal,
            cc: copias,
            subject,
            template,
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleClickCloseUpdate = () => {
    this.cerrar();
  };

  cerrar = () => {
    this.props.history.push(`/recibo-pagos`);
  };

  handleClickPDF = () => {
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

    const urlDownloadPDF = `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/recibos/${this.state.recibo_pago.id}/pdf?authorization=${token}`;
    window.open(urlDownloadPDF);
  };

  handleClickOpenImprimirModal = (tipo_impresion) => {
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

    const printPDFUrl = `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/recibos/${this.state.recibo_pago.id}/${tipo_impresion}?authorization=${token}`;
    this.setState({
      printPDFUrl,
      openImprimirModal: true,
    });
  };

  handleClickCloseImprimirModal = () => {
    this.setState({
      openImprimirModal: false,
    });
  };

  render() {
    const { recibo_pago } = this.state;
    return (
      <div>
        <h2 className="page-header">{recibo_pago.responsable ? recibo_pago.responsable : 'Detalle Recibo de Pago'}</h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <div className="card__title">
                  <div className="card__sub--title">RECIBO DE PAGO</div>
                  <div className="card__btns">
                    <Button onClick={this.handleClickPDF}>
                      <i className="bx bx-file"></i>
                    </Button>
                    <Button onClick={() => this.handleClickOpenImprimirModal('imprimir')}>
                      <i className="bx bx-printer"></i>
                    </Button>
                    <img onClick={() => this.handleClickCloseUpdate()} width="20" src={Close} alt="Close" />
                  </div>
                </div>
                <div>
                  <Row className="space__detalle">
                    #Recibo Pago <b><p style={{ paddingLeft: 6 }}>{recibo_pago.serie_numero}</p></b>
                  </Row>
                  <Row className="space__detalle">
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <b>Tipo de Recibo</b>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      {recibo_pago.tipo_recibo}
                    </Col>
                  </Row>
                  <Row className="space__detalle">
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <b>Fecha Recibo de Recibo</b>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      {recibo_pago.fecha_emision}
                    </Col>
                  </Row>
                  <Row className="space__detalle">
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <b>Cajero</b>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      {recibo_pago.vendedor_formato}
                    </Col>
                  </Row>
                  <Row className="space__detalle">
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <b>Forma Pago</b>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      {recibo_pago.forma_pago}
                    </Col>
                  </Row>
                  <Row className="space__detalle">
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <b>Fecha de Aprobación</b>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      {recibo_pago.fecha_aprobacion}
                    </Col>
                  </Row>
                  <Row className="space__detalle">
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <b>Concepto</b>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      {recibo_pago.concepto}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <b>Monto</b>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      S/ {recibo_pago.monto}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.openImprimirModal ? (
          <ImprimirModal
            visible={this.state.openImprimirModal}
            onCancel={this.handleClickCloseImprimirModal}
            url={this.state.printPDFUrl}
            mime_type="application/pdf"
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default ShowReciboPagos;
