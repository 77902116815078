import React, { Component } from 'react';
import { Form, Input, Button, message, Select, Spin } from 'antd';
import RolService from '../../services/api/roles';
import Helpers from '../../utils/helper';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 8 },
  },
};

class UserFormulario extends Component {
  state = {
    id: this.props.user && this.props.user.id ? this.props.user.id : '',
    name: this.props.user && this.props.user.name ? this.props.user.name : '',
    email: this.props.user && this.props.user.email ? this.props.user.email : '',
    password: this.props.user && this.props.user.password ? this.props.user.password : '',
    rol_id:
      this.props.user && this.props.user.roles && this.props.user.roles.length > 0 && this.props.user.roles[0]
        ? this.props.user.roles[0].id
        : '',
    rol_formato:
      this.props.user && this.props.user.roles && this.props.user.roles.length > 0 && this.props.user.roles[0]
        ? this.props.user.roles[0].name
        : '',
    roles: this.props.user && this.props.user.roles ? this.props.user.roles : '',
    validators: {
      name: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => value.trim().length <= 255,
            message: 'El campo acepta 255 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      email: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => {
              if (value.trim().length > 0) {
                if (/^[^@]+@[^]+\..+/.test(value)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            },
            message: 'El campo acepta solo correo electrónico.',
          },
          {
            test: (value) => value.trim().length <= 255,
            message: 'El campo acepta 255 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      password: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length <= 30,
            message: 'El campo acepta 30 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  componentDidMount() {
    this.cargarRoles();
  }

  cargarRoles = () => {
    this.setState({
      cargando_roles: true,
    });
    RolService.getRoles(
      '',
      (roles) => {
        if (roles) {
          this.setState({
            cargando_roles: false,
            roles,
          });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando_roles: false,
          });
        }
      }
    );
  };

  handleChangeName = (e, fieldName) => {
    this.setState({
      name: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeEmail = (e, fieldName) => {
    this.setState({
      email: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangePassword = (e, fieldName) => {
    this.setState({
      password: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeRol = (value, opt) => {
    this.setState({
      rol_id: opt.props.value,
      rol_formato: opt.props.children,
    });
  };

  handleSave = (data) => {
    const valid = this.isFormValid();
    if (valid) {
      this.props.onFormSubmit({
        id: this.state.id,
        name: this.state.name || '',
        email: this.state.email || '',
        password: this.state.password || '',
        password_confirmation: this.state.password || '',
        roles: [this.state.rol_id],
      });
      this.nameInput.focus();
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleCancel = () => {
    this.props.onFormCancel();
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  render() {
    let roles = [];
    if (this.state.roles && this.state.roles.length > 0) {
      roles = this.state.roles.map((item) => {
        return (
          <Option key={item.id} value={item.id} rol={item}>
            {item.name}
          </Option>
        );
      });
    }

    return (
      <Form>
        <FormItem
          {...formItemLayout}
          label="Nombres Completo"
          validateStatus={this.state.validators.name.valid ? 'success' : 'error'}
          help={this.state.errors}
          required={true}
        >
          <Input
            onChange={(e) => this.handleChangeName(e, 'name')}
            value={this.state.name}
            autoComplete="off"
            id="name"
            ref={(input) => {
              this.nameInput = input;
            }}
          />
          {this.mostrarErroresValidacion('name')}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Correo Electrónico"
          validateStatus={this.state.validators.email.valid ? 'success' : 'error'}
          help={this.state.errors}
          required={true}
        >
          <Input
            onChange={(e) => this.handleChangeEmail(e, 'email')}
            value={this.state.email}
            autoComplete="off"
            autoFocus
            id="email"
          />
          {this.mostrarErroresValidacion('email')}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Contraseña"
          validateStatus={this.state.validators.password.valid ? 'success' : 'error'}
          help={this.state.errors}
          required={true}
        >
          <Input
            onChange={(e) => this.handleChangePassword(e, 'password')}
            value={this.state.password}
            autoComplete="off"
            id="password"
            ref={(input) => {
              this.passwordInput = input;
            }}
          />
          {this.mostrarErroresValidacion('password')}
        </FormItem>
        <FormItem {...formItemLayout} label="Asignar Rol" help={this.state.errors} required={false}>
          <Select
            showSearch
            optionFilterProp="children"
            value={this.state.rol_id}
            onChange={this.handleChangeRol}
            notFoundContent={this.props.cargando_roles ? <Spin size="small" /> : 'No se ha encontrado registros'}
          >
            {roles}
          </Select>
        </FormItem>
        <FormItem {...formItemLayoutWithOutLabel} label="">
          <Button type="primary" onClick={this.handleSave} htmlType="submit" loading={this.props.guardando}>
            Guardar
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={this.handleCancel}>
            Cancelar
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default UserFormulario;
