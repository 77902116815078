import React, { Component } from 'react';
import RenunciaSeguroMutualFormulario from '../../../components/FondoMutual/RenunciaSeguroMutualFormulario';

class RenunciaSeguroMutual extends Component {
  render() {
    return (
      <>
        <RenunciaSeguroMutualFormulario />
      </>
    );
  }
}

export default RenunciaSeguroMutual;
