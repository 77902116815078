import apiConfig from './config';
import helper from '../../utils/helper';

const getColegiados = (queryString, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados${queryString}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getColegiadoHC = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${id}?fondo_mutual=false`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getColegiadoDetalle = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados-detalle/${data.id}?PERS_PERSONA_ID=${data.PERS_PERSONA_ID}&PROD_PRODUCTO_ID=${data.PROD_PRODUCTO_ID}&producto_id=${data.producto_id}&es_negocios=${data.es_negocios}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getColegiadoPagoPendiente = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${id}?pago_pendiente=true`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getColegiado = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${id}?fondo_mutual=true`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const createColegiado = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const createPagoCache = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${data.id}/pago-cache`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const updateColegiado = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'files' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files[${index}]`, item);
      });
    } else if (key === 'files_titulo' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_titulo[${index}]`, item);
      });
    } else if (key === 'files_fotografia' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_fotografia[${index}]`, item);
      });
    } else if (key === 'files_dni' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_dni[${index}]`, item);
      });
    } else if (key === 'files_poliza' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_poliza[${index}]`, item);
      });
    } else if (key === 'files_declaracion_jurada' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_declaracion_jurada[${index}]`, item);
      });
    } else if (key === 'files_titular_poliza_decano' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_titular_poliza_decano[${index}]`, item);
      });
    } else if (key === 'files_dni_titular_poliza' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_dni_titular_poliza[${index}]`, item);
      });
    } else if (key === 'files_poliza_original' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_poliza_original[${index}]`, item);
      });
    } else if (key === 'files_renuncia_titular_poliza_decano' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_renuncia_titular_poliza_decano[${index}]`, item);
      });
    } else if (key === 'files_renuncia_dni_titular_poliza' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_renuncia_dni_titular_poliza[${index}]`, item);
      });
    } else if (key === 'files_renuncia_poliza_original' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_renuncia_poliza_original[${index}]`, item);
      });
    } else if (key === 'files_documento' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_documento[${index}]`, item);
      });
    } else if (key === 'beneficiarios' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        Object.keys(item).forEach((_key) => {
          formData.append(`beneficiarios[${index}][${_key}]`, item[_key]);

          if (data[key] && data[key].length > 0 && _key === 'files_dni') {
            data[key][index][_key].forEach((item, index_file) => {
              formData.append(`beneficiarios[${index}][${_key}][${index_file}]`, item);
            });
          }
        });
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${data.id}/actualizar`, {
    method: 'post',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const deleteColegiado = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${id}`, {
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const sendEmail = (id, data, success, error) => {
  const token = helper.getToken();

  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key !== 'files') {
      if (typeof data[key] === 'object') {
        data[key].forEach((item, index) => {
          if (typeof item === 'object') {
            Object.keys(item).forEach((k) => {
              formData.append(`${key}[${index}][${k}]`, item[k]);
            });
          } else {
            formData.append(`${key}[${index}]`, item);
          }
        });
      } else {
        formData.append(key, data[key]);
      }
    } else if (data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files[${index}]`, item);
      });
    }
  });

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${id}/email`, {
    method: 'post',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const createHabilitarColegiado = (data, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'files' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files[${index}]`, item);
      });
    } else if (key === 'files_titulo' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_titulo[${index}]`, item);
      });
    } else if (key === 'files_fotografia' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_fotografia[${index}]`, item);
      });
    } else if (key === 'files_dni' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_dni[${index}]`, item);
      });
    } else if (key === 'files_poliza' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_poliza[${index}]`, item);
      });
    } else if (key === 'files_declaracion_jurada' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_declaracion_jurada[${index}]`, item);
      });
    } else if (key === 'files_declaracion_jurada_no_afiliacion' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_declaracion_jurada_no_afiliacion[${index}]`, item);
      });
    } else if (key === 'files_documento' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        formData.append(`files_documento[${index}]`, item);
      });
    } else if (key === 'beneficiarios' && data[key] && data[key].length > 0) {
      data[key].forEach((item, index) => {
        Object.keys(item).forEach((_key) => {
          formData.append(`beneficiarios[${index}][${_key}]`, item[_key]);

          if (data[key] && data[key].length > 0 && _key === 'files_dni') {
            data[key][index][_key].forEach((item, index_file) => {
              formData.append(`beneficiarios[${index}][${_key}][${index_file}]`, item);
            });
          }
        });
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados`, {
    method: 'post',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getColegiadoDocumentos = (id, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(`${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${id}/documentos`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getStoreClienteFind = (data, success, error) => {
  return fetch(
    `${apiConfig.constants.URL_NEGOCIOS}/api/v1/store/cliente/${data.numero_documento}?codigo_afiliado=${data.codigo_afiliado}`,
    {
      headers: {
        Accept: 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getStoreClienteFindCuenta = (data, success, error) => {
  return fetch(`${apiConfig.constants.URL_NEGOCIOS}/api/v1/store/cliente/${data.numero_documento}`, {
    headers: {
      Accept: 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const getFindColegiadoHabilitado = (numero_documento, success, error) => {
  const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;

  return fetch(
    `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/colegiados/${numero_documento}/habilitados`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
  )
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const createCuentaMontoAdelantar = (data, success, error) => {
  const token = null;
  return fetch(`${apiConfig.constants.URL_NEGOCIOS}/api/v1/adelantos?codigo_afiliado=${data.codigo_afiliado}`, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(apiConfig.methods.checkStatus)
    .then(apiConfig.methods.parseJSON)
    .then(success)
    .catch(apiConfig.methods.error)
    .then(error);
};

const exportedObject = {
  getColegiados,
  getColegiado,
  createColegiado,
  updateColegiado,
  deleteColegiado,
  sendEmail,
  createHabilitarColegiado,
  getColegiadoDocumentos,
  getStoreClienteFind,
  getStoreClienteFindCuenta,
  getFindColegiadoHabilitado,
  getColegiadoHC,
  getColegiadoPagoPendiente,
  createCuentaMontoAdelantar,
  getColegiadoDetalle,
  createPagoCache
};

export default exportedObject;
