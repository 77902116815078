import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, message, Tag, Pagination, Alert } from 'antd';
import MesaParteService from '../../../services/api/mesa_partes';
import helper from '../../../utils/helper';
import { EyeOutlined } from '@ant-design/icons';

class IndexMesaPartes extends Component {
  state = {
    cargando: false,
    mesa_partes: [],
    page: {
      number: 1,
      per_page: 15,
      order_column: 'created_at',
      order_type: 'desc',
      from: 0,
      to: 0,
      last_page: 0,
      total: 0,
      buscar_texto: '',
    },
  };

  componentWillUnmount() {
    this.unlisten();
  }

  // UNSAFE_componentWillMount() {
  //   this.unlisten = this.props.history.listen(this.handleChangeLocation);

  //   if (this.props.location && this.props.location.search) {
  //     const queryString = new URLSearchParams(this.props.location.search);
  //     if (queryString) {
  //       const buscar_texto = queryString.get('buscar_texto');
  //       const configPage = Object.assign({}, this.state.page);
  //       configPage.buscar_texto = buscar_texto;
  //       this.setState({
  //         page: configPage,
  //       });
  //     }
  //   }
  // }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleChangeLocation);

    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        const configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage,
        });
      }
    }

    this.cargarMesaPates();
  }

  handleChangeSearch = (value) => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = value.target.value;
    this.setState({
      page: configPage,
      buscar_texto: value.target.value
    }, () => {
      this.cargarMesaPates();
    });
  }

  handleChangeSearchIcon = () => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = this.state.buscar_texto;
    this.setState({
      page: configPage,
      buscar_texto: this.state.buscar_texto
    }, () => {
      this.cargarMesaPates();
    });
  }

  cargarMesaPates = (path = undefined) => {
    this.setState({
      cargando: true,
    });

    if (!path || path === undefined) {
      path = `?page=${this.state.page.number}&per_page=${this.state.page.per_page}&order_column=${this.state.page.order_column}&order_type=${this.state.page.order_type}`;
      if (this.state.page && this.state.page.buscar_texto && this.state.page.buscar_texto !== '') {
        path = `${path}&buscar_texto=${this.state.page.buscar_texto}`;
      }
    }

    MesaParteService.getMesaPartes(
      path,
      (page) => {
        if (page && page.data && page.data.length > 0) {
          const configPage = Object.assign({}, this.state.page);
          configPage.from = page.from;
          configPage.to = page.to;
          configPage.last_page = page.last_page;
          configPage.total = page.total;
          configPage.number = page.current_page;
          configPage.per_page = parseInt(page.per_page);
          configPage.buscar_texto = page.buscar_texto ? page.buscar_texto : '';

          this.setState({
            cargando: false,
            mesa_partes: page.data,
            page: configPage,
          });
        } else {
          this.setState({
            mesa_partes: [],
            cargando: false,
          });
        }
      },
      (error, result) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleChangeLocation = (location, action) => {
    const queryString = new URLSearchParams(location.search);
    const buscar_texto = queryString.get('buscar_texto');

    if (buscar_texto && buscar_texto != null) {
      const page = Object.assign({}, this.state.page);

      page.buscar_texto = buscar_texto;

      this.setState({
        page,
      });

      setTimeout(() => {
        this.cargarMesaPates();
      }, 10);
    }
  };

  handleChangePerPage = (current, pageSize) => {
    const page = Object.assign({}, this.state.page);
    page.number = current;
    page.per_page = pageSize;
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    this.props.history.push(path);
    this.cargarMesaPates(path);
  };

  showTotal = (total) => {
    return `Total ${total} registros`;
  };

  render() {
    const columns = [
      {
        title: 'N°',
        dataIndex: 'name',
        key: 'name',
        responsive: ['xl'],
        width: 50,
        render: (text, record, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        title: 'Ticket',
        dataIndex: 'ticket_formato',
        key: 'ticket_formato',
        width: 100,
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Tipo Tramite',
        dataIndex: 'tipo_tramite_formato',
        key: 'tipo_tramite_formato',
        responsive: ['md', 'lg', 'xl'],
      },
      {
        title: 'RUC/DNI',
        dataIndex: 'numero_documento',
        key: 'numero_documento',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable',
        key: 'responsable',
        responsive: ['lg', 'xl'],
      },
      {
        title: 'Fecha Documento',
        dataIndex: 'created_at_format',
        key: 'created_at_format',
        responsive: ['lg', 'xl'],
      },
      {
        title: 'Fecha Limíte',
        dataIndex: 'fecha_limite_format',
        key: 'fecha_limite_format',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 120,
        render: (text) => (
          <Tag color={text === 'RECIBIDO' ? 'blue' : text === 'EN ATENCIÓN' ? 'green' : text === 'ARCHIVADO' ? 'red' : ''}>
            {text}
          </Tag>
        ),
      },
      {
        title: 'Detalle',
        dataIndex: 'estado',
        key: 'estado',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 80,
        render: (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {helper.tienePermiso(this.props.permisos, 'Ver detalle mesa de parte') ? (
                <Link to={`/mesa-partes/tickets-atencion/${record.id}/show`}>
                  <EyeOutlined style={{ color: '#1890ff' }} />
                </Link>
              ) : (
                <EyeOutlined style={{ color: '#b5b5b5' }} />
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <div className="page-header">
          <h2>Tickets de mesa de partes</h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 12 }}>
            <div className="topnav__search">
              <input
                type="text"
                placeholder="Buscar..."
                onChange={this.handleChangeSearch}
                value={ this.state.buscar_texto }
              />
              <i onClick={() => this.handleChangeSearchIcon()} className="bx bx-search" style={{cursor: 'pointer'}}></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {!helper.tienePermiso(this.props.permisos, 'Ver registro mesa de partes') ? (
                <Alert message="Permiso" description="No tiene permiso para ver lista de registro mesa de partes" type="error" />
              ):
                <div className="card__body">
                  <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={this.state.mesa_partes}
                    pagination={false}
                    loading={this.state.cargando}
                    size="small"
                  />
                  <div style={{ paddingTop: 12, textAlign: 'right' }}>
                    <Pagination
                      size="small"
                      showTotal={this.showTotal}
                      total={this.state.page.total}
                      current={this.state.page.number}
                      pageSize={this.state.page.per_page}
                      onChange={this.handleChangePerPage}
                      onShowSizeChange={this.handleChangePerPage}
                      pageSizeOptions={[10, 20, 30, 40]}
                      showQuickJumper
                      showSizeChanger
                    />
                  </div>
                </div>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexMesaPartes);
