import React, { Component } from 'react';
import { Row, Col, Card, Form, Input, Button, message, Upload, Modal, Alert, Radio } from 'antd';
import HabilitarColegiadoService from '../../services/api/colegiados';
import RucService from '../../services/api/ruc';
import DniService from '../../services/api/dni';
import Helpers from '../../utils/helper';
import ApiConfig from '../../services/api/config';
import {
  UploadOutlined,
  HighlightOutlined,
  LoadingOutlined,
  LinkOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import './styles.css';
const FormItem = Form.Item;

class BeneficiarioFormulario extends Component {
  state = {
    guardando: false,
    files_declaracion_jurada: [],
    files_poliza: [],
    files_dni: [],
    uploading: false,
    buscando_numero_documento: false,
    beneficiarios: [{ files_dni: [], loading: false, es_nuevo_file: true }],
    validators: {
      beneficiarios: {
        rules: [
          {
            test: (value) => {
              let es_valido = true;
              if (value && value.length > 0) {
                const beneficiarios = value.map((item) => {
                  let errores_numero_documento = [];
                  let errores_nombres = [];
                  let errores_porcentaje = [];
                  let errores_files = [];

                  const elNumeroDocumentoEstaLleno = !!item.numero_documento && item.numero_documento.trim().length > 0;
                  const elNumeroDocumentoEsMenorACaracteres =
                    !!item.numero_documento && item.numero_documento.trim().length <= 15;

                  const elNombresEstaLleno = !!item.nombres && item.nombres.trim().length > 0;
                  const elNombresEsMenorACaracteres = !item.nombres || (item.nombres && item.nombres.trim().length + 1 <= 300);

                  const elFilesEstaLleno = !!item.files_dni && item.files_dni.length > 0;

                  const elPorcentajeEstaLleno = !!item.porcentaje && item.porcentaje.trim().length > 0;

                  const esNumeroDocumentoValido = elNumeroDocumentoEstaLleno && elNumeroDocumentoEsMenorACaracteres;
                  const esNombresValido = elNombresEstaLleno && elNombresEsMenorACaracteres;
                  const esFilesValido = elFilesEstaLleno;
                  const esPorcentajeValido = elPorcentajeEstaLleno;

                  const esBeneficiarioValido = esNumeroDocumentoValido && esNombresValido && esFilesValido && esPorcentajeValido;
                  es_valido = es_valido && esNumeroDocumentoValido && esNombresValido && esFilesValido && esPorcentajeValido;

                  if (!elNumeroDocumentoEstaLleno) {
                    errores_numero_documento = [...errores_numero_documento, 'Campo obligatorio.'];
                  }

                  if (!elNumeroDocumentoEsMenorACaracteres) {
                    errores_numero_documento = [...errores_numero_documento, 'El campo acepta 11 caracteres.'];
                  }

                  if (!elNombresEstaLleno) {
                    errores_nombres = [...errores_nombres, 'Campo obligatorio.'];
                  }

                  if (!elNombresEsMenorACaracteres) {
                    errores_nombres = [...errores_nombres, 'El campo acepta 300 caracteres.'];
                  }

                  if (!elFilesEstaLleno) {
                    errores_files = [...errores_files, 'Debes de subir un archivo.'];
                  }

                  if (!elPorcentajeEstaLleno) {
                    errores_porcentaje = [...errores_porcentaje, 'Campo obligatorio.'];
                  }

                  return {
                    ...item,
                    valid: esBeneficiarioValido,
                    esNumeroDocumentoValido,
                    errores_numero_documento,
                    esNombresValido,
                    esPorcentajeValido,
                    errores_nombres,
                    errores_porcentaje,
                    esFilesValido,
                    errores_files,
                    validatorNumeroDocumento: {
                      valid: esNumeroDocumentoValido,
                      errors: errores_numero_documento,
                    },
                    validatorNombres: {
                      valid: esNombresValido,
                      errors: errores_nombres,
                    },
                    validatorPorcentaje: {
                      valid: esPorcentajeValido,
                      errors: errores_porcentaje,
                    },
                    validatorFiles: {
                      valid: esFilesValido,
                      errors: errores_files,
                    },
                  };
                });

                const validators = this.state.validators;
                validators.beneficiarios.state = beneficiarios;
                this.setState({ validators });
              }
              return es_valido;
            },
            message: '',
          },
          {
            test: (value) => {
              let valid = true;
              let total_porcentaje = 0;
              value.map((item) => {
                total_porcentaje += parseFloat(item.porcentaje);
                return item;
              });

              if (total_porcentaje === 100) {
                valid = true;
              } else {
                valid = false;
              }
              return valid;
            },
            message: 'Porcentaje de sumar el 100 %.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_declaracion_jurada: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (value && value.length > 0) {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      // files_poliza: {
      //   rules: [
      //     {
      //       test: (value) => {
      //         let valid = false;
      //         if (value && value.length > 0) {
      //           valid = true;
      //         }
      //         return valid;
      //       },
      //       message: 'Campo obligatorio.',
      //     },
      //   ],
      //   errors: [],
      //   valid: true,
      //   state: '',
      // },
      files_dni: {
        rules: [
          // {
          //   test: (value) => {
          //     let valid = false;
          //     if (value && value.length > 0) {
          //       valid = true;
          //     }
          //     return valid;
          //   },
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  handleChangeNombres = (e, fieldName, index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].nombres = e.target.value;
    this.setState({
      beneficiarios,
    });

    this.uploadValidators('beneficiarios', beneficiarios);
  };

  handleChangePorcentaje = (e, fieldName, index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].porcentaje = e.target.value;
    this.setState({
      beneficiarios,
    });

    this.uploadValidators('beneficiarios', beneficiarios);
  };

  handleChangeNumeroDocumento = (e, fieldName, index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].numero_documento = e.target.value;
    beneficiarios[index].es_nuevo_file = true;
    this.setState(
      {
        beneficiarios,
      },
      () => {
        if (beneficiarios[index].numero_documento.trim().length === 8) {
          this.buscarDni(index);
        } else if (beneficiarios[index].numero_documento.trim().length === 11) {
          this.buscarRuc(index);
        }
      }
    );

    this.uploadValidators('beneficiarios', beneficiarios);
  };

  handleClickBuscarDocumento = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    if (beneficiarios[index].numero_documento) {
      if (beneficiarios[index].numero_documento.trim().length === 8) {
        this.buscarDni(index);
      } else if (beneficiarios[index].numero_documento.trim().length === 11) {
        this.buscarRuc(index);
      } else {
        message.error('Por favor número documento no cumplete para buscar en (SUNAT O RENIEC)');
      }
    } else {
      message.error('Por favor número documento no cumplete para buscar en (SUNAT O RENIEC)');
    }
  };

  handleAceptar = () => {
    window.location.reload(true);
  };

  buscarRuc = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].loading = true;
    this.setState({ beneficiarios });
    RucService.getRuc(
      beneficiarios[index].numero_documento,
      (response) => {
        if (response && response.success) {
          beneficiarios[index].nombres = `${response.data.razonSocial}`;
          beneficiarios[index].nombres_temp = `${response.data.razonSocial}`;
          beneficiarios[index].loading = false;
          this.setState(
            {
              buscando_numero_documento: false,
              beneficiarios,
            },
            () => {
              this.uploadValidators('beneficiarios', beneficiarios);
            }
          );
        }
        if (!response.success) {
          beneficiarios[index].loading = false;
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      },
      (err) => {
        if (err) {
          beneficiarios[index].loading = false;
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      }
    );
  };

  buscarDni = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios[index].loading = true;
    this.setState({ beneficiarios });
    DniService.getDni(
      beneficiarios[index].numero_documento,
      (response) => {
        if (response && response.success) {
          beneficiarios[
            index
          ].nombres = `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`;
          beneficiarios[
            index
          ].nombres_temp = `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`;
          beneficiarios[index].loading = false;
          this.setState(
            {
              buscando_numero_documento: false,
              beneficiarios,
            },
            () => {
              this.uploadValidators('beneficiarios', beneficiarios);
            }
          );
        } else if (!response.success) {
          beneficiarios[index].loading = false;
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      },
      (err) => {
        if (err) {
          beneficiarios[index].loading = false;
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            beneficiarios,
          });
        }
      }
    );
  };

  handleSend = () => {
    const valid = this.isFormValid();
    if (valid) {
      this.setState({ guardando: true });
      const colegiatura = this.props.colegiatura;
      colegiatura.beneficiarios = this.state.beneficiarios;
      colegiatura.files_poliza = this.state.files_poliza;
      colegiatura.files_declaracion_jurada = this.state.files_declaracion_jurada;
      colegiatura.es_afiliado_fondo_mutual = false;

      HabilitarColegiadoService.createHabilitarColegiado(
        colegiatura,
        (response) => {
          if (response) {
            Modal.success({
              title: 'Formulario enviado correctamente',
              content: <div style={{ fontSize: '13px' }}>{`Gracias su ticket de atención es el N° ${response.id}.`}</div>,
              onOk: this.handleAceptar,
            });
          }
          this.setState({ guardando: false });
        },
        (err) => {
          if (err) {
            message.error(err.message);
          }
          this.setState({ guardando: false });
        }
      );
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleLimpiar = () => {
    this.setState(
      {
        numero_documento: null,
        guardando: false,
        files_declaracion_jurada: [],
        files_poliza: [],
        files_dni: [],
        uploading: false,
        buscando_numero_documento: false,
      },
      () => {
        this.handleAceptar();
      }
    );
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  handleUpload = () => {
    const { files } = this.state;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files[]', file);
    });
    this.setState({
      uploading: true,
    });
    // You can use any AJAX library you like
    fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        this.setState({
          files: [],
        });
        message.success('upload successfully.');
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        this.setState({
          uploading: false,
        });
      });
  };

  onClickCeremonia = () => {
    window.open(`${ApiConfig.constants.URL_API}/ceremonia.pdf`, '_blank');
  };

  onClickDeclaracionJurada = () => {
    window.open(`${ApiConfig.constants.URL_API}/declaracion_jurada.pdf`, '_blank');
  };

  onClickPoliza = () => {
    window.open(`${ApiConfig.constants.URL_API}/poliza.pdf`, '_blank');
  };

  handleClickAgregar = () => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios.push({ files_dni: [], loading: false });
    this.setState({ beneficiarios });
  };

  handleClickEliminar = (index) => {
    const beneficiarios = this.state.beneficiarios.slice();
    beneficiarios.splice(index, 1);
    this.setState({ beneficiarios });
  };

  getFormatoHTMLDeErrores = (validator) => {
    if (validator && !validator.valid && validator.errors.length > 0) {
      const errors = validator.errors.map((info, index) => <li key={index}>{info}</li>);
      return <ul className="errores">{errors}</ul>;
    }
    return '';
  };

  render() {
    const { uploading, files_declaracion_jurada, files_dni } = this.state;
    const props_declaracion_jurada = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_declaracion_jurada.indexOf(file);
          const newFileList = state.files_declaracion_jurada.slice();
          newFileList.splice(index, 1);
          return {
            files_declaracion_jurada: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_declaracion_jurada.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_declaracion_jurada: [...state.files_declaracion_jurada, file],
            }),
            () => {
              this.uploadValidators('files_declaracion_jurada', this.state.files_declaracion_jurada);
            }
          );
        }
        return false;
      },
      files_declaracion_jurada,
    };

    // const props_poliza_seguro_mutual = {
    //   onRemove: (file) => {
    //     this.setState((state) => {
    //       const index = state.files_poliza.indexOf(file);
    //       const newFileList = state.files_poliza.slice();
    //       newFileList.splice(index, 1);
    //       return {
    //         files_poliza: newFileList,
    //       };
    //     });
    //   },
    //   beforeUpload: (file) => {
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     if (!isLt2M) {
    //       message.error('¡El documento debe de ser menor a 2MB!');
    //     }

    //     const isCountFile = this.state.files_poliza.length < 1;
    //     if (!isCountFile) {
    //       message.error('¡Solo se puede subir cantidad de archivos: 1!');
    //       return true;
    //     }

    //     const esValido = isLt2M;

    //     if (esValido) {
    //       this.setState(
    //         (state) => ({
    //           files_poliza: [...state.files_poliza, file],
    //         }),
    //         () => {
    //           this.uploadValidators('files_poliza', this.state.files_poliza);
    //         }
    //       );
    //     }
    //     return false;
    //   },
    //   files_poliza,
    // };

    return (
      <>
        <div className="container-sm">
          <br />
          <div>
            <Alert
              message={
                <div>
                  ¿Desea afiliarse al Seguro Mutual?
                  <br />
                  <Radio.Group onChange={(e) => this.props.onChangeDeseaAfiliarseNo(e)} value={this.props.es_afiliado_fondo_mutual}>
                    <Radio value={true}>SI</Radio>
                    <Radio value={false}>NO</Radio>
                  </Radio.Group>
                </div>
              }
              type="info"
            />
          </div>
          <Card
            className="colegiatura"
            type="inner"
            title={
              <div>
                <HighlightOutlined /> &nbsp; Ingresa tus datos de beneficiarios 
              </div>
            }
            extra={null}
          >
            {this.state.beneficiarios &&
              this.state.beneficiarios.length > 0 &&
              this.state.beneficiarios.map((item, index) => {
                return (
                  <Form key={index} layout="vertical" className="beneficiario__formulario--item">
                    <Row>
                      <Col className="padding__0-left" style={{ paddingRight: 16 }} xs={24} sm={24} md={16} lg={16} xl={16}>
                        <FormItem
                          label="Número Documento"
                          validateStatus={
                            this.state.validators.beneficiarios &&
                            this.state.validators.beneficiarios.state &&
                            this.state.validators.beneficiarios.state[index] &&
                            !this.state.validators.beneficiarios.state[index].esNumeroDocumentoValido
                              ? 'error'
                              : 'success'
                          }
                          help={this.state.errors}
                          required={true}
                        >
                          <Input.Group compact>
                            <Input
                              onChange={(e) => this.handleChangeNumeroDocumento(e, 'numero_documento', index)}
                              style={{ width: `calc(100% - ${item.loading ? '117.5px' : '97.5px'})` }}
                              value={item.numero_documento}
                              autoComplete="off"
                              id="numero_documento"
                              placeholder="Ingrese la descripción de su archivo."
                            />
                            <Button type="primary" onClick={() => this.handleClickBuscarDocumento(index)} loading={item.loading}>
                              Consultar
                            </Button>
                          </Input.Group>

                          {this.state.validators.beneficiarios &&
                          this.state.validators.beneficiarios.state &&
                          this.state.validators.beneficiarios.state[index] &&
                          !this.state.validators.beneficiarios.state[index].esNumeroDocumentoValido
                            ? this.getFormatoHTMLDeErrores(
                                this.state.validators.beneficiarios.state[index].validatorNumeroDocumento
                              )
                            : ''}
                        </FormItem>
                      </Col>
                      <Col className="padding__0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormItem
                          label="Porcentaje"
                          validateStatus={
                            this.state.validators.beneficiarios &&
                            this.state.validators.beneficiarios.state &&
                            this.state.validators.beneficiarios.state[index] &&
                            !this.state.validators.beneficiarios.state[index].esPorcentajeValido
                              ? 'error'
                              : 'success'
                          }
                          help={this.state.errors}
                          required={true}
                        >
                          <Input
                            onChange={(e) => this.handleChangePorcentaje(e, 'porcentaje', index)}
                            value={item.porcentaje}
                            autoComplete="off"
                            id="porcentaje"
                            placeholder="Porcentaje."
                          />
                          {this.state.validators.beneficiarios &&
                          this.state.validators.beneficiarios.state &&
                          this.state.validators.beneficiarios.state[index] &&
                          !this.state.validators.beneficiarios.state[index].esPorcentajeValido
                            ? this.getFormatoHTMLDeErrores(this.state.validators.beneficiarios.state[index].validatorPorcentaje)
                            : ''}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="padding__0-left" style={{ paddingRight: 16 }} xs={24} sm={24} md={16} lg={16} xl={16}>
                        <FormItem
                          label="Nombre Completo"
                          validateStatus={
                            this.state.validators.beneficiarios &&
                            this.state.validators.beneficiarios.state &&
                            this.state.validators.beneficiarios.state[index] &&
                            !this.state.validators.beneficiarios.state[index].esNombresValido
                              ? 'error'
                              : 'success'
                          }
                          help={this.state.errors}
                          required={true}
                        >
                          <Input
                            showCount
                            maxLength={300}
                            onChange={(e) => this.handleChangeNombres(e, 'nombres', index)}
                            value={item.nombres}
                            autoComplete="off"
                            id="nombres"
                            placeholder="Ingrese su nombre completo."
                          />
                          {this.state.validators.beneficiarios &&
                          this.state.validators.beneficiarios.state &&
                          this.state.validators.beneficiarios.state[index] &&
                          !this.state.validators.beneficiarios.state[index].esNombresValido
                            ? this.getFormatoHTMLDeErrores(this.state.validators.beneficiarios.state[index].validatorNombres)
                            : ''}
                        </FormItem>
                      </Col>
                      <Col className="padding__0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormItem label="DNI (Ambos Lados)" help={this.state.errors} required={true}>
                          <Upload
                            {...{
                              onRemove: (file) => {
                                this.setState((state) => {
                                  const index_ = state.beneficiarios[index].files_dni.indexOf(file);
                                  const newFileList = state.beneficiarios[index].files_dni.slice();
                                  newFileList.splice(index_, 1);

                                  const beneficiarios = state.beneficiarios;
                                  beneficiarios[index].files_dni = newFileList;
                                  return {
                                    files_dni: newFileList,
                                  };
                                });
                              },
                              beforeUpload: (file) => {
                                const beneficiarios = this.state.beneficiarios.slice();
                                const isLt2M = file.size / 1024 / 1024 < 2;
                                if (!isLt2M) {
                                  message.error('¡El documento debe de ser menor a 2MB!');
                                }

                                const isCountFile = beneficiarios[index].files_dni && beneficiarios[index].files_dni.length < 1;
                                if (!isCountFile) {
                                  message.error('¡Solo se puede subir cantidad de archivos: 1!');
                                  return true;
                                }

                                const esValido = isLt2M;

                                if (esValido) {
                                  beneficiarios[index].files_dni = [...beneficiarios[index].files_dni, file];
                                  this.setState({ beneficiarios }, () => {
                                    this.uploadValidators('files_dni', beneficiarios[index].files_dni);
                                    this.uploadValidators('beneficiarios', beneficiarios);
                                  });
                                }
                                return false;
                              },
                              files_dni,
                            }}
                          >
                            <Button icon={<UploadOutlined />}>
                              Seleccionar archivo
                              {uploading && <LoadingOutlined />}
                            </Button>
                          </Upload>
                          {/* <div style={{ fontSize: 12, paddingTop: 4 }}>2 MB por archivo Cantidad de archivos: 1</div> */}
                          {this.state.validators.beneficiarios &&
                          this.state.validators.beneficiarios.state &&
                          this.state.validators.beneficiarios.state[index] &&
                          !this.state.validators.beneficiarios.state[index].esFilesValido
                            ? this.getFormatoHTMLDeErrores(this.state.validators.beneficiarios.state[index].validatorFiles)
                            : ''}
                          {index !== 0 && (
                            <div className="delete__beneficiario" onClick={() => this.handleClickEliminar(index)}>
                              <DeleteOutlined /> Eliminar
                            </div>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Form>
                );
              })}
            <div>{this.mostrarErroresValidacion('beneficiarios')}</div>
            <div
              style={{ color: '#0074ff', fontWeight: 'bold', paddingBottom: 8, cursor: 'pointer' }}
              onClick={this.handleClickAgregar}
            >
              <PlusOutlined /> Agregar otro beneficiario
            </div>
            <Card
              className="colegiatura-file"
              type="inner"
              title={
                <div>
                  <LinkOutlined /> &nbsp; Archivo de muestra
                </div>
              }
              extra={null}
            >
              <Row>
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                  <Button onClick={this.onClickDeclaracionJurada} icon={<UploadOutlined />}>
                    Declaración jurada de afiliación
                    {uploading && <LoadingOutlined />}
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                  {/* <Button onClick={this.onClickPoliza} icon={<UploadOutlined />}>
                    Póliza de Seguro
                    {uploading && <LoadingOutlined />}
                  </Button> */}
                </Col>
              </Row>
            </Card>
            <br />
            <Card
              className="colegiatura-file"
              type="inner"
              title={
                <div>
                  <LinkOutlined /> &nbsp; Archivo a Adjuntar
                </div>
              }
              extra={null}
            >
              <Row>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> Declaración Jurada:
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>1 archivo, máximo 2MB</div>
                  <Upload {...props_declaracion_jurada}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_declaracion_jurada')}
                </Col>
                {/* <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> Poliza de Seguro Mutual:
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>1 archivo, máximo 2MB</div>
                  <Upload {...props_poliza_seguro_mutual}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_poliza')}
                </Col> */}
              </Row>
            </Card>
            <br />
            <div className="m-pl-16">
              <Button type="primary" onClick={this.handleSend} htmlType="submit" loading={this.state.guardando} size="large">
                Enviar
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleLimpiar} size="large">
                Limpiar
              </Button>
            </div>
          </Card>
          <br />
        </div>
      </>
    );
  }
}

export default BeneficiarioFormulario;
