import React, { Component } from 'react';
import HabilitarColegiatura from '../../../components/HabilitarColegiatura/HabilitarColegiaturaFormulario';

class IndexHabilitarColegiatura extends Component {
  render() {
    return (
      <>
        <HabilitarColegiatura location={this.props.location} />
      </>
    );
  }
}

export default IndexHabilitarColegiatura;
