import { CARGAR_USUARIO, CARGAR_PERMISOS } from './actions';

export const user = (state = {}, action) => {
  switch (action.type) {
    case CARGAR_USUARIO:
      return {
        ...state,
        ...action.usuario,
      };
    case CARGAR_PERMISOS:
      return {
        ...state,
        permisos: action.permisos,
      };
    default:
      return state;
  }
};
