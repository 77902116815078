import React, { Component } from 'react';
import { message, Form, Input, Button, Spin, DatePicker, Tabs } from 'antd';
import ConfiguracionService from '../../../../services/api/configuraciones';
import Helpers from '../../../../utils/helper';
import { connect } from 'react-redux';
import moment from 'moment';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 4 },
  },
};

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';
class IndexProductosColegiatura extends Component {
  state = {
    guardando: false,
    cargando: false,
    configuracion: null,
    id: null,
    url_nuevo: null,
    url_traslado: null,
    url_cambio_beneficiario: null,
    cuota_ordinaria: null,
    fraccionamiento: null,
    fondo_mortuorio: null,
    adelanto_fondo_mortuorio: null,
    validators: {
      url_nuevo: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      url_traslado: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          // {
          //   test: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9- ]*$/,
          //   message: 'El campo acepta solo alfanuméricos.'
          // },
          // {
          //   test: (value) => value.trim().length <= 100,
          //   message: 'El campo acepta 100 caracteres.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      url_cambio_beneficiario: {
        rules: [
          // {
          //   test: (value) => value.trim().length > 0,
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      cuota_ordinaria: {
        rules: [
          // {
          //   test: (value) => value.trim().length > 0,
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      fraccionamiento: {
        rules: [
          // {
          //   test: (value) => value.trim().length > 0,
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      fondo_mortuorio: {
        rules: [
          // {
          //   test: (value) => value.trim().length > 0,
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      adelanto_fondo_mortuorio: {
        rules: [
          // {
          //   test: (value) => value.trim().length > 0,
          //   message: 'Campo obligatorio.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  componentDidMount() {
    this.cargandoConfiguracions();
  }

  cargandoConfiguracions = () => {
    this.setState({ cargando: true });
    ConfiguracionService.getConfiguracions(
      '',
      (configuracion) => {
        if (configuracion) {
          this.setState({
            cargando: false,
            id: configuracion.id,
            url_nuevo: configuracion.url_nuevo,
            url_traslado: configuracion.url_traslado,
            url_cambio_beneficiario: configuracion.url_cambio_beneficiario,
            cuota_ordinaria: configuracion.cuota_ordinaria,
            fraccionamiento: configuracion.fraccionamiento,
            fondo_mortuorio: configuracion.fondo_mortuorio,
            adelanto_fondo_mortuorio: configuracion.adelanto_fondo_mortuorio,
            fecha_1: configuracion.fecha_1,
            fecha_2: configuracion.fecha_2,
          });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
        this.setState({ cargando: false });
      }
    );
  };

  handleChangeUrlNuevoMiembro = (e, fieldName) => {
    this.setState({
      url_nuevo: e.target.value,
    });
  };

  handleChangeUrlCambioBeneficiario = (e, fieldName) => {
    this.setState({
      url_cambio_beneficiario: e.target.value,
    });
  };

  handleChangeCuotaOrdinaria = (e, fieldName) => {
    this.setState({
      cuota_ordinaria: e.target.value,
    });
  };

  handleChangeFraccionamiento = (e, fieldName) => {
    this.setState({
      fraccionamiento: e.target.value,
    });
  };
  
  handleChangeFondoMortuorio = (e, fieldName) => {
    this.setState({
      fondo_mortuorio: e.target.value,
    });
  };

  handleChangeAdelantoFondoMortuorio  = (e, fieldName) => {
    this.setState({
      adelanto_fondo_mortuorio: e.target.value,
    });
  };

  handleChangeUrlTraslado = (e, fieldName) => {
    this.setState({
      url_traslado: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeFecha1 = (date, fieldName) => {
    let fecha_1 = '';
    if (date) {
      fecha_1 = moment(date).format('DD/MM/YYYY');
    }
    this.setState({
      fecha_1: fecha_1,
    });
  };

  handleChangeFecha2 = (date, fieldName) => {
    let fecha_2 = '';
    if (date) {
      fecha_2 = moment(date).format('DD/MM/YYYY');
    }
    this.setState({
      fecha_2: fecha_2,
    });
  };

  handleSave = () => {
    if (Helpers.tienePermiso(this.props.permisos, 'Ver detalle estado de cuenta')) {
      const valid = this.isFormValid();
      if (valid) {
        this.setState({ guardando: true });
        if (this.state.id) {
          this.updateConfiguracion();
        } else {
          this.createConfiguracion();
        }

        this.nameInput.focus();
      } else {
        message.destroy();
        message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
      }
    } else {
      message.error('No tienes permiso para actualizar.');
    }
  };
  
  
  
  createConfiguracion = () => {
    ConfiguracionService.createConfiguracion(
      {
        id: this.state.id,
        url_nuevo: this.state.url_nuevo || '',
        url_traslado: this.state.url_traslado || '',
        url_cambio_beneficiario: this.state.url_cambio_beneficiario || '',
        cuota_ordinaria: this.state.cuota_ordinaria || '',
        fraccionamiento: this.state.fraccionamiento || '',
        fondo_mortuorio: this.state.fondo_mortuorio || '',
        adelanto_fondo_mortuorio: this.state.adelanto_fondo_mortuorio || '',
        fecha_1: this.state.fecha_1 || '',
        fecha_2: this.state.fecha_2 || '',
      },
      (response) => {
        if (response) {
          this.setState({
            guardando: false,
            id: response.id,
            url_nuevo: response.url_nuevo,
            url_traslado: response.url_traslado,
            url_cambio_beneficiario: this.state.url_cambio_beneficiario,
            cuota_ordinaria: this.state.cuota_ordinaria,
            fraccionamiento: this.state.fraccionamiento,
            fondo_mortuorio: this.state.fondo_mortuorio,
            adelanto_fondo_mortuorio: this.state.adelanto_fondo_mortuorio || '',
          });
          message.success('Se guardo con exíto.');
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
        this.setState({ guardando: false });
      }
    );
  };

  updateConfiguracion = () => {
    ConfiguracionService.updateConfiguracion(
      {
        id: this.state.id,
        url_nuevo: this.state.url_nuevo || '',
        url_traslado: this.state.url_traslado || '',
        url_cambio_beneficiario: this.state.url_cambio_beneficiario || '',
        cuota_ordinaria: this.state.cuota_ordinaria  || '',
        fraccionamiento: this.state.fraccionamiento  || '',
        fondo_mortuorio: this.state.fondo_mortuorio  || '',
        adelanto_fondo_mortuorio: this.state.adelanto_fondo_mortuorio || '',
        fecha_1: this.state.fecha_1 || '',
        fecha_2: this.state.fecha_2 || '',
      },
      (response) => {
        if (response) {
          this.setState({
            guardando: false,
            id: response.id,
            url_nuevo: response.url_nuevo,
            url_traslado: response.url_traslado,
            url_cambio_beneficiario: response.url_cambio_beneficiario,
            cuota_ordinaria: this.state.cuota_ordinaria,
            fraccionamiento: this.state.fraccionamiento,
            fondo_mortuorio: this.state.fondo_mortuorio,
            adelanto_fondo_mortuorio: this.state.adelanto_fondo_mortuorio || '',
          });
          message.success('Se actualizo con exíto.');
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
        this.setState({ guardando: false });
      }
    );
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  render() {
    return (
      <div>
        <h2 className="page-header">Productos Pasarelas</h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <Spin spinning={this.state.cargando}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Productos Colegiatura" key="1">
                    <FormItem
                      {...formItemLayout}
                      label="Url Traslado"
                      validateStatus={this.state.validators.url_traslado.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={true}
                    >
                      <Input
                        onChange={(e) => this.handleChangeUrlTraslado(e, 'url_traslado')}
                        value={this.state.url_traslado}
                        autoComplete="off"
                        autoFocus
                        id="url_traslado"
                      />
                      {this.mostrarErroresValidacion('url_traslado')}
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      label="Url Nuevo Miembro"
                      validateStatus={this.state.validators.url_nuevo.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={true}
                    >
                      <Input
                        onChange={(e) => this.handleChangeUrlNuevoMiembro(e, 'url_nuevo')}
                        value={this.state.url_nuevo}
                        autoComplete="off"
                        id="url_nuevo"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                      />
                      {this.mostrarErroresValidacion('url_nuevo')}
                    </FormItem>
                  </TabPane>
                  <TabPane tab="Productos Fondo Mutual" key="2">
                    <FormItem
                      {...formItemLayout}
                      label="Url Cambio Beneficiario"
                      validateStatus={this.state.validators.url_cambio_beneficiario.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={false}
                    >
                      <Input
                        onChange={(e) => this.handleChangeUrlCambioBeneficiario(e, 'url_cambio_beneficiario')}
                        value={this.state.url_cambio_beneficiario}
                        autoComplete="off"
                        id="url_cambio_beneficiario"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                      />
                      {this.mostrarErroresValidacion('url_cambio_beneficiario')}
                    </FormItem>
                  </TabPane>
                  <TabPane tab="Fechas para Colegiatura" key="3">
                    <FormItem {...formItemLayout} label="Fecha Colegiatura 1" help={this.state.errors} required={false}>
                      <DatePicker
                        value={
                          this.state.fecha_1 && this.state.fecha_1 !== 'Invalid date'
                            ? moment(this.state.fecha_1, dateFormat)
                            : null
                        }
                        format={dateFormat}
                        id="fecha_1"
                        placeholder="DD/MM/YYYY"
                        onChange={(e) => this.handleChangeFecha1(e, 'fecha_1')}
                      />
                    </FormItem>
                    <FormItem {...formItemLayout} label="Fecha Colegiatura 2" help={this.state.errors} required={false}>
                      <DatePicker
                        value={
                          this.state.fecha_2 && this.state.fecha_2 !== 'Invalid date'
                            ? moment(this.state.fecha_2, dateFormat)
                            : null
                        }
                        format={dateFormat}
                        id="fecha_2"
                        placeholder="DD/MM/YYYY"
                        onChange={(e) => this.handleChangeFecha2(e, 'fecha_2')}
                      />
                    </FormItem>
                  </TabPane>
                  <TabPane tab="Pago por Producto" key="4">
                    <FormItem
                      {...formItemLayout}
                      label="Cuota Ordinaria"
                      validateStatus={this.state.validators.cuota_ordinaria.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={false}
                    >
                      <Input
                        onChange={(e) => this.handleChangeCuotaOrdinaria(e, 'cuota_ordinaria')}
                        value={this.state.cuota_ordinaria}
                        autoComplete="off"
                        id="cuota_ordinaria"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                      />
                      {this.mostrarErroresValidacion('cuota_ordinaria')}
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      label="Francionamiento"
                      validateStatus={this.state.validators.fraccionamiento.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={false}
                    >
                      <Input
                        onChange={(e) => this.handleChangeFraccionamiento(e, 'fraccionamiento')}
                        value={this.state.fraccionamiento}
                        autoComplete="off"
                        id="fraccionamiento"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                      />
                      {this.mostrarErroresValidacion('fraccionamiento')}
                    </FormItem>
                    <FormItem
                      {...formItemLayout}
                      label="Fondo Mortuorio"
                      validateStatus={this.state.validators.fondo_mortuorio.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={false}
                    >
                      <Input
                        onChange={(e) => this.handleChangeFondoMortuorio(e, 'fondo_mortuorio')}
                        value={this.state.fondo_mortuorio}
                        autoComplete="off"
                        id="fondo_mortuorio"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                      />
                      {this.mostrarErroresValidacion('fondo_mortuorio')}
                    </FormItem>
                  </TabPane>
                  <TabPane tab="Adelanto por producto" key="5">
                    <FormItem
                      {...formItemLayout}
                      label="Fondo Mortuorio"
                      validateStatus={this.state.validators.adelanto_fondo_mortuorio.valid ? 'success' : 'error'}
                      help={this.state.errors}
                      required={false}
                    >
                      <Input
                        onChange={(e) => this.handleChangeAdelantoFondoMortuorio(e, 'adelanto_fondo_mortuorio')}
                        value={this.state.adelanto_fondo_mortuorio}
                        autoComplete="off"
                        id="adelanto_fondo_mortuorio"
                        ref={(input) => {
                          this.nameInput = input;
                        }}
                      />
                      {this.mostrarErroresValidacion('adelanto_fondo_mortuorio')}
                    </FormItem>
                  </TabPane>
                </Tabs>
                  <Form>
                    <FormItem {...formItemLayoutWithOutLabel} label="">
                      <Button type="primary" onClick={this.handleSave} htmlType="submit" loading={this.state.guardando}>
                        {this.state.id ? 'Actualizar' : 'Guardar'}
                      </Button>
                    </FormItem>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexProductosColegiatura);
