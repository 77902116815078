import React, { Component } from 'react';
import { Modal } from 'antd';

import FraccionarFormulario from './FraccionarFormulario';

class FraccionarFormularioModal extends Component {
  state = {
    crear_otro: false,
  };

  setFormRef = (ref) => {
    this.formRef = ref;
  };

  handleClose = () => {
    this.props.onCancel();
  };

  handleFormSubmit = (data) => {
    this.props.onFormSubmit(data, this.state.crear_otro);
  };


  render() {
    return (
      <div>
        <Modal
          title={'Fraccionar'}
          visible={this.props.visible}
          onCancel={this.handleClose}
          width={500}
          footer={null}
          maskClosable={false}
        >
          <FraccionarFormulario
            ref={this.setFormRef}
            onFormSubmit={this.handleFormSubmit}
            onFormCancel={this.handleClose}
            fraccionar={this.props.fraccionar}
            guardando={this.props.guardando}
            importe_total={this.props.importe_total}
            errores={this.props.errores}
          />
        </Modal>
      </div>
    );
  }
}

export default FraccionarFormularioModal;
