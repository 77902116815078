import React, { Component } from 'react';
import { Row, Col, Card, Select, Form, Input, Button, message, Upload, Checkbox, Modal } from 'antd';
import MesaParteService from '../../services/api/mesa_partes';
import RucService from '../../services/api/ruc';
import DniService from '../../services/api/dni';
import Helpers from '../../utils/helper';
import { UploadOutlined, UserOutlined, LinkOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import './styles.css';
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

class MpvFormulario extends Component {
  state = {
    tipo_tramite_formato: null,
    tipo_tramite_codigo: null,
    asunto: null,
    tipo_documento_formato: null,
    tipo_documento_codigo: null,
    numero_documento: null,
    contenido: null,
    direccion: null,
    correo_electronico: null,
    celular: null,
    descripcion_archivo: null,
    estado: null,
    responsable: null,
    ticket_id: null,
    ticket_formato: null,
    fecha_limite: null,
    descripcion: '',
    codigo_matricula: null,
    es_colegiado: false,
    guardando: false,
    files: [],
    uploading: false,
    buscando_numero_documento: false,
    validators: {
      tipo_documento_codigo: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      tipo_tramite_codigo: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      asunto: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length + 1 <= 300,
            message: 'El campo acepta 300 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      contenido: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length + 1 <= 2000,
            message: 'El campo acepta 2000 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      direccion: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      correo_electronico: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => {
              if (value.trim().length > 0) {
                if (/^[^@]+@[^]+\..+/.test(value)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            },
            message: 'El campo acepta solo correo electrónico.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      celular: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/,
            message: 'El campo acepta solo celular.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      descripcion_archivo: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length <= 500,
            message: 'El campo acepta 500 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      numero_documento: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[0-9 ]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => {
              let valid = true;
              if (this.state.tipo_documento_codigo === '6') {
                if (value.trim().length === 11) {
                  valid = true;
                } else {
                  valid = false;
                }
              }
              return valid;
            },
            message: 'El campo acepta 11 caracteres.',
          },
          {
            test: (value) => {
              let valid = true;
              if (this.state.tipo_documento_codigo === '1') {
                if (value.trim().length === 8) {
                  valid = true;
                } else {
                  valid = false;
                }
              }
              return valid;
            },
            message: 'El campo acepta 8 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      codigo_matricula: {
        rules: [
          {
            test: (value) => {
              let valid = true;
              if (this.state.es_colegiado) {
                if (value.trim().length > 0) {
                  valid = true;
                } else {
                  valid = false;
                }
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (value && value.length > 0) {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  handleChangeTramite = (value, opt) => {
    this.setState({
      tipo_tramite_codigo: value,
      tipo_tramite_formato: opt.children,
    });

    this.uploadValidators('tipo_tramite_codigo', value);
  };

  handleChangeTipoDocumento = (value, opt) => {
    this.setState(
      {
        tipo_documento_codigo: value,
        tipo_documento_formato: opt.children,
      },
      () => {
        this.uploadValidators('numero_documento', this.state.numero_documento);
      }
    );

    this.uploadValidators('tipo_documento_codigo', value);
  };

  handleChangeAsunto = (e, fieldName) => {
    this.setState({
      asunto: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeContenido = (e, fieldName) => {
    this.setState({
      contenido: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeDireccion = (e, fieldName) => {
    this.setState({
      direccion: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeCorreoElectronico = (e, fieldName) => {
    this.setState({
      correo_electronico: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeCelular = (e, fieldName) => {
    this.setState({
      celular: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeCodigoMatricula = (e, fieldName) => {
    this.setState({
      codigo_matricula: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeDescripcionArchivo = (e, fieldName) => {
    this.setState({
      descripcion_archivo: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeNumeroDocumento = (e, fieldName) => {
    this.setState(
      {
        numero_documento: e.target.value,
      },
      () => {
        if (this.state.tipo_documento_codigo === '1') {
          if (this.state.numero_documento.trim().length === 8) {
            this.buscarDni();
          }
        } else if (this.state.tipo_documento_codigo === '6') {
          if (this.state.numero_documento.trim().length === 11) {
            this.buscarRuc();
          }
        }
      }
    );

    this.uploadValidators(fieldName, e.target.value);
  };

  onChangeEsColegiado = (e) => {
    this.setState({
      es_colegiado: e.target.checked,
    });
  };

  handleAceptar = () => {
    window.location.reload(true);
  };

  buscarRuc = () => {
    this.setState({ buscando_numero_documento: true });
    RucService.getRuc(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.razonSocial}`,
            nombres_temp: `${response.data.razonSocial}`,
          });
        }
        if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  buscarDni = () => {
    this.setState({ buscando_numero_documento: true });
    DniService.getDni(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
            nombres_temp: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
          });
        } else if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  handleSend = () => {
    const valid = this.isFormValid();
    if (valid) {
      this.setState({ guardando: true });
      MesaParteService.createMpv(
        this.state,
        (response) => {
          if (response) {
            Modal.success({
              title: 'Formulario enviado correctamente',
              content: <div style={{ fontSize: '13px' }}>{`Gracias su ticket de atención es el N° ${response.ticket}.`}</div>,
              onOk: this.handleAceptar,
            });
            this.handleLimpiar();
          }
          this.setState({ guardando: false });
        },
        (err) => {
          if (err) {
            message.error(err.message);
          }
          this.setState({ guardando: false });
        }
      );
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleLimpiar = () => {
    this.setState({
      tipo_tramite_formato: '',
      tipo_tramite_codigo: '',
      asunto: null,
      tipo_documento_formato: null,
      tipo_documento_codigo: null,
      numero_documento: null,
      contenido: null,
      direccion: null,
      correo_electronico: null,
      celular: null,
      descripcion_archivo: null,
      archivo_id: null,
      estado: null,
      responsable: null,
      ticket_id: null,
      ticket_formato: null,
      fecha_limite: null,
      descripcion: '',
      codigo_matricula: null,
      es_colegiado: false,
      files: [],
      nombres,
      nombres_temp,
    });
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  handleUpload = () => {
    const { files } = this.state;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files[]', file);
    });
    this.setState({
      uploading: true,
    });
    // You can use any AJAX library you like
    fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        this.setState({
          files: [],
        });
        message.success('upload successfully.');
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        this.setState({
          uploading: false,
        });
      });
  };

  render() {
    const { uploading, files } = this.state;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files.indexOf(file);
          const newFileList = state.files.slice();
          newFileList.splice(index, 1);
          return {
            files: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        // const isPDFandDOC = file.type === 'application/pdf' ? 'application/pdf': file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '';

        // if (!isPDFandDOC) {
        //   message.error('¡Solo puedes subir archivos PDF y DOC!');
        // }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files.length < 5;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 5!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files: [...state.files, file],
            }),
            () => {
              this.uploadValidators('files', this.state.files);
            }
          );
        }
        return false;
      },
      files,
    };
    return (
      <div className="container">
        {/* <div className="mpv__title">Mesa de Partes Virtual</div> */}
        <Card
          className="mpv"
          type="inner"
          title={
            <div>
              <EditOutlined /> &nbsp; Registro de Expediente MPV
            </div>
          }
          extra={null}
        >
          <Row>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <div className="mpv-labal">Trámite:</div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <FormItem
                label={null}
                validateStatus={this.state.validators.tipo_tramite_codigo.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Select style={{ width: '100%' }} onChange={this.handleChangeTramite} placeholder="Seleccione">
                  <Option value="1">Órganos y Consejos </Option>
                  <Option value="2">Comisiones técnicas y de estudio</Option>
                  <Option value="3">Administrativo (áreas funcionales)</Option>
                  <Option value="4">Miembros y agremiados</Option>
                  <Option value="5">Otros</Option>
                </Select>
                {this.mostrarErroresValidacion('tipo_tramite_codigo')}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <div className="mpv-labal">Asunto de la Solicitud:</div>
            </Col>
            <Col xs={24} sm={24} md={19} lg={19} xl={19}>
              <FormItem
                label={null}
                validateStatus={this.state.validators.asunto.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Input
                  showCount
                  maxLength={300}
                  onChange={(e) => this.handleChangeAsunto(e, 'asunto')}
                  value={this.state.asunto}
                  autoComplete="off"
                  id="asunto"
                  placeholder="Registre en forma clara el asunto por el cual ingresa el documento o nombre del procedimiento."
                />
                {this.mostrarErroresValidacion('asunto')}
              </FormItem>
            </Col>
          </Row>
          <Card
            className="mpv-administrado"
            type="inner"
            title={
              <div>
                <UserOutlined /> &nbsp; Datos del Administrado
              </div>
            }
            extra={null}
          >
            <Row>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <div className="mpv-labal">Tipo de Doc.:</div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <FormItem
                  label={null}
                  validateStatus={this.state.validators.tipo_tramite_codigo.valid ? 'success' : 'error'}
                  help={this.state.errors}
                  required={true}
                >
                  <Select style={{ width: '100%' }} onChange={this.handleChangeTipoDocumento} placeholder="Seleccione">
                    <Option value="6">RUC</Option>
                    <Option value="1">DNI</Option>
                    <Option value="7">PASAPORTE</Option>
                    <Option value="4">CARNET DE EXTRANJERIA</Option>
                    <Option value="0">PERMISO TEMPORAL DE PERMANENCIA</Option>
                  </Select>
                  {this.mostrarErroresValidacion('tipo_tramite_codigo')}
                </FormItem>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <div className="mpv-labal">
                  Número de Doc.:
                  {this.state.buscando_numero_documento && <LoadingOutlined />}
                </div>
              </Col>
              <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                <FormItem
                  label={null}
                  validateStatus={this.state.validators.numero_documento.valid ? 'success' : 'error'}
                  help={this.state.errors}
                  required={true}
                >
                  <Input
                    onChange={(e) => this.handleChangeNumeroDocumento(e, 'numero_documento')}
                    value={this.state.numero_documento}
                    autoComplete="off"
                    id="numero_documento"
                    placeholder="Número de Doc."
                  />
                  {this.mostrarErroresValidacion('numero_documento')}
                </FormItem>
                {this.state.nombres_temp && <div style={{ marginBottom: 12 }}>{this.state.nombres_temp}</div>}
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <div className="mpv-labal">Contenido:</div>
              </Col>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <FormItem
                  label={null}
                  validateStatus={this.state.validators.contenido.valid ? 'success' : 'error'}
                  help={this.state.errors}
                  required={true}
                >
                  <TextArea
                    showCount
                    maxLength={2000}
                    onChange={(e) => this.handleChangeContenido(e, 'contenido')}
                    value={this.state.contenido}
                    autoComplete="off"
                    id="contenido"
                    style={{ height: 100 }}
                    placeholder="Ingrese en forma detallada el contenido de su solicitud, procedimiento o trámite."
                  />
                  {this.mostrarErroresValidacion('contenido')}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <div className="mpv-labal">Dirección:</div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <FormItem
                  label={null}
                  validateStatus={this.state.validators.direccion.valid ? 'success' : 'error'}
                  help={this.state.errors}
                  required={true}
                >
                  <Input
                    onChange={(e) => this.handleChangeDireccion(e, 'direccion')}
                    value={this.state.direccion}
                    autoComplete="off"
                    id="direccion"
                    placeholder="Ingrese la Dirección"
                  />
                  {this.mostrarErroresValidacion('direccion')}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <div className="mpv-labal">Correo Electrónico:</div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <FormItem
                  label={null}
                  validateStatus={this.state.validators.correo_electronico.valid ? 'success' : 'error'}
                  help={this.state.errors}
                  required={true}
                >
                  <Input
                    onChange={(e) => this.handleChangeCorreoElectronico(e, 'correo_electronico')}
                    value={this.state.correo_electronico}
                    autoComplete="off"
                    id="correo_electronico"
                    placeholder="Ingrese el correo electrónico"
                  />
                  {this.mostrarErroresValidacion('correo_electronico')}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <div className="mpv-labal">Celular:</div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <FormItem
                  label={null}
                  validateStatus={this.state.validators.celular.valid ? 'success' : 'error'}
                  help={this.state.errors}
                  required={true}
                >
                  <Input
                    onChange={(e) => this.handleChangeCelular(e, 'celular')}
                    value={this.state.celular}
                    autoComplete="off"
                    id="celular"
                    placeholder="Ingrese el Celular"
                  />
                  {this.mostrarErroresValidacion('celular')}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}></Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <FormItem label={null} required={false}>
                  <Checkbox checked={this.state.es_colegiado} onChange={this.onChangeEsColegiado}>
                    Soy colegiado
                  </Checkbox>
                </FormItem>
              </Col>
            </Row>
            {this.state.es_colegiado && (
              <Row>
                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                  <div className="mpv-labal">Código de matrícula:</div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <FormItem
                    label={null}
                    validateStatus={this.state.validators.codigo_matricula.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <Input
                      onChange={(e) => this.handleChangeCodigoMatricula(e, 'codigo_matricula')}
                      value={this.state.codigo_matricula}
                      autoComplete="off"
                      id="codigo_matricula"
                      placeholder="Ingrese el código de matrícula"
                    />
                    {this.mostrarErroresValidacion('codigo_matricula')}
                  </FormItem>
                </Col>
              </Row>
            )}
          </Card>
          <br />
          <Card
            className="mpv-file"
            type="inner"
            title={
              <div>
                <LinkOutlined /> &nbsp; Archivo a Adjuntar
              </div>
            }
            extra={null}
          >
            <Row>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="mpv-labal-left">Archivo:</div>
                <div style={{ fontSize: 12, paddingBottom: 4 }}>2 MB por archivo Cantidad de archivos: 5</div>
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>
                    Seleccionar archivo
                    {uploading && <LoadingOutlined />}
                  </Button>
                </Upload>
                {this.mostrarErroresValidacion('files')}
              </Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <div className="mpv-labal-left">Descripción del archivo:</div>
                <FormItem
                  label={null}
                  validateStatus={this.state.validators.descripcion_archivo.valid ? 'success' : 'error'}
                  help={this.state.errors}
                  required={true}
                >
                  <Input
                    onChange={(e) => this.handleChangeDescripcionArchivo(e, 'descripcion_archivo')}
                    value={this.state.descripcion_archivo}
                    autoComplete="off"
                    id="descripcion_archivo"
                    placeholder="Ingrese la descripción de su archivo."
                  />
                  {this.mostrarErroresValidacion('descripcion_archivo')}
                </FormItem>
              </Col>
            </Row>
          </Card>
          <br />
          <div className="m-pl-16">
            <Button type="primary" onClick={this.handleSend} htmlType="submit" loading={this.state.guardando} size="large">
              Enviar
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={this.handleLimpiar} size="large">
              Limpiar
            </Button>
          </div>
        </Card>
        <br />
      </div>
    );
  }
}

export default MpvFormulario;
