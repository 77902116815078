import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { message, Tabs, Row, Col, Tag } from 'antd';
import EstadoCuentaService from '../../../services/api/estado_cuentas';
import { CloseOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

class ShowEstadoCuenta extends Component {
  state = {
    id: null,
    cargando: false,
    estado_cuenta: {},
    comprobante: {},
    destinatarios: [],
    cc: [],
    usuarios: [],
    active_tab: '1',
    cargando_documentos: false,
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.cargarEstadoCuenta(this.props.match.params.id);
    }
  }

  cargarEstadoCuenta = (id) => {
    this.setState({
      cargando: true,
      id,
    });

    EstadoCuentaService.getEstadoCuentaTemp(
      id,
      (estado_cuenta) => {
        if (estado_cuenta) {
          this.setState({
            cargando: false,
            estado_cuenta,
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  render() {
    const { estado_cuenta } = this.state;
    return (
      <div>
        <h2 className="page-header">{estado_cuenta.razon_social ? estado_cuenta.razon_social : 'Detalle Estado de Colegiado'}</h2>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <div
                  style={{
                    position: 'absolute',
                    right: '48px',
                    fontSize: '20px',
                    zIndex: 10,
                  }}
                >
                  <Link to={`/estado-cuentas`}>
                    <CloseOutlined />
                  </Link>
                </div>
                <Tabs defaultActiveKey={this.state.active_tab}>
                  <TabPane tab="Información General" key="1">
                    <Row>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div>
                          <b>Estado Afiliado: </b>
                          <Tag
                            color={
                              estado_cuenta.estado === 'BAJA'
                                ? 'red'
                                : estado_cuenta.estado === 'HABIL'
                                ? 'green'
                                : estado_cuenta.estado === 'INHABILITADO POR DEUDA'
                                ? 'blue'
                                : 'Sin Estado'
                            }
                          >
                            {estado_cuenta.estado}
                          </Tag>
                        </div>
                        <div>
                          <b>Tipo Documento: </b> {estado_cuenta.numero_documento && estado_cuenta.numero_documento.length === 8 ? 'DNI': 'RUC'}
                        </div>
                        <div>
                          <b>Nombres: </b> {estado_cuenta.razon_social}
                        </div>
                        <div>
                          <b>Dirección: </b> {estado_cuenta.departamento} {estado_cuenta.provincia} {estado_cuenta.distrito}
                        </div>
                        <div>
                          <b>Fecha Nacimiento: </b> {estado_cuenta.fecha_nacimiento}
                        </div>
                        <div>
                          <b>Fecha Colegiatura: </b> {estado_cuenta.fecha_colegiatura}
                        </div>
                        <div>
                          <b>Ultimo Pago: </b> {estado_cuenta.fecha_ultimo_pago}
                        </div>
                        <div>
                          <b>Ultimo Vencimiento: </b> {estado_cuenta.fecha_ultimo_vencimiento}
                        </div>
                        <div>
                          <b>Número Poliza: </b> {estado_cuenta.numero_poliza}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div>
                          <b>Código Afiliado: </b> {estado_cuenta.codigo}
                        </div>
                        <div>
                          <b>N° Documento: </b> {estado_cuenta.numero_documento}
                        </div>
                        <div>
                          <b>Correo Electrónico: </b> {estado_cuenta.email}
                        </div>
                        <div>
                          <b>Télefono: </b> {estado_cuenta.telefono}
                        </div>
                        <div>
                          <b>Departamento : </b> {estado_cuenta.departamento}
                        </div>
                        <div>
                          <b>Provincia: </b> {estado_cuenta.provincia}
                        </div>
                        <div>
                          <b>Distrito: </b> {estado_cuenta.distrito}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                      <div>
                          <b>Cuota Ordinaria Deuda: </b> {estado_cuenta.co_deuda}
                        </div>
                        <div>
                          <b>Cuota Ordinaria Pago: </b> {estado_cuenta.co_pago}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div>
                          <b>Fondo Mutual Deuda: </b> {estado_cuenta.fm_deuda}
                        </div>
                        <div>
                          <b>Fondo Mutual Pago: </b> {estado_cuenta.fm_pago}
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
    configuracion: state && state.configuracion ? state.configuracion : {},
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowEstadoCuenta);
