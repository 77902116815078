import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Button, message, Pagination, Alert } from 'antd';
import ReciboPagoService from '../../../services/api/recibo_pagos';
import helper from '../../../utils/helper';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import apiConfig from '../../../services/api/config';

class IndexReciboPagos extends Component {
  state = {
    cargando: false,
    recibo_pagos: [],
    page: {
      number: 1,
      per_page: 15,
      order_column: 'created_at',
      order_type: 'desc',
      from: 0,
      to: 0,
      last_page: 0,
      total: 0,
      buscar_texto: '',
    },
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleChangeLocation);

    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        const configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage,
        });
      }
    }

    this.cargarMesaPates();
  }

  handleChangeSearch = (value) => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = value.target.value;
    this.setState({
      page: configPage,
      buscar_texto: value.target.value
    }, () => {
      this.cargarMesaPates();
    });
  }

  handleChangeSearchIcon = () => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = this.state.buscar_texto;
    this.setState({
      page: configPage,
      buscar_texto: this.state.buscar_texto
    }, () => {
      this.cargarMesaPates();
    });
  }
  

  cargarMesaPates = (path = undefined) => {
    this.setState({
      cargando: true,
    });

    if (!path || path === undefined) {
      path = `?page=${this.state.page.number}&per_page=${this.state.page.per_page}&order_column=${this.state.page.order_column}&order_type=${this.state.page.order_type}&historial_cuenta=true`;
      if (this.state.page && this.state.page.buscar_texto && this.state.page.buscar_texto !== '') {
        path = `${path}&buscar_texto=${this.state.page.buscar_texto}`;
      }
    }

    ReciboPagoService.getReciboPagos(
      path,
      (page) => {
        if (page && page.data && page.data.length > 0) {
          const configPage = Object.assign({}, this.state.page);
          configPage.from = page.from;
          configPage.to = page.to;
          configPage.last_page = page.last_page;
          configPage.total = page.total;
          configPage.number = page.current_page;
          configPage.per_page = parseInt(page.per_page);
          configPage.buscar_texto = page.buscar_texto ? page.buscar_texto : '';

          this.setState({
            cargando: false,
            recibo_pagos: page.data,
            page: configPage,
          });
        } else {
          this.setState({
            recibo_pagos: [],
            cargando: false,
          });
        }
      },
      (error, result) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleChangeLocation = (location, action) => {
    const queryString = new URLSearchParams(location.search);
    const buscar_texto = queryString.get('buscar_texto');

    if (buscar_texto && buscar_texto != null) {
      const page = Object.assign({}, this.state.page);

      page.buscar_texto = buscar_texto;

      this.setState({
        page,
      });

      setTimeout(() => {
        this.cargarMesaPates();
      }, 10);
    }
  };

  handleChangePerPage = (current, pageSize) => {
    const page = Object.assign({}, this.state.page);
    page.number = current;
    page.per_page = pageSize;
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&historial_cuenta=true`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    this.props.history.push(path);
    this.cargarMesaPates(path);
  };

  showTotal = (total) => {
    return `Total ${total} registros`;
  };

  handleClickExportarExcel = () => {
    const page = Object.assign({}, this.state.page);
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&historial_cuenta=true`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    const url_exportar = `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/reporte/recibos/xls${path}&authorization=${token}`;
    window.open(url_exportar);
  };

  handleClickExportarPDF = () => {
    const page = Object.assign({}, this.state.page);
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&historial_cuenta=true`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    const url_exportar = `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/reporte/recibos/pdf${path}&authorization=${token}`;
    window.open(url_exportar);
  };

  render() {
    const columns = [
      {
        title: 'N° Documento',
        dataIndex: 'serie_numero',
        key: 'serie_numero',
        responsive: ['xl'],
        width: 50,
      },
      {
        title: 'RUC/DNI',
        dataIndex: 'cliente_numero_documento',
        key: 'cliente_numero_documento',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 120,
      },
      {
        title: 'Nombre del colegiado',
        dataIndex: 'cliente_formato',
        key: 'cliente_formato',
        responsive: ['lg', 'xl'],
        render: (text, record) => {
          return (
            <div>{text || `${record.nombre_1} ${record.nombre_2} ${record.apellido_paterno} ${record.apellido_materno}`}</div>
          );
        },
      },
      {
        title: 'Fecha Documento',
        dataIndex: 'fecha_emision',
        key: 'fecha_emision',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Cajero',
        dataIndex: 'vendedor_formato',
        key: 'vendedor_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Tipo Recibo',
        dataIndex: 'tipo_recibo',
        key: 'tipo_recibo',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Total',
        dataIndex: 'monto',
        key: 'monto',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 150,
        render: (text) => <div>S/ {text}</div>,
      },
      {
        title: 'Detalle',
        dataIndex: 'estado',
        key: 'estado',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        width: 80,
        render: (text, record) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {helper.tienePermiso(this.props.permisos, 'Ver detalle gestion colegiatura') ? (
                <Link to={`/recibo-pagos/${record.id}/show`}>
                  <EyeOutlined style={{ color: '#1890ff' }} />
                </Link>
              ) : (
                <EyeOutlined style={{ color: '#b5b5b5' }} />
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <div className="page-header">
          <h2>Lista de Recibos de Pago</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6 }}>
            <div className="topnav__search">
              <input
                type="text"
                placeholder="Buscar..."
                onChange={this.handleChangeSearch}
                value={ this.state.buscar_texto }
              />
              <i onClick={() => this.handleChangeSearchIcon()} className="bx bx-search" style={{cursor: 'pointer'}}></i>
            </div>
            <div>
              <Button onClick={this.handleClickExportarPDF} type="primary" shape="round" icon={<DownloadOutlined />} size={null}>
                PDF
              </Button>
            </div>
            <div>
              <Button onClick={this.handleClickExportarExcel} type="primary" shape="round" icon={<DownloadOutlined />} size={null}>
                Excel
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {!helper.tienePermiso(this.props.permisos, 'Ver registro gestion colegiaturas') ? (
                <Alert
                  message="Permiso"
                  description="No tiene permiso para ver lista de registro gestión colegiatura"
                  type="error"
                />
              ):
                <div className="card__body">
                  <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={this.state.recibo_pagos}
                    pagination={false}
                    loading={this.state.cargando}
                    size="small"
                  />
                  <div style={{ paddingTop: 12, textAlign: 'right' }}>
                    <Pagination
                      size="small"
                      showTotal={this.showTotal}
                      total={this.state.page.total}
                      current={this.state.page.number}
                      pageSize={this.state.page.per_page}
                      onChange={this.handleChangePerPage}
                      onShowSizeChange={this.handleChangePerPage}
                      pageSizeOptions={[10, 20, 30, 40]}
                      showQuickJumper
                      showSizeChanger
                    />
                  </div>
                </div>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexReciboPagos);
