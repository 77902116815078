import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { message, Tabs, Row, Col, Tag, Timeline, Table, Spin, Form, Input, Button } from 'antd';
import EstadoCuentaService from '../../../services/api/estado_cuentas';
import UserService from '../../../services/api/users';
import HistorialCuentaEmailFormulario from '../../../components/Email/HistorialCuentaEmailFormulario';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import apiConfig from '../../../services/api/config';
const { TabPane } = Tabs;
const FormItem = Form.Item;
class ShowHistorialCuentas extends Component {
  state = {
    id: null,
    cargando: false,
    colegiado: {},
    comprobante: {},
    destinatarios: [],
    cc: [],
    usuarios: [],
    active_tab: '1',
    cargando_documentos: false,
    ordenes_venta_pendientes: [],
    ordenes_venta_pagados: [],
    MESES: [
      '',
      'ENERO',
      'FEBRERO',
      'MARZO',
      'ABRIL',
      'MAYO',
      'JUNIO',
      'JULIO',
      'AGOSTO',
      'SETIEMBRE',
      'OCTUBRE',
      'NOVIEMBRE',
      'DICIEMBRE',
    ],
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.cargarColegiado(this.props.match.params.id);
    }
    this.cargarUsuarios();
    if (this.props.location) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const active_tab = queryString.get('active_tab');
        if (active_tab) {
          this.setState({
            active_tab,
          });
        }
      }
    }
  }

  // UNSAFE_componentWillMount() {
  //   if (
  //     this.props.match &&
  //     this.props.match.params &&
  //     this.props.match.params.id
  //   ) {
  //     this.cargarColegiado(this.props.match.params.id);
  //   }
  // }

  cargarUsuarios = () => {
    this.setState({ cargando_usuarios: true });
    UserService.getUsers(
      '',
      (response) => {
        if (response && response.data.length > 0) {
          this.setState({ usuarios: response.data });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
      }
    );
  };

  cargarColegiado = (id) => {
    this.setState({
      cargando: true,
      id,
    });

    EstadoCuentaService.getEstadoCuenta(
      id,
      (colegiado) => {
        if (colegiado) {
          let subject = '';
          let body = '';
          let template = '';
          let destinatario_principal = [];
          const copias = [];
          const subject_string =
            colegiado.razon_social ||
            `${colegiado.nombre_1} ${colegiado.nombre_2} ${colegiado.apellido_paterno} ${colegiado.apellido_materno}`;

          subject = `Historial de cuentas de ${subject_string}`;

          body = `Hola,<br/>
          Adjuntamos el pdf de su historial de cuentas<br/><br/>`;

          const templateHeader = ``;
          const templateBody = `${body}`;
          const templateFooter = ``;

          template = `${templateHeader} ${templateBody} ${templateFooter}`;

          destinatario_principal = [...this.state.destinatarios, colegiado.correo_electronico];

          const ordenes_venta_pendientes = [];
          const ordenes_venta_pagados = [];
          if (colegiado.pagos_pendientes && colegiado.pagos_pendientes.length > 0) {
            colegiado.pagos_pendientes.map((pago) => {
              if (pago.importe_deuda > 0) {
                ordenes_venta_pendientes.push(pago);
              } else {
                ordenes_venta_pagados.push(pago);
              }
              return pago;
            });
          }
          this.setState({
            cargando: false,
            colegiado,
            destinatarios: destinatario_principal,
            cc: copias,
            subject,
            template,
            ordenes_venta_pendientes,
            ordenes_venta_pagados,
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleFormSubmit = (email) => {
    email.pagos_pendientes = this.state.ordenes_venta_pendientes;
    email.id = this.state.colegiado.id;
    email.numero_documento = this.state.colegiado.numero_documento;
    email.codigo_afiliado = this.state.colegiado.codigo_afiliado;
    email.razon_social =
      this.state.colegiado.razon_social ||
      `${this.state.colegiado.nombre_1} ${this.state.colegiado.nombre_2} ${this.state.colegiado.apellido_paterno} ${this.state.colegiado.apellido_materno}`;
    this.setState({ guardando: true });
    EstadoCuentaService.sendEmail(
      this.state.id,
      email,
      (response) => {
        if (response) {
          message.success('Se envió el correo con éxito');
          this.setState({
            guardando: false,
            active_tab: '1',
            destinatarios: [],
          });
          this.cargarColegiado(this.state.id);
        }
      },
      (err) => {
        if (err) {
          this.setState({ guardando: false });
        }
      }
    );
  };

  onTabClick = (key) => {
    this.setState({ active_tab: key });
  };

  handleClickExportarExcel = () => {
    const page = Object.assign({}, this.state.page);
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&historial_cuenta=true`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    const url_exportar = `${apiConfig.constants.URL_NEGOCIOS}/api/${apiConfig.constants.API_VERSION}/store/historial/${this.state.colegiado.id}/xls${path}&authorization=${token}`;
    window.open(url_exportar);
  };

  handleClickExportarPDF = () => {
    const page = Object.assign({}, this.state.page);
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&historial_cuenta=true`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    const url_exportar = `${apiConfig.constants.URL_NEGOCIOS}/api/${apiConfig.constants.API_VERSION}/store/historial/${this.state.colegiado.id}/pdf${path}&authorization=${token}`;
    window.open(url_exportar);
  };

  render() {
    const { colegiado } = this.state;
    const columns = [
      {
        title: 'Tipo Servicio',
        dataIndex: 'tipo_servicio',
        key: 'tipo_servicio',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <span>{record.tipo_servicio_formato}</span>
            </div>
          );
        },
      },
      {
        title: 'Orden de Venta',
        dataIndex: 'serie_formato',
        key: 'serie_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      // {
      //   title: 'Cuotas Pendientes',
      //   dataIndex: 'pagados',
      //   key: 'pagados',
      //   responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      //   render: (text, record) => {
      //     return (
      //       <div>
      //         <span>
      //           {record.pendientes}/{record.pagados + record.pendientes}
      //         </span>
      //       </div>
      //     );
      //   },
      // },
      {
        title: 'Fecha Emisión',
        dataIndex: 'fecha_emision',
        key: 'fecha_emision',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Comprobante',
        dataIndex: 'comprobante_serie_formato',
        key: 'comprobante_serie_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Fecha Emisión',
        dataIndex: 'comprobante_fecha_emision',
        key: 'comprobante_fecha_emision',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Importe',
        dataIndex: 'comprobante_total',
        key: 'comprobante_total',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <span>{Number.parseFloat(record.comprobante_total).toFixed(2)}</span>
            </div>
          );
        },
      },
      {
        title: 'Estado',
        dataIndex: 'importe_deuda',
        key: 'importe_deuda',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <Tag color={record.importe_deuda > 0 ? 'blue' : 'green'}>{record.importe_deuda > 0 ? 'DEBE' : 'PAGADO'}</Tag>
            </div>
          );
        },
      },
    ];

    const columns_pendiente = [
      {
        title: 'Tipo Servicio',
        dataIndex: 'tipo_servicio',
        key: 'tipo_servicio',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <span>{record.tipo_servicio_formato}</span>
            </div>
          );
        },
      },
      {
        title: 'Orden de Venta',
        dataIndex: 'serie_formato',
        key: 'serie_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Cuotas Pendientes',
        dataIndex: 'pagados',
        key: 'pagados',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <span>
                {record.pendientes}/{record.pagados + record.pendientes}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Fecha Emisión',
        dataIndex: 'fecha_emision',
        key: 'fecha_emision',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Comprobante',
        dataIndex: 'comprobante_serie_formato',
        key: 'comprobante_serie_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Fecha Vencimiento',
        dataIndex: 'fecha_vencimiento',
        key: 'fecha_vencimiento',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Importe',
        dataIndex: 'importe_deuda',
        key: 'importe_deuda',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <span>{Number.parseFloat(record.importe_deuda).toFixed(2)}</span>
            </div>
          );
        },
      },
      {
        title: 'Estado',
        dataIndex: 'importe_deuda',
        key: 'importe_deuda',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <Tag color={record.importe_deuda > 0 ? 'blue' : 'green'}>{record.importe_deuda > 0 ? 'DEBE' : 'PAGADO'}</Tag>
            </div>
          );
        },
      },
    ];

    const expandedRowRender = (record_data) => {
      const cuota_mes = record_data.detalle.length ? 12 - record_data.detalle.length : 0;
      const columns = [
        {
          title: 'Tipo Servicio',
          dataIndex: 'tipo_servicio',
          key: 'tipo_servicio',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
          render: (text, record) => {
            return (
              <div>
                <span>{record_data.tipo_servicio_formato}</span>
              </div>
            );
          },
        },
        {
          title: 'Orden de Venta',
          dataIndex: 'serie_formato',
          key: 'serie_formato',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
          render: (text, record) => {
            return (
              <div>
                <span>{record_data.serie_formato}</span>
              </div>
            );
          },
        },
        {
          title: 'Cuotas Pendientes',
          dataIndex: 'pagados',
          key: 'pagados',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
          render: (text, record) => {
            return (
              <div>
                <span>{this.state.MESES[cuota_mes + record.numero_cuota]}</span>
              </div>
            );
          },
        },
        {
          title: 'Fecha Emisión',
          dataIndex: 'fecha_emision',
          key: 'fecha_emision',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'Fecha Vencimiento',
          dataIndex: 'fecha_vencimiento',
          key: 'fecha_vencimiento',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        },
        {
          title: 'Importe que adeuda ',
          dataIndex: 'importe',
          key: 'importe',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
          render: (text, record) => {
            return (
              <div>
                <span>{Number.parseFloat(record.importe).toFixed(2)}</span>
              </div>
            );
          },
        },
        {
          title: 'Estado',
          dataIndex: 'es_pagado',
          key: 'es_pagado',
          responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
          render: (text, record) => {
            return (
              <div>
                <Tag color={!record.es_pagado ? 'blue' : 'green'}>{!record.es_pagado ? 'DEBE' : 'PAGADO'}</Tag>
              </div>
            );
          },
        },
      ];
      if (record_data.tipo_servicio_formato === 'Cuota Ordinaria') {
        return (
          <>
            <Table columns={columns} size="middle" dataSource={record_data.detalle} pagination={false} />
          </>
        );
      }
    };

    return (
      <div>
        <div className="page-header">
          <h2 className="page-header">{colegiado.nombres ? colegiado.nombres : 'Detalle Historial de Cuenta'}</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6 }}>
              <div>
              <Button onClick={this.handleClickExportarPDF} type="primary" shape="round" icon={<DownloadOutlined />} size={null}>
                PDF
              </Button>
            </div>
            <div>
              <Button onClick={this.handleClickExportarExcel} type="primary" shape="round" icon={<DownloadOutlined />} size={null}>
                Excel
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <div
                  style={{
                    position: 'absolute',
                    right: '48px',
                    fontSize: '20px',
                    zIndex: 10,
                  }}
                >
                  <Link to={`/historial-cuentas`}>
                    <CloseOutlined />
                  </Link>
                </div>
                <Tabs activeKey={this.state.active_tab} onTabClick={this.onTabClick}>
                  <TabPane tab="Información General" key="1">
                    <Row>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div>
                          <b>Estado: </b>
                          <Tag
                            color={
                              colegiado.estado_afiliado_formato === 'HABIL'
                                ? 'green'
                                : colegiado.estado_afiliado_formato === 'VITALICIO'
                                ? 'red'
                                : colegiado.estado_afiliado_formato === 'INHABILITADO POR DEUDA'
                                ? 'blue'
                                : ''
                            }
                          >
                            {colegiado.estado_afiliado_formato}
                          </Tag>
                        </div>
                        <div>
                          <b>RUC/DNI: </b> {colegiado.numero_documento}
                        </div>
                        <div>
                          <b>Nombres: </b>{' '}
                          {colegiado.razon_social ||
                            `${colegiado.nombre_1} ${colegiado.nombre_2} ${colegiado.apellido_paterno} ${colegiado.apellido_materno}`}
                        </div>
                        <div>
                          <b>Código Afiliado : </b> {colegiado.codigo_afiliado}
                        </div>
                        <div>
                          <b>Fecha Colegiatura: </b> {colegiado.fecha_colegiatura}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28"></Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                        <Timeline mode="left">
                          {colegiado.historiales_fondo_mutual &&
                            colegiado.historiales_fondo_mutual.length > 0 &&
                            colegiado.historiales_fondo_mutual.map((item, index) => {
                              return (
                                <Timeline.Item
                                  key={index}
                                  label={item.fecha_formato}
                                  color={
                                    item.tipo_operacion === 'Afiliado'
                                      ? 'green'
                                      : item.tipo_operacion === 'No Afiliado'
                                      ? 'blue'
                                      : item.tipo_operacion === 'Renuncia'
                                      ? 'red'
                                      : ''
                                  }
                                >
                                  <Tag
                                    color={
                                      item.tipo_operacion === 'Afiliado'
                                        ? 'green'
                                        : item.tipo_operacion === 'No Afiliado'
                                        ? 'blue'
                                        : item.tipo_operacion === 'Renuncia'
                                        ? 'red'
                                        : ''
                                    }
                                  >
                                    {item.tipo_operacion}
                                  </Tag>
                                  <div>{item.comentario}</div>
                                </Timeline.Item>
                              );
                            })}
                        </Timeline>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Ordenes venta pendientes" key="2">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FormItem label="Monto Adelanto" required={false}>
                          <Input value={colegiado.adelanto} style={{ width: '15%' }} disabled={true} />
                        </FormItem>
                        <Table
                          rowKey={(record) => record.id}
                          columns={columns_pendiente}
                          dataSource={this.state.ordenes_venta_pendientes}
                          pagination={false}
                          loading={this.state.cargando}
                          size="small"
                          expandedRowRender={expandedRowRender}
                          locale={{
                            emptyText: 'No se ha encontrado registros',
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Ordenes venta pagados" key="3">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                          rowKey={(record) => record.id}
                          columns={columns}
                          dataSource={this.state.ordenes_venta_pagados}
                          pagination={false}
                          loading={this.state.cargando}
                          size="small"
                          locale={{
                            emptyText: 'No se ha encontrado registros',
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Enviar correo ordenes venta pendientes" key="4">
                    <Row>
                      <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <br />
                        <div>
                          {!this.state.cargando ? (
                            <HistorialCuentaEmailFormulario
                              from={this.props.user.email}
                              destinatarios={this.state.destinatarios}
                              cc={this.state.cc}
                              subject={this.state.subject}
                              template={this.state.template}
                              serie_formato={this.state.comprobante.serie_formato}
                              onFormSubmit={this.handleFormSubmit}
                              onFormClose={this.handleClickCloseEmail}
                              guardando={this.state.guardando}
                              permisos={this.props.permisos}
                              usuarios={this.state.usuarios}
                              colegiado={colegiado}
                            />
                          ) : (
                            <Spin tip="Cargando...">
                              <HistorialCuentaEmailFormulario />
                            </Spin>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
    configuracion: state && state.configuracion ? state.configuracion : {},
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowHistorialCuentas);
