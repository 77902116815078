import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import './sidebar.css';

import logo from '../../assets/images/logo.png';

import sidebar_items from '../../assets/JsonData/sidebar_routes.json';

const SidebarItem = (props) => {
  const active = props.active ? 'active' : '';

  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>

        {props.es_padre && <i className="bx bx-chevron-down"></i>}
      </div>
    </div>
  );
};

const Sidebar = (props) => {
  const [es_colap, setEsColap] = useState(false);
  const pathname_array = props.location.pathname.split('/');
  const activeItem = sidebar_items.findIndex((item) => item.route === `/${pathname_array[1]}`);
  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="company logo" />
      </div>
      {sidebar_items.map((item, index) => (
        <>
          {item.submenu ? (
            <>
              <Link to={item.route} key={index} onClick={() => setEsColap(!es_colap)}>
                <SidebarItem title={item.display_name} icon={item.icon} active={index === activeItem} es_padre={true} />
              </Link>
              <div id="submenu" style={{ display: `${es_colap ? 'block' : 'none'}` }}>
                {item.submenu.map((item_, index_) => (
                  <div key={index_} style={{ paddingTop: 4, paddingLeft: 20 }}>
                    <Link to={item_.route} key={index_}>
                      <SidebarItem
                        title={item_.display_name}
                        icon={item_.icon}
                        active={item_.route === `/${pathname_array[1]}/${pathname_array[2]}`}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <Link to={item.route} key={index}>
              <SidebarItem title={item.display_name} icon={item.icon} active={index === activeItem} />
            </Link>
          )}
        </>
      ))}
    </div>
  );
};

export default Sidebar;
