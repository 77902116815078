import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Row, Col, message } from 'antd';

import LoginService from '../../../services/api/login';
// import UserService from '../../../services/api/users';
// import RolService from '../../../services/api/roles';
import { cargarUsuario, cargarPermisos } from '../../../data/user/actions';
import Config from '../../../services/api/config';
import Helpers from '../../../utils/helper';
import Logo from '../../../assets/images/logo.png';

import './styles.css';

class Login extends Component {
  state = {
    email: '',
    password: '',
    entrando: false,
    mi_ip: '',
    validators: {
      email: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[^@]+@[^]+\..+/,
            message: 'El campo acepta solo correo electrónico.',
          },
          {
            test: (value) => value.trim().length <= 100,
            message: 'El campo acepta 100 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      password: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  UNSAFE_componentWillMount () {
    this.cargarIpPublica();
  }

  cargarIpPublica = () => {
    LoginService.getIpPublica(
      (response) => {
        this.setState({
          mi_ip: response
        })
      },
      (err) => {
        if (err) {
          message.error(err.message);
        }
      }
    );
  };

  handleChangeCorreoElectronico = (e, fieldName) => {
    this.setState({
      email: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangePassword = (e, fieldName) => {
    this.setState({
      password: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleClickLogin = () => {
    const data = {
      email: this.state.email,
      password: this.state.password,
      mi_ip: this.state.mi_ip
    };
    const valid = this.isFormValid();
    if (valid) {
      this.setState({ entrando: true });
      LoginService.login(
        data,
        (success) => {
          if (success) {
            this.setAuth(success);
            this.setState({
              entrando: false,
              mensaje: '',
              tipo_mensaje: '',
            });
            this.setUser(success);
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              entrando: false,
              mensaje: error.message,
              tipo_mensaje: 'danger',
            });
          }
        }
      );
    } else {
      message.error('verifique que no haya campos vacíos.');
    }
  };

  setUser = (auth) => {
    localStorage.setItem(Config.constants.USUARIO_ID, auth.usuario.id);
    this.props.cargarUsuario(auth.usuario);
    this.props.history.push('/');
  };

  setAuth = (auth) => {
    const expires_at = new Date().getTime() / 1000 + auth.expires_in;
    localStorage.setItem(Config.constants.ACCESS_TOKEN_KEY, auth.access_token);
    localStorage.setItem(Config.constants.REFRESH_TOKEN_KEY, auth.refresh_token);
    localStorage.setItem(Config.constants.ACCESS_TOKEN_EXPIRES_IN, auth.expires_in);
    localStorage.setItem(Config.constants.ACCESS_TOKEN_EXPIRES_AT, expires_at);
    localStorage.setItem('ip', this.state.mi_ip);
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  render() {
    return (
      <div className="login-full">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="login-center">
              <div className="login">
                <div>
                  <img style={{ margin: 'auto', display: 'block', width: 300 }} src={Logo} alt="Login" />
                </div>
                <div>
                  <h2>Ingresar (Usuario y Contraseña)</h2>
                </div>
                <Form className="login__form">
                  <Form.Item validateStatus={this.state.validators.email.valid ? 'success' : 'error'} help={this.state.errors}>
                    <Input
                      value={this.state.email}
                      onChange={(e) => this.handleChangeCorreoElectronico(e, 'email')}
                      prefix={<i className="fal fa-envelope" style={{ color: 'rgba(0,0,0,.25)' }}></i>}
                      placeholder="Correo electrónico"
                      size="large"
                    />
                    {this.mostrarErroresValidacion('email')}
                  </Form.Item>
                  <Form.Item validateStatus={this.state.validators.password.valid ? 'success' : 'error'} help={this.state.errors}>
                    <Input
                      value={this.state.password}
                      onChange={(e) => this.handleChangePassword(e, 'password')}
                      prefix={<i className="fal fa-lock-alt" style={{ color: 'rgba(0,0,0,.25)' }}></i>}
                      type="password"
                      placeholder="Contraseña"
                      size="large"
                    />
                    {this.mostrarErroresValidacion('password')}
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      loading={this.state.entrando}
                      type="primary"
                      onClick={this.handleClickLogin}
                      size="large"
                      block
                    >
                      Iniciar sesión
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
        {this.state.mostrar_preload ? <div>Por favor espere un momento mientras trabajamos.</div> : ''}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    cargarUsuario: (usuario_id) => dispatch(cargarUsuario(usuario_id)),
    cargarPermisos: (permisos) => dispatch(cargarPermisos(permisos)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
