import React, { Component } from 'react';
import { Form, Input, Button, message, Checkbox } from 'antd';
import PermisoService from '../../services/api/permissions';
import Helpers from '../../utils/helper';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 8 },
  },
};

class RolFormulario extends Component {
  state = {
    id: this.props.rol && this.props.rol.id ? this.props.rol.id : '',
    name: this.props.rol && this.props.rol.name ? this.props.rol.name : '',
    permisos: [],
    validators: {
      name: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => value.trim().length <= 255,
            message: 'El campo acepta 255 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  componentDidMount() {
    this.cargarPermisos();
  }

  cargarPermisos = () => {
    PermisoService.getPermisos(
      '',
      (permisos) => {
        if (permisos) {
          if (this.props.rol && this.props.rol.id) {
            permisos.map((item) => {
              this.props.rol.permisos.map((item_) => {
                if (item.name === item_.name) {
                  item.es_checked = true;
                }
                return item_;
              });
              return item;
            });
          }
          this.setState({ permisos });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
        }
      }
    );
  };

  handleChangeName = (e, fieldName) => {
    this.setState({
      name: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleSave = (data) => {
    const valid = this.isFormValid();
    if (valid) {
      const permissions = [];
      this.state.permisos.map((item) => {
        if (item.es_checked) {
          permissions.push(item.id);
        }
        return item;
      });

      this.props.onFormSubmit({
        id: this.state.id,
        name: this.state.name || '',
        permissions,
      });
      this.nameInput.focus();
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleCancel = () => {
    this.props.onFormCancel();
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  handleChangeEsChecked = (e, index) => {
    const permisos = this.state.permisos.slice();
    permisos[index].es_checked = e.target.checked;
    this.setState({
      permisos,
    });
  };

  render() {
    return (
      <Form>
        <FormItem
          {...formItemLayout}
          label="Nombre"
          validateStatus={this.state.validators.name.valid ? 'success' : 'error'}
          help={this.state.errors}
          required={true}
        >
          <Input
            onChange={(e) => this.handleChangeName(e, 'name')}
            value={this.state.name}
            autoComplete="off"
            id="name"
            ref={(input) => {
              this.nameInput = input;
            }}
          />
          {this.mostrarErroresValidacion('name')}
        </FormItem>
        <FormItem {...formItemLayout} label="Lista de permisos" help={this.state.errors} required={true}>
          {this.state.permisos &&
            this.state.permisos.length > 0 &&
            this.state.permisos.map((permiso, index) => {
              return (
                <div key={index}>
                  <Checkbox
                    checked={permiso.es_checked ? permiso.es_checked : false}
                    onChange={(e) => this.handleChangeEsChecked(e, index)}
                  >
                    {permiso.name}
                  </Checkbox>
                </div>
              );
            })}
        </FormItem>
        <FormItem {...formItemLayoutWithOutLabel} label="">
          <Button type="primary" onClick={this.handleSave} htmlType="submit" loading={this.props.guardando}>
            Guardar
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={this.handleCancel}>
            Cancelar
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default RolFormulario;
