import React, { Component } from 'react';
import CambioBeneficiarioFormulario from '../../../components/FondoMutual/CambioBeneficiarioFormulario';

class CambioBeneficiario extends Component {
  render() {
    return (
      <>
        <CambioBeneficiarioFormulario />
      </>
    );
  }
}

export default CambioBeneficiario;
