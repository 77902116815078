import React, { Component } from 'react';
import { Modal } from 'antd';

import RolFormulario from './RolFormulario';

class RolFormularioModal extends Component {
  handleClose = () => {
    this.props.onCancel();
  };

  handleFormSubmit = (data) => {
    this.props.onFormSubmit(data);
  };

  onFormUploadValidators = (error) => {
    if (this.formRef) {
      this.formRef.actualizarListaErrores(error);
    }
  };

  render() {
    return (
      <div>
        <Modal
          title={this.props.rol && this.props.rol.id ? 'Editar Rol' : 'Nueva Rol'}
          visible={this.props.visible}
          onCancel={this.handleClose}
          width={500}
          footer={null}
          maskClosable={false}
        >
          <RolFormulario
            ref={this.setFormRef}
            onFormSubmit={this.handleFormSubmit}
            onFormCancel={this.handleClose}
            rol={this.props.rol}
            guardando={this.props.guardando}
            errores={this.props.errores}
          />
        </Modal>
      </div>
    );
  }
}

export default RolFormularioModal;
