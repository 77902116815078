import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { message, Tabs, Row, Col, Tag, Timeline, Table, Spin, Popover, Tooltip, Button } from 'antd';
import ColegiadoService from '../../../services/api/colegiados';
import ArchivoService from '../../../services/api/archivos';
import UserService from '../../../services/api/users';
import EmailFormularioColegiado from '../../../components/Email/EmailFormularioColegiado';
import { LinkOutlined, CloudDownloadOutlined, CloseOutlined, DownloadOutlined, PayCircleOutlined } from '@ant-design/icons';
import apiConfig from '../../../services/api/config';

const { TabPane } = Tabs;

class ShowGestionColegiatura extends Component {
  state = {
    id: null,
    cargando: false,
    colegiado: {},
    comprobante: {},
    destinatarios: [],
    cc: [],
    usuarios: [],
    active_tab: '1',
    cargando_documentos: false,
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.cargarColegiado(this.props.match.params.id);
      this.cargarColegiadoDocumentos(this.props.match.params.id);
    }
    this.cargarUsuarios();
  }

  // UNSAFE_componentWillMount() {
  //   if (
  //     this.props.match &&
  //     this.props.match.params &&
  //     this.props.match.params.id
  //   ) {
  //     this.cargarColegiado(this.props.match.params.id);
  //   }
  // }

  cargarUsuarios = () => {
    this.setState({ cargando_usuarios: true });
    UserService.getUsers(
      '',
      (response) => {
        if (response && response.data.length > 0) {
          this.setState({ usuarios: response.data });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
      }
    );
  };

  cargarColegiadoDocumentos = (id) => {
    this.setState({ cargando_documentos: true });
    ColegiadoService.getColegiadoDocumentos(
      id,
      (documentos) => {
        if (documentos) {
          this.setState({ documentos, cargando_documento: true });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
        this.setState({ cargando_documentos: false });
      }
    );
  };

  cargarColegiado = (id) => {
    this.setState({
      cargando: true,
      id,
    });

    ColegiadoService.getColegiadoHC(
      id,
      (colegiado) => {
        if (colegiado) {
          let subject = '';
          let body = '';
          let template = '';
          let destinatario_principal = [];
          const copias = [];

          subject = `Habilitación de colegiatura`;

          body = `Hola,<br/>

        Tu ticket <b>${colegiado.ticket_formato}</b> está 
        en atención.<br/><br/>
        
        Responsable será el<br/>
        Ejecutivo responsable de tu atención<br/><br/>
      
        En las próximas 24 horas se estará<br/>
        comunicando contigo para atender<br/>
        tu trámite.Te pedimos estar atento.`;

          const templateHeader = ``;
          const templateBody = `${body}`;
          const templateFooter = ``;

          template = `${templateHeader} ${templateBody} ${templateFooter}`;

          destinatario_principal = [...this.state.destinatarios, colegiado.correo_electronico];

          this.setState({
            cargando: false,
            colegiado,
            destinatarios: destinatario_principal,
            cc: copias,
            subject,
            template,
          });
        }
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleFormSubmit = (email) => {
    // email.numero_ticket = this.state.colegiado && this.state.colegiado.ticket_formato ? this.state.colegiado.ticket_formato : null;
    // email.usuario = this.state.colegiado && this.state.colegiado.usuario_creacion_formato ? this.state.colegiado.usuario_creacion_formato : '-';
    this.setState({ guardando: true });
    ColegiadoService.sendEmail(
      this.state.id,
      email,
      (response) => {
        if (response) {
          message.success('Se envió el correo con éxito');
          this.setState({
            guardando: false,
            active_tab: '1',
            destinatarios: [],
          });
          this.cargarColegiado(this.state.id);
        }
      },
      (err) => {
        if (err) {
          this.setState({ guardando: false });
        }
      }
    );
  };

  handleClickExportarPDF = () => {
    const token = localStorage.getItem(apiConfig.constants.ACCESS_TOKEN_KEY) || null;
    const url_exportar = `${apiConfig.constants.URL_API}/api/${apiConfig.constants.API_VERSION}/gestion-colegiado/${this.state.id}/pdf?authorization=${token}`;
    window.open(url_exportar);
  };

  handlePagoEfectivo = () => {
    let url_producto = '';
    if (this.state.colegiado.tipo_colegiatura_codigo === '2') {
      url_producto = this.props.configuracion?.url_traslado;
    } else if (this.state.colegiado.tipo_colegiatura_codigo === '1') {
      url_producto = this.props.configuracion?.url_nuevo;
    }

    const href = `${url_producto}&colegiado_id=${this.state.colegiado?.id}`;

    window.open(href);
  }
  
  render() {
    const historiales = [];
    if (this.state.colegiado && this.state.colegiado.historiales && this.state.colegiado.historiales.length > 0) {
      this.state.colegiado.historiales.map((historial) => {
        if (historial.comentario !== null) {
          historiales.push(historial);
        }
        return historial;
      });
    }

    const { colegiado } = this.state;
    const columns = [
      {
        title: 'Fecha',
        dataIndex: 'fecha_descripcion',
        key: 'fecha_descripcion',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Estado',
        dataIndex: 'tipo_operacion',
        key: 'tipo_operacion',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text) => {
          return (
            <div>
              <Tag color={text === 'RECIBIDO' ? 'blue' : text === 'OBSERVADO' ? 'red' : text === 'ATENDIDO' ? 'green' : ''}>
                {text}
              </Tag>
            </div>
          );
        },
      },
      {
        title: 'Responsable',
        dataIndex: 'responsable',
        key: 'responsable',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Descripción',
        dataIndex: 'descripcion',
        key: 'descripcion',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text) => {
          return (
            <div>
              {text && (
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                    ></div>
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text.substring(0, 80) + ' <span style="color: blue">Ver más</span>',
                    }}
                  ></div>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        title: 'Email Descripción',
        dataIndex: 'comentario',
        key: 'comentario',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'Adjuntos',
        dataIndex: 'ticket_formato',
        key: 'ticket_formato',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text, record) => {
          return (
            <div>
              <Popover
                placement="bottomRight"
                content={
                  record.files &&
                  record.files.length > 0 &&
                  record.files.map((file, index) => {
                    return (
                      <div key={index}>
                        <p>
                          <CloudDownloadOutlined />{' '}
                          <a href={ArchivoService.getArchivoUrl(file.id)} target="_blank" rel="noreferrer">
                            {file.nombre_original}
                          </a>
                        </p>
                      </div>
                    );
                  })
                }
                title="Descargar Archivos"
              >
                <LinkOutlined />
              </Popover>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="page-header">
          <h2 className="page-header">{colegiado.nombres ? colegiado.nombres : 'Detalle Gestión De Solicitudes De Colegiatura'}</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6 }}>
          {
            colegiado.estado === 'RECIBIDO' &&
            <div>
              <Button onClick={this.handlePagoEfectivo} type="primary" shape="round" icon={<PayCircleOutlined />} size={null}>
                Pago Efectivo
              </Button>
            </div>
          }
           
            <div>
              <Button onClick={this.handleClickExportarPDF} type="primary" shape="round" icon={<DownloadOutlined />} size={null}>
                PDF
              </Button>
            </div>
          </div>
        </div>
       
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <div
                  style={{
                    position: 'absolute',
                    right: '48px',
                    fontSize: '20px',
                    zIndex: 10,
                  }}
                >
                  <Link to={`/gestion-colegiaturas`}>
                    <CloseOutlined />
                  </Link>
                </div>
                <Tabs defaultActiveKey={this.state.active_tab}>
                  <TabPane tab="Información General" key="1">
                    <Row>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div>
                          <b>Estado: </b>
                          <Tag
                            color={
                              colegiado.estado === 'RECIBIDO'
                                ? 'blue'
                                : colegiado.estado === 'OBSERVADO'
                                ? 'red'
                                : colegiado.estado === 'ATENDIDO'
                                ? 'green'
                                : ''
                            }
                          >
                            {colegiado.estado}
                          </Tag>
                        </div>
                        <div>
                          <b>Tipo Documento: </b> {colegiado.tipo_documento_formato}
                        </div>
                        <div>
                          <b>Nombres: </b> {colegiado.nombres}
                        </div>
                        <div>
                          <b>Responsable: </b> {colegiado.responsable}
                        </div>
                        <div>
                          <b>Nro de póliza: </b> {colegiado.numero_poliza}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7} className="line-height-28">
                        <div>
                          <b>Código de Afiliación: </b> {colegiado.codigo_afiliado}
                        </div>
                        <div>
                          <b>Correo: </b> {colegiado.correo_electronico}
                        </div>
                        <div>
                          <b>N° Documento: </b> {colegiado.numero_documento}
                        </div>
                        <div>
                          <b>Télefono: </b> {colegiado.telefono}
                        </div>
                        <div>
                          <b>Fecha: </b> {colegiado.created_at}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                        <Timeline mode="left">
                          {colegiado.historiales &&
                            colegiado.historiales.length > 0 &&
                            colegiado.historiales.map((item, index) => {
                              return (
                                <Timeline.Item
                                  key={index}
                                  label={item.fecha_formato}
                                  color={
                                    item.tipo_operacion === 'RECIBIDO'
                                      ? 'blue'
                                      : item.tipo_operacion === 'OBSERVADO'
                                      ? 'red'
                                      : item.tipo_operacion === 'ATENDIDO'
                                      ? 'green'
                                      : ''
                                  }
                                >
                                  <Tag
                                    color={
                                      item.tipo_operacion === 'RECIBIDO'
                                        ? 'blue'
                                        : item.tipo_operacion === 'OBSERVADO'
                                        ? 'red'
                                        : item.tipo_operacion === 'ATENDIDO'
                                        ? 'green'
                                        : ''
                                    }
                                  >
                                    {item.tipo_operacion}
                                  </Tag>
                                  <div>{item.comentario}</div>
                                </Timeline.Item>
                              );
                            })}
                        </Timeline>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b>DETALLE DE TRAMÍTE: </b>
                          </div>
                          <div>
                            <b>Tipo de Colegiatura: </b> {colegiado.tipo_colegiatura_formato}
                          </div>
                          <div>
                            <b>Grado de Instrucción: </b> {colegiado.grado_instruccion}
                          </div>
                          {colegiado.fecha_colegiatura_1 && (
                            <div>
                              <b>Fecha a Colegiarse: </b> {colegiado.fecha_colegiatura_1}
                            </div>
                          )}
                          {colegiado.fecha_colegiatura_2 && (
                            <div>
                              <b>Fecha a Colegiarse: </b> {colegiado.fecha_colegiatura_2}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b style={{ color: 'white' }}>.</b>
                          </div>
                          <div>
                            <b>Fecha de egreso: </b> {colegiado.fecha_egreso}
                          </div>
                          <div>
                            <b>Fecha de nacimiento: </b> {colegiado.fecha_nacimiento}
                          </div>
                          <div>
                            <b>Universidad: </b> {colegiado.universidad}
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b style={{ color: 'white' }}>.</b>
                          </div>
                          <div>
                            <b>Hobbies: </b> {colegiado.hobbies}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="line-height-28">
                          <br />
                          <div>
                            <b>ARCHIVOS ADJUNTOS: </b>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.documentos &&
                        this.state.documentos.length > 0 &&
                        this.state.documentos.map((item, index) => {
                          if (item.modulo === 'titulo' || item.modulo === 'fotografia' || item.modulo === 'dni' || item.modulo === 'documento') {
                            return (
                              <Col key={index} xs={24} sm={24} md={6} lg={6} xl={6}>
                                <div className="line-height-28">
                                  <div>
                                    <b>
                                      {item.modulo === 'titulo'
                                        ? 'Título'
                                        : item.modulo === 'fotografia'
                                        ? 'Fotografia'
                                        : item.modulo === 'dni'
                                        ? 'DNI'
                                        : 'Documento'}
                                      :{' '}
                                    </b>
                                  </div>
                                  <Tooltip title="Descargar">
                                    <div className="download__file">
                                      <a href={ArchivoService.getArchivoUrl(item.id)} target="_blank" rel="noreferrer">
                                        <CloudDownloadOutlined /> {item.nombre_original.substring(0, 20)}
                                      </a>
                                    </div>
                                  </Tooltip>
                                </div>
                              </Col>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Row>
                  </TabPane>
                  <TabPane tab="Estados" key="2">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table
                          rowKey={(record) => record.id}
                          columns={columns}
                          dataSource={historiales}
                          pagination={false}
                          loading={this.state.cargando}
                          size="small"
                          locale={{
                            emptyText: 'No se ha encontrado registros',
                          }}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <br />
                        <div>
                          {!this.state.cargando ? (
                            <EmailFormularioColegiado
                              from={this.props.user.email}
                              destinatarios={this.state.destinatarios}
                              cc={this.state.cc}
                              subject={this.state.subject}
                              template={this.state.template}
                              serie_formato={this.state.comprobante.serie_formato}
                              onFormSubmit={this.handleFormSubmit}
                              onFormClose={this.handleClickCloseEmail}
                              guardando={this.state.guardando}
                              permisos={this.props.permisos}
                              usuarios={this.state.usuarios}
                              historiales={colegiado.historiales}
                              colegiado={colegiado}
                              configuracion={this.props.configuracion}
                            />
                          ) : (
                            <Spin tip="Cargando...">
                              <EmailFormularioColegiado />
                            </Spin>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user ? state.user : {},
    configuracion: state && state.configuracion ? state.configuracion : {},
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowGestionColegiatura);
