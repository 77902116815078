import React, { Component } from 'react';
import { Row, Col, Card, Form, Input, Button, message, Modal, InputNumber } from 'antd';
import HabilitarColegiadoService from '../../services/api/colegiados';
import RucService from '../../services/api/ruc';
import DniService from '../../services/api/dni';
import Helpers from '../../utils/helper';
import moment from 'moment';
import { HighlightOutlined, LinkOutlined } from '@ant-design/icons';
import './styles.css';
const FormItem = Form.Item;

class IndependienteFormulario extends Component {
  state = {
    guardando: false,
    uploading: false,
    buscando_numero_documento: false,
    es_afiliado_fondo_mutual: true,
    codigo_afiliado: '',
    es_afiliado_fondo_mutual_negocios: false,
    estado_fondo_mutual_formato: 'Afiliado',
    fecha_afiliado_fondo_mutual: moment().format('DD/MM/YYYY'),
    cuenta_id: '',
    adelanto: '',
    validators: {
      nombres: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length + 1 <= 300,
            message: 'El campo acepta 300 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      adelanto: {
        rules: [
          {
            test: (value) => String(value).trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      numero_documento: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[0-9 ]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => value.trim().length < 12,
            message: 'El campo acepta 11 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      codigo_afiliado: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  handleChangeNumeroDocumento = (e, fieldName) => {
    this.setState(
      {
        numero_documento: e.target.value,
      },
      () => {
        // if (this.state.numero_documento.trim().length === 8) {
        //   this.buscarDni();
        // } else if (this.state.numero_documento.trim().length === 11) {
        //   this.buscarRuc();
        // }
      }
    );

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeNombres = (e, fieldName) => {
    this.setState({
      nombres: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeMontoAdelantar = (value, fieldName) => {
    this.setState({
      adelanto: value,
    });

    this.uploadValidators(fieldName, value);
  };

  handleChangeCodigoAfiliacion = (e, fieldName) => {
    this.setState({
      codigo_afiliado: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleClickBuscarDocumento = () => {
    if (this.state.numero_documento) {
      if (this.state.numero_documento.trim().length === 8) {
        this.buscarDni();
      }
      if (this.state.numero_documento.trim().length === 11) {
        this.buscarRuc();
      }
    } else {
      message.error('Por favor número documento no cumplete para buscar en (SUNAT O RENIEC)');
    }
  };

  handleAceptar = () => {
    window.location.reload(true);
  };

  buscarRuc = () => {
    this.setState({ buscando_numero_documento: true });
    RucService.getRuc(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.razonSocial}`,
            nombres_temp: `${response.data.razonSocial}`,
          });
        }
        if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  buscarDni = () => {
    this.setState({ buscando_numero_documento: true });
    DniService.getDni(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
            nombres_temp: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
          });
        } else if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  handleSend = () => {
    const valid = this.isFormValid();
    if (valid) {
      this.setState({ guardando: true });
      const data = this.state;
      data.id = this.state.habilitado.id;

      HabilitarColegiadoService.createCuentaMontoAdelantar(
        {
          id: data.id,
          cuenta_id: data.cuenta_id,
          adelanto: data.adelanto,
          codigo_afiliado: data.codigo_afiliado,
        },
        (response) => {
          if (response) {
            Modal.success({
              title: 'Formulario enviado correctamente',
              content: null,
              onOk: this.handleAceptar,
            });
          }
          this.setState({ guardando: false });
        },
        (err) => {
          if (err) {
            message.error(err.message);
          }
          this.setState({ guardando: false });
        }
      );
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleLimpiar = () => {
    this.setState(
      {
        numero_documento: null,
        guardando: false,
        uploading: false,
        buscando_numero_documento: false,
      },
      () => {
        this.handleAceptar();
      }
    );
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  getFormatoHTMLDeErrores = (validator) => {
    if (validator && !validator.valid && validator.errors.length > 0) {
      const errors = validator.errors.map((info, index) => <li key={index}>{info}</li>);
      return <ul className="errores">{errors}</ul>;
    }
    return '';
  };

  handleClickValidar = () => {
    this.buscarCuentaNO();
  };

  buscarCuentaNO = () => {
    if (this.state.numero_documento) {
      this.setState({ validando_codigo_afiliado: true });
      const data = this.state;
      HabilitarColegiadoService.getStoreClienteFind(
        data,
        (response) => {
          if (response) {
            this.setState(
              {
                validando_codigo_afiliado: false,
                es_afiliado_fondo_mutual_negocios: response.es_afiliado_fondo_mutual,
                cuenta_id: response.id ? response.id : '',
              },
              () => {
                if (response.cuenta_existe) {
                  this.bucarColegioadoHabilitado();
                } else {
                  message.error('Colegiado no existe.');
                }
              }
            );
          } else {
            this.setState({ validando_codigo_afiliado: false });
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              validando_codigo_afiliado: false,
            });
          }
        }
      );
    } else {
      message.error('Ingrese número documento.');
    }
  };

  bucarColegioadoHabilitado = () => {
    if (this.state.numero_documento) {
      this.setState({ validando_codigo_afiliado: true });
      const data = this.state;
      HabilitarColegiadoService.getFindColegiadoHabilitado(
        data.numero_documento,
        (response) => {
          if (response) {
            if (response.beneficiarios && response.beneficiarios.length > 0) {
              message.error('El colegioado ya tiene beneficiarios.');
              this.setState({
                validando_codigo_afiliado: false,
                nombres: null,
                beneficiarios: [],
                habilitado: null,
                files_declaracion_jurada_temp: [],
                files_poliza_temp: [],
              });
            } else {
              this.setState({
                validando_codigo_afiliado: false,
                nombres: response.nombres,
                beneficiarios: response.beneficiarios,
                habilitado: response,
                files_declaracion_jurada_temp:
                  response.files && response.files.length > 0
                    ? response.files.find((item) => item.modulo === 'declaracion_jurada')
                      ? [response.files.find((item) => item.modulo === 'declaracion_jurada')]
                      : []
                    : [],
                files_poliza_temp:
                  response.files && response.files.length > 0
                    ? response.files.find((item) => item.modulo === 'poliza')
                      ? [response.files.find((item) => item.modulo === 'poliza')]
                      : []
                    : [],
              });
            }
          } else {
            this.setState({ validando_codigo_afiliado: false });
            message.error('No se encontro al colegiado.');
          }
        },
        (error) => {
          if (error) {
            message.error(error.message);
            this.setState({
              validando_codigo_afiliado: false,
            });
          }
        }
      );
    } else {
      message.error('Ingrese número documento.');
    }
  };

  render() {
    return (
      <>
        <div className="colegiatura__header">
          <div className="container-sm">
            <img src="https://www.ccpll.org/assets/img/logo.png" alt="Logo" />
          </div>
        </div>
        <div className="container-sm">
          <Card
            className="colegiatura"
            type="inner"
            title={
              <div>
                <HighlightOutlined /> &nbsp; Ingresa tus datos
              </div>
            }
            extra={null}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem
                    label="Número Documento"
                    validateStatus={this.state.validators.numero_documento.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <Input
                      onChange={(e) => this.handleChangeNumeroDocumento(e, 'numero_documento')}
                      value={this.state.numero_documento}
                      autoComplete="off"
                      id="numero_documento"
                      placeholder="Ingrese la número documento."
                    />

                    {this.mostrarErroresValidacion('numero_documento')}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem
                    label="Código de Afiliado"
                    validateStatus={this.state.validators.codigo_afiliado.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <Input.Group compact>
                      <Input
                        onChange={(e) => this.handleChangeCodigoAfiliacion(e, 'codigo_afiliado')}
                        style={{ width: `calc(100% - ${this.state.validando_codigo_afiliado ? '98.5px' : '76.5px'})` }}
                        value={this.state.codigo_afiliado}
                        autoComplete="off"
                        id="codigo_afiliado"
                        placeholder="Ingrese Código de Afiliado."
                      />
                      <Button type="primary" onClick={this.handleClickValidar} loading={this.state.validando_codigo_afiliado}>
                        Validar
                      </Button>
                    </Input.Group>

                    {this.mostrarErroresValidacion('codigo_afiliado')}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem
                    label="Nombre Completo"
                    validateStatus={this.state.validators.nombres.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <Input
                      showCount
                      maxLength={300}
                      onChange={(e) => this.handleChangeNombres(e, 'nombres')}
                      value={this.state.nombres}
                      autoComplete="off"
                      id="nombres"
                      placeholder="Ingrese su nombre completo."
                    />
                    {this.mostrarErroresValidacion('nombres')}
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <br />
            <Card
              className="colegiatura-file"
              type="inner"
              title={
                <div>
                  <LinkOutlined /> &nbsp; Ingreso monto a adelantar
                </div>
              }
              extra={null}
            >
              <Row>
                <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                  <FormItem
                    label="Monto adelantar"
                    validateStatus={this.state.validators.adelanto.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <InputNumber
                      onChange={(e) => this.handleChangeMontoAdelantar(e, 'adelanto')}
                      value={this.state.adelanto}
                      autoComplete="off"
                      id="adelanto"
                      placeholder="Ingrese monto."
                      style={{ width: '100%' }}
                    />
                    {this.mostrarErroresValidacion('adelanto')}
                  </FormItem>
                </Col>
              </Row>
            </Card>
            <br />
            <div className="m-pl-16">
              <Button type="primary" onClick={this.handleSend} htmlType="submit" loading={this.state.guardando} size="large">
                Enviar
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleLimpiar} size="large">
                Limpiar
              </Button>
            </div>
          </Card>
          <br />
        </div>
      </>
    );
  }
}

export default IndependienteFormulario;
