import React from 'react';
import Table from '../components/table/Table';
import customerList from '../assets/JsonData/customers-list.json';
import Ojo from '../assets/images/Ojo.png';

const customerTableHead = [
  'N°',
  'Tipo Tramite',
  'Ticket',
  'RUC/DNI',
  'Nombres',
  'Correo',
  'Teléfono',
  'Fecha Documento',
  'Fecha Limíte',
  'Estado',
  '',
];

const renderHead = (item, index) => <th key={index}>{item}</th>;

const renderBody = (item, index) => (
  <tr key={index}>
    <td>{item.id}</td>
    <td>{item.name}</td>
    <td>{item.email}</td>
    <td>{item.location}</td>
    <td>Gustavo Zárate</td>
    <td>info@contasiscorp.com</td>
    <td>999888777</td>
    <td>23/11/2021</td>
    <td>27/11/2021</td>
    <td>EN PROCESO</td>
    <td>
      <img src={Ojo} alt="Ojo" />
    </td>
  </tr>
);

const Customers = () => {
  return (
    <div>
      <h2 className="page-header">Registro mesa de partes</h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Table
                limit="10"
                headData={customerTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={customerList}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
