import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import App from './App';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/lib/locale-provider/es_ES';

import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './assets/css/grid.css';
import './assets/css/theme.css';
import './assets/css/index.css';
// import 'antd/dist/antd.css';

document.title = 'Ccpll';

ReactDOM.render(
  <ConfigProvider locale={es_ES}>
    <App />
  </ConfigProvider>,
  document.getElementById('app_ccppll')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
