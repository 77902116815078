import ConfiguracionService from '../../services/api/configuraciones';

// Tipos de acciones
export const CARGAR_CONFIGURACION_EXITO = 'CARGAR_CONFIGURACION_EXITO';
export const CARGANDO_CONFIGURACION = 'CARGANDO_CONFIGURACION';

// Creadores de acción
export const cargarConfiguracionExito = (configuracion) => {
  return { type: CARGAR_CONFIGURACION_EXITO, configuracion };
};

export const cargandoConfiguracion = (estado) => {
  return { type: CARGANDO_CONFIGURACION, estado };
};

export const cargarConfiguracion = (path = '', callback) => {
  return function (dispatch) {
    dispatch(cargandoConfiguracion(true));
    return ConfiguracionService.getConfiguracions(path)
      .then(
        (configuracion) => {
          if (configuracion) {
            if (callback) {
              callback(configuracion);
            }
            dispatch(cargandoConfiguracion(false));
            dispatch(cargarConfiguracionExito(configuracion));
          }
        },
        (error) => {
          if (error) {
            dispatch(cargandoConfiguracion(false));
            throw error;
          }
        }
      )
      .catch((error) => {
        throw error;
      });
  };
};
