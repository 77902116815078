import React, { Component } from 'react';
import { Row, Col, Card, Select, Form, Input, Button, message, Upload, Modal, Checkbox, Radio, DatePicker } from 'antd';
import HabilitarColegiadoService from '../../services/api/colegiados';
import RucService from '../../services/api/ruc';
import DniService from '../../services/api/dni';
import Helpers from '../../utils/helper';
import ApiConfig from '../../services/api/config';
import { UploadOutlined, HighlightOutlined, LoadingOutlined, LinkOutlined } from '@ant-design/icons';
import BeneficiarioFormulario from './BeneficiarioFormulario';
import ConfiguracionService from '../../services/api/configuraciones';
import moment from 'moment';
import './styles.css';
const FormItem = Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';

class HabilitarColegiaturaFormulario extends Component {
  state = {
    tipo_colegiatura_formato: null,
    tipo_colegiatura_codigo: null,
    nombres: null,
    tipo_documento_formato: null,
    tipo_documento_codigo: null,
    numero_documento: null,
    grado_instruccion: null,
    correo_electronico: null,
    telefono: null,
    fecha_1: null,
    fecha_2: null,
    fecha_colegiatura_1: '',
    fecha_colegiatura_2: '',
    guardando: false,
    files_titulo: [],
    files_fotografia: [],
    files_declaracion_jurada_no_afiliacion: [],
    files_dni: [],
    files_documento: [],
    uploading: false,
    buscando_numero_documento: false,
    es_afiliado_fondo_mutual: false,
    es_fecha_colegiatura_1: false,
    es_fecha_colegiatura_2: false,
    universidad: '',
    fecha_egreso: '',
    fecha_nacimiento: '',
    hobbies: [],
    validators: {
      tipo_documento_codigo: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      tipo_colegiatura_codigo: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      nombres: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length + 1 <= 300,
            message: 'El campo acepta 300 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      grado_instruccion: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      universidad: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      correo_electronico: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => {
              if (value.trim().length > 0) {
                if (/^[^@]+@[^]+\..+/.test(value)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            },
            message: 'El campo acepta solo correo electrónico.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      telefono: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/,
            message: 'El campo acepta solo télefono.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      fecha_egreso: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          }
        ],
        errors: [],
        valid: true,
        state: '',
      },
      hobbies: {
        rules: [
          {
            test: (value) => value.toString().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      fecha_nacimiento: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          }
        ],
        errors: [],
        valid: true,
        state: '',
      },
      numero_documento: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[0-9 ]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => {
              let valid = true;
              if (this.state.tipo_documento_codigo === '6') {
                if (value.trim().length === 11) {
                  valid = true;
                } else {
                  valid = false;
                }
              }
              return valid;
            },
            message: 'El campo acepta 11 caracteres.',
          },
          {
            test: (value) => {
              let valid = true;
              if (this.state.tipo_documento_codigo === '1') {
                if (value.trim().length === 8) {
                  valid = true;
                } else {
                  valid = false;
                }
              }
              return valid;
            },
            message: 'El campo acepta 8 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_titulo: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (value && value.length > 0) {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_fotografia: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (value && value.length > 0) {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_declaracion_jurada_no_afiliacion: {
        rules: [
          {
            test: (value) => {
              if (this.state.es_afiliado_fondo_mutual) {
                let valid = false;
                if (value && value.length > 0) {
                  valid = true;
                }
                return valid;
              } else {
                return true;
              }
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_dni: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (value && value.length > 0) {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      files_documento: {
        rules: [
          {
            test: (value) => {
              let valid = false;
              if (this.state.tipo_colegiatura_codigo === '2') {
                if (value && value.length > 0) {
                  valid = true;
                }
              } else {
                valid = true;
              }
              return valid;
            },
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  componentDidMount() {
    if (this.props.location) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const es_header = queryString.get('es_header');
        this.setState({
          es_header,
        });
      }
    }
    this.cargandoConfiguracions();
  }

  cargandoConfiguracions = () => {
    this.setState({ cargando: true });
    ConfiguracionService.getConfiguracions(
      '',
      (configuracion) => {
        if (configuracion) {
          this.setState({
            cargando: false,
            fecha_1: configuracion.fecha_1,
            fecha_2: configuracion.fecha_2,
          });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
        }
        this.setState({ cargando: false });
      }
    );
  };

  setFormBeneficioFormulario = (ref) => {
    this.formBeneficioFormularioRef = ref;
  };

  handleChangeTipoColegiatura = (value, opt) => {
    this.setState({
      tipo_colegiatura_codigo: value,
      tipo_colegiatura_formato: opt.children,
    });

    this.uploadValidators('tipo_colegiatura_codigo', value);
  };

  handleChangeTipoDocumento = (value, opt) => {
    this.setState(
      {
        tipo_documento_codigo: value,
        tipo_documento_formato: opt.children,
      },
      () => {
        this.uploadValidators('numero_documento', this.state.numero_documento);
      }
    );

    this.uploadValidators('tipo_documento_codigo', value);
  };

  handleChangeNombres = (e, fieldName) => {
    this.setState({
      nombres: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeGradoInstruccion = (e, fieldName) => {
    this.setState({
      grado_instruccion: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeUniversidad = (e, fieldName) => {
    this.setState({
      universidad: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeFechaEgreso = (date, fieldName) => {
    let fecha_egreso = '';
    if (date) {
      fecha_egreso = moment(date).format('DD/MM/YYYY');
    }
    this.setState({
      fecha_egreso: fecha_egreso,
    }, () => {
      this.uploadValidators(fieldName, fecha_egreso);
    });
  };

  handleChangeFechaNacimiento = (date, fieldName) => {
    let fecha_nacimiento = '';
    if (date) {
      fecha_nacimiento = moment(date).format('DD/MM/YYYY');
    }
    this.setState({
      fecha_nacimiento: fecha_nacimiento,
    }, () => {
      this.uploadValidators(fieldName, fecha_nacimiento);
    });
  };

  handleChangeHobbies = (value) => {
    this.setState({
      hobbies: value,
    });
    this.uploadValidators('hobbies', value);
  };

  handleChangeCorreoElectronico = (e, fieldName) => {
    this.setState({
      correo_electronico: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeTelefono = (e, fieldName) => {
    this.setState({
      telefono: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeNumeroDocumento = (e, fieldName) => {
    this.setState(
      {
        numero_documento: e.target.value,
      },
      () => {
        if (this.state.numero_documento.trim().length === 8) {
          this.buscarDni();
        } else if (this.state.numero_documento.trim().length === 11) {
          this.buscarRuc();
        }
      }
    );

    this.uploadValidators(fieldName, e.target.value);
  };

  handleClickBuscarDocumento = () => {
    if (this.state.tipo_documento_codigo === '1') {
      if (this.state.numero_documento.trim().length === 8) {
        this.buscarDni();
      }
    } else if (this.state.tipo_documento_codigo === '6') {
      if (this.state.numero_documento.trim().length === 11) {
        this.buscarRuc();
      }
    } else {
      message.error('Por favor número documento no cumplete para buscar en (SUNAT O RENIEC)');
    }
  };

  handleAceptar = () => {
    window.location.reload(true);
  };

  buscarRuc = () => {
    this.setState({ buscando_numero_documento: true });
    RucService.getRuc(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.razonSocial}`,
            nombres_temp: `${response.data.razonSocial}`,
          });
        }
        if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  buscarDni = () => {
    this.setState({ buscando_numero_documento: true });
    DniService.getDni(
      this.state.numero_documento,
      (response) => {
        if (response && response.success) {
          this.setState({
            buscando_numero_documento: false,
            nombres: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
            nombres_temp: `${response.data.nombres} ${response.data.apellidoPaterno} ${response.data.apellidoMaterno}`,
          });
        } else if (!response.success) {
          message.error(response.msg);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
          this.setState({
            buscando_numero_documento: false,
            nombres: '',
            nombres_temp: '',
          });
        }
      }
    );
  };

  handleSend = () => {
    const valid = this.isFormValid();
    if (valid) {
      this.setState({ guardando: true });
      HabilitarColegiadoService.createHabilitarColegiado(
        this.state,
        (response) => {
          if (response) {
            Modal.success({
              title: 'Formulario enviado correctamente',
              content: <div style={{ fontSize: '13px' }}>{`Gracias su ticket de atención es el N° ${response.id}.`}</div>,
              onOk: this.handleAceptar,
            });
          }
          this.setState({ guardando: false });
        },
        (err) => {
          if (err) {
            message.error(err.message);
          }
          this.setState({ guardando: false });
        }
      );
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleLimpiar = () => {
    this.setState(
      {
        tipo_colegiatura_formato: null,
        tipo_colegiatura_codigo: null,
        nombres: null,
        tipo_documento_formato: null,
        tipo_documento_codigo: null,
        numero_documento: null,
        grado_instruccion: null,
        correo_electronico: null,
        telefono: null,
        guardando: false,
        files_titulo: [],
        files_fotografia: [],
        files_declaracion_jurada_no_afiliacion: [],
        files_dni: [],
        files_documento: [],
        uploading: false,
        buscando_numero_documento: false,
        universidad: '',
        fecha_egreso: '',
        fecha_nacimiento: '',
        hobbies: []
      },
      () => {
        this.handleAceptar();
      }
    );
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  handleUpload = () => {
    const { files } = this.state;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files[]', file);
    });
    this.setState({
      uploading: true,
    });
    // You can use any AJAX library you like
    fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        this.setState({
          files: [],
        });
        message.success('upload successfully.');
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        this.setState({
          uploading: false,
        });
      });
  };

  onClickCeremonia = () => {
    window.open(`${ApiConfig.constants.URL_API}/ceremonia.pdf`, '_blank');
  };

  onClickDeclaracionJurada = () => {
    window.open(`${ApiConfig.constants.URL_API}/no_afiliacion.pdf`, '_blank');
  };

  onChangeDeseaAfiliarse = (e) => {
    // if (e.target.checked) {
    //   message.error('Aun no esta habilitado esta opción.');
    //   return true;
    // }
    const valid = this.isFormValid();
    if (valid) {
      this.setState({ es_afiliado_fondo_mutual: e.target.value });
    } else {
      message.destroy();
      message.error('Verifique que no haya campos vacíos.');
    }
  };

  onChangeDeseaAfiliarseNo = (e) => {
    this.setState({ es_afiliado_fondo_mutual: e.target.value });
  };

  onChangeFechaColegiatura1 = (e) => {
    this.setState({
      es_fecha_colegiatura_1: e.target.checked,
      fecha_colegiatura_1: this.state.fecha_1,
      fecha_colegiatura_2: '',
      es_fecha_colegiatura_2: false,
    });
  };

  onChangeFechaColegiatura2 = (e) => {
    this.setState({
      es_fecha_colegiatura_2: e.target.checked,
      fecha_colegiatura_1: '',
      fecha_colegiatura_2: this.state.fecha_1,
      es_fecha_colegiatura_1: false,
    });
  };

  render() {
    const { uploading, files_titulo, files_fotografia, files_dni, files_documento, files_declaracion_jurada_no_afiliacion } =
      this.state;
    const props_titulos = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_titulo.indexOf(file);
          const newFileList = state.files_titulo.slice();
          newFileList.splice(index, 1);
          return {
            files_titulo: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_titulo.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_titulo: [...state.files_titulo, file],
            }),
            () => {
              this.uploadValidators('files_titulo', this.state.files_titulo);
            }
          );
        }
        return false;
      },
      files_titulo,
    };

    const props_fotografias = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_fotografia.indexOf(file);
          const newFileList = state.files_fotografia.slice();
          newFileList.splice(index, 1);
          return {
            files_fotografia: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_fotografia.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_fotografia: [...state.files_fotografia, file],
            }),
            () => {
              this.uploadValidators('files_fotografia', this.state.files_fotografia);
            }
          );
        }
        return false;
      },
      files_fotografia,
    };

    const props_declaracion_jurada_no_afiliaciones = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_declaracion_jurada_no_afiliacion.indexOf(file);
          const newFileList = state.files_declaracion_jurada_no_afiliacion.slice();
          newFileList.splice(index, 1);
          return {
            files_declaracion_jurada_no_afiliacion: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_declaracion_jurada_no_afiliacion.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_declaracion_jurada_no_afiliacion: [...state.files_declaracion_jurada_no_afiliacion, file],
            }),
            () => {
              this.uploadValidators('files_declaracion_jurada_no_afiliacion', this.state.files_declaracion_jurada_no_afiliacion);
            }
          );
        }
        return false;
      },
      files_declaracion_jurada_no_afiliacion,
    };

    const props_dnis = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_dni.indexOf(file);
          const newFileList = state.files_dni.slice();
          newFileList.splice(index, 1);
          return {
            files_dni: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_dni.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_dni: [...state.files_dni, file],
            }),
            () => {
              this.uploadValidators('files_dni', this.state.files_dni);
            }
          );
        }
        return false;
      },
      files_dni,
    };

    const props_documentos = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.files_documento.indexOf(file);
          const newFileList = state.files_documento.slice();
          newFileList.splice(index, 1);
          return {
            files_documento: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('¡El documento debe de ser menor a 2MB!');
        }

        const isCountFile = this.state.files_documento.length < 1;
        if (!isCountFile) {
          message.error('¡Solo se puede subir cantidad de archivos: 1!');
          return true;
        }

        const esValido = isLt2M;

        if (esValido) {
          this.setState(
            (state) => ({
              files_documento: [...state.files_documento, file],
            }),
            () => {
              this.uploadValidators('files_documento', this.state.files_documento);
            }
          );
        }
        return false;
      },
      files_documento,
    };
    return (
      <>
        {this.state.es_header === 'true' && (
          <div className="colegiatura__header">
            <div className="container-sm">
              <img src="https://www.ccpll.org/assets/img/logo.png" alt="Logo" />
            </div>
          </div>
        )}

        {this.state.es_afiliado_fondo_mutual && (
          <BeneficiarioFormulario
            onChangeDeseaAfiliarseNo={this.onChangeDeseaAfiliarseNo}
            es_afiliado_fondo_mutual={this.state.es_afiliado_fondo_mutual}
            colegiatura={this.state}
            ref={this.setFormBeneficioFormulario}
          />
        )}
        <div className="container-sm" style={{ display: `${this.state.es_afiliado_fondo_mutual ? 'none' : 'block'}` }}>
          {/* <div className="colegiatura__title">Mesa de Partes Virtual</div> */}
          <Card
            className="colegiatura"
            type="inner"
            title={
              <div>
                <HighlightOutlined /> &nbsp; Ingresa tus datos de inscripción
              </div>
            }
            extra={null}
          >
            <Form layout="vertical">
              <FormItem
                label="Tipo de Colegiatura"
                validateStatus={this.state.validators.tipo_colegiatura_codigo.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Select style={{ width: '100%' }} onChange={this.handleChangeTipoColegiatura} placeholder="Seleccione">
                  <Option value="1">Nuevo Miembro</Option>
                  <Option value="2">Traslado De Otro Colegio</Option>
                </Select>
                {this.mostrarErroresValidacion('tipo_colegiatura_codigo')}
              </FormItem>
              <FormItem
                label="Tipo Documento:"
                validateStatus={this.state.validators.tipo_documento_codigo.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Select style={{ width: '100%' }} onChange={this.handleChangeTipoDocumento} placeholder="Seleccione">
                  <Option value="1">DOCUMENTOS NACIONAL DE IDENTIDAD (DNI)</Option>
                  <Option value="6">REGISTRO ÚNICO DE CONTRIBUYENTES</Option>
                  <Option value="7">PASAPORTE</Option>
                  <Option value="4">CARNET DE EXTRANJERIA</Option>
                  <Option value="0">PERMISO TEMPORAL DE PERMANENCIA</Option>
                  <Option value="0">OTROS TIPOS DE DOCUMENTOS</Option>
                </Select>
                {this.mostrarErroresValidacion('tipo_documento_codigo')}
              </FormItem>
              <FormItem
                label="Número Documento"
                validateStatus={this.state.validators.numero_documento.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Input.Group compact>
                  <Input
                    onChange={(e) => this.handleChangeNumeroDocumento(e, 'numero_documento')}
                    style={{ width: `calc(100% - ${this.state.buscando_numero_documento ? '117.5px' : '97.5px'})` }}
                    value={this.state.numero_documento}
                    autoComplete="off"
                    id="numero_documento"
                    placeholder="Ingrese la descripción de su archivo."
                  />
                  <Button type="primary" onClick={this.handleClickBuscarDocumento} loading={this.state.buscando_numero_documento}>
                    Consultar
                  </Button>
                </Input.Group>

                {this.mostrarErroresValidacion('numero_documento')}
              </FormItem>
              <FormItem
                label="Nombre Completo"
                validateStatus={this.state.validators.nombres.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Input
                  showCount
                  maxLength={300}
                  onChange={(e) => this.handleChangeNombres(e, 'nombres')}
                  value={this.state.nombres}
                  autoComplete="off"
                  id="nombres"
                  placeholder="Ingrese su nombre completo."
                />
                {this.mostrarErroresValidacion('nombres')}
              </FormItem>
              <FormItem
                label="Grado de Instrucción"
                validateStatus={this.state.validators.grado_instruccion.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Input
                  onChange={(e) => this.handleChangeGradoInstruccion(e, 'grado_instruccion')}
                  value={this.state.grado_instruccion}
                  autoComplete="off"
                  id="grado_instruccion"
                  placeholder="Ingrese Grado de Instrucción"
                />
                {this.mostrarErroresValidacion('grado_instruccion')}
              </FormItem>
              <FormItem
                label="Correo Electrónico"
                validateStatus={this.state.validators.correo_electronico.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Input
                  onChange={(e) => this.handleChangeCorreoElectronico(e, 'correo_electronico')}
                  value={this.state.correo_electronico}
                  autoComplete="off"
                  id="correo_electronico"
                  placeholder="Ingrese el correo electrónico"
                />
                {this.mostrarErroresValidacion('correo_electronico')}
              </FormItem>
              <FormItem
                label="Télefono"
                validateStatus={this.state.validators.telefono.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Input
                  onChange={(e) => this.handleChangeTelefono(e, 'telefono')}
                  value={this.state.telefono}
                  autoComplete="off"
                  id="telefono"
                  placeholder="Ingrese el télefono"
                />
                {this.mostrarErroresValidacion('telefono')}
              </FormItem>
              <FormItem
                label="Universidad"
                validateStatus={this.state.validators.universidad.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Input
                  onChange={(e) => this.handleChangeUniversidad(e, 'universidad')}
                  value={this.state.universidad}
                  autoComplete="off"
                  id="universidad"
                  placeholder="Ingrese la universidad en que estudió"
                />
                {this.mostrarErroresValidacion('universidad')}
              </FormItem>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem
                    label="Fecha de egreso"
                    validateStatus={this.state.validators.fecha_egreso.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <DatePicker
                      value={
                      this.state.fecha_egreso && this.state.fecha_egreso !== 'Invalid date'
                        ? moment(this.state.fecha_egreso, dateFormat)
                        : null
                      }
                      format={dateFormat}
                      id="fecha_egreso"
                      placeholder="DD/MM/YYYY"
                      onChange={(e) => this.handleChangeFechaEgreso(e, 'fecha_egreso')}
                    />
                    {this.mostrarErroresValidacion('fecha_egreso')}
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem
                    label="Fecha de nacimiento"
                    validateStatus={this.state.validators.fecha_nacimiento.valid ? 'success' : 'error'}
                    help={this.state.errors}
                    required={true}
                  >
                    <DatePicker
                      value={
                        this.state.fecha_nacimiento && this.state.fecha_nacimiento !== 'Invalid date'
                          ? moment(this.state.fecha_nacimiento, dateFormat)
                          : null
                      }
                      format={dateFormat}
                      id="fecha_nacimiento"
                      placeholder="DD/MM/YYYY"
                      onChange={(e) => this.handleChangeFechaNacimiento(e, 'fecha_nacimiento')}
                    />
                    {this.mostrarErroresValidacion('fecha_nacimiento')}
                  </FormItem>
                </Col>
              </Row>
              <FormItem
                label="Hobbies"
                validateStatus={this.state.validators.hobbies.valid ? 'success' : 'error'}
                help={this.state.errors}
                required={true}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  help={this.state.errors}
                  placeholder="Escriba sus hobbies"
                  onChange={this.handleChangeHobbies}
                  value={this.state.hobbies}
                  notFoundContent="No se ha encontrado registros"
                >
                  <Option value="Ajedrez">Ajedrez</Option>
                  <Option value="Atletismo">Atletismo</Option>
                  <Option value="Basket">Basket</Option>
                  <Option value="Billar">Billar</Option>
                  <Option value="Billas">Billas</Option>
                  <Option value="Bochas">Bochas</Option>
                  <Option value="Canto">Canto</Option>
                  <Option value="Ciclismo">Ciclismo</Option>
                  <Option value="Danza">Danza</Option>
                  <Option value="Futbol">Futbol</Option>
                  <Option value="Maratón">Maratón</Option>
                  <Option value="Música">Música</Option>
                  <Option value="Natación">Natación</Option>
                  <Option value="Sapo">Sapo</Option>
                  <Option value="Tenis campo">Tenis campo</Option>
                  <Option value="Tenis fronton">Tenis fronton</Option>
                  <Option value="Vóley">Vóley</Option>
                  <Option value="Tejos">Tejos</Option>
                </Select>
                {this.mostrarErroresValidacion('hobbies')}
              </FormItem>
            </Form>

            <Card
              className="colegiatura-file"
              type="inner"
              title={
                <div>
                  <LinkOutlined /> &nbsp; Protocolo de ceremonia
                </div>
              }
              extra={null}
            >
              <Row>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <Button onClick={this.onClickCeremonia} icon={<UploadOutlined />}>
                    Descargar protocolo de ceremonia
                    {uploading && <LoadingOutlined />}
                  </Button>
                </Col>
              </Row>
            </Card>
            <br />
            <Card
              className="colegiatura-file"
              type="inner"
              title={
                <div>
                  <LinkOutlined /> &nbsp; Archivo a Adjuntar
                </div>
              }
              extra={null}
            >
              <Row>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> Título Profesional:
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>2 MB por archivo Cantidad de archivos: 1</div>
                  <Upload {...props_titulos}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_titulo')}
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> Fotografía a colores (Fondo Blanco):
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>2 MB por archivo Cantidad de archivos: 1</div>
                  <Upload {...props_fotografias}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_fotografia')}
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                  <div className="colegiatura-label-left">
                    <span style={{ color: 'red' }}>*</span> DNI (Ambos Lados):
                  </div>
                  <div style={{ fontSize: 12, paddingBottom: 4 }}>2 MB por archivo Cantidad de archivos: 1</div>
                  <Upload {...props_dnis}>
                    <Button icon={<UploadOutlined />}>
                      Seleccionar archivo
                      {uploading && <LoadingOutlined />}
                    </Button>
                  </Upload>
                  {this.mostrarErroresValidacion('files_dni')}
                </Col>
                {this.state.tipo_colegiatura_codigo === '2' && (
                  <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <div className="colegiatura-label-left">
                      {this.state.tipo_colegiatura_codigo === '2' && <span style={{ color: 'red' }}>*</span>}
                      Documento que acrédite colegiatura en otro colegio:
                    </div>
                    <div style={{ fontSize: 12, paddingBottom: 4 }}>2 MB por archivo Cantidad de archivos: 1</div>
                    <Upload {...props_documentos}>
                      <Button icon={<UploadOutlined />}>
                        Seleccionar archivo
                        {uploading && <LoadingOutlined />}
                      </Button>
                    </Upload>
                    {this.mostrarErroresValidacion('files_documento')}
                  </Col>
                )}
              </Row>
            </Card>
            <br />
            {/* <div>
              <Row>
                {this.state.fecha_1 && (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Checkbox checked={this.state.es_fecha_colegiatura_1} onChange={this.onChangeFechaColegiatura1}>
                      {this.state.fecha_1}
                    </Checkbox>
                  </Col>
                )}
                {this.state.fecha_2 && (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Checkbox checked={this.state.es_fecha_colegiatura_2} onChange={this.onChangeFechaColegiatura2}>
                      {this.state.fecha_2}
                    </Checkbox>
                  </Col>
                )}
              </Row>
            </div> */}

            <Card
              className="colegiatura-file"
              type="inner"
              title={
                <div>
                  <HighlightOutlined /> &nbsp; Seleccione fecha a colegiarse
                </div>
              }
              extra={null}
            >
              <Row>
                {this.state.fecha_1 && (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Checkbox checked={this.state.es_fecha_colegiatura_1} onChange={this.onChangeFechaColegiatura1}>
                      {this.state.fecha_1}
                    </Checkbox>
                  </Col>
                )}
                {this.state.fecha_2 && (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Checkbox checked={this.state.es_fecha_colegiatura_2} onChange={this.onChangeFechaColegiatura2}>
                      {this.state.fecha_2}
                    </Checkbox>
                  </Col>
                )}
              </Row>
            </Card>

            <br />
            <div>
              <Card
                className="colegiatura-file"
                type="inner"
                title={
                  <div>
                    <LinkOutlined /> &nbsp; ¿Desea afiliarse al Seguro Mutual?
                  </div>
                }
                extra={null}
              >
                <Row>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Radio.Group onChange={this.onChangeDeseaAfiliarse} value={this.state.es_afiliado_fondo_mutual}>
                      <Radio value={true}>SI</Radio>
                      <Radio value={false}>NO</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Card>
              {!this.state.es_afiliado_fondo_mutual && (
                <>
                  <br />
                  <Card
                    className="colegiatura-file"
                    type="inner"
                    title={
                      <div>
                        <LinkOutlined /> &nbsp; Archivo a Adjuntar
                      </div>
                    }
                    extra={null}
                  >
                    <Row>
                      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <div className="colegiatura-label-left">
                           Documento de muestra:
                        </div>
                        <Button onClick={this.onClickDeclaracionJurada} icon={<UploadOutlined />}>
                          Declaración Jurada
                          {uploading && <LoadingOutlined />}
                        </Button>
                      </Col>
                      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                        <div className="colegiatura-label-left">
                          <span style={{ color: 'red' }}>*</span> Declaración Jurada no Afiliación:
                        </div>
                        <div style={{ fontSize: 12, paddingBottom: 4 }}>2 MB por archivo Cantidad de archivos: 1</div>
                        <Upload {...props_declaracion_jurada_no_afiliaciones}>
                          <Button icon={<UploadOutlined />}>
                            Seleccionar archivo
                            {uploading && <LoadingOutlined />}
                          </Button>
                        </Upload>
                        {this.mostrarErroresValidacion('files_declaracion_jurada_no_afiliacion')}
                      </Col>
                    </Row>
                  </Card>
                </>
              )}
            </div>
            <br />
            <div className="m-pl-16">
              <Button type="primary" onClick={this.handleSend} htmlType="submit" loading={this.state.guardando} size="large">
                Enviar
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleLimpiar} size="large">
                Limpiar
              </Button>
            </div>
          </Card>
          <br />
        </div>
      </>
    );
  }
}

export default HabilitarColegiaturaFormulario;
