import Page from './page';

export default {
  constants: {
    URL_API: Page.constants.URL_API,
    ACCESS_TOKEN_KEY: Page.constants.ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY: Page.constants.REFRESH_TOKEN_KEY,
    ACCESS_TOKEN_EXPIRES_IN: Page.constants.ACCESS_TOKEN_EXPIRES_IN,
    ACCESS_TOKEN_EXPIRES_AT: Page.constants.ACCESS_TOKEN_EXPIRES_AT,
    USUARIO_ID: Page.constants.USUARIO_ID,
    API_VERSION: Page.constants.API_VERSION,
    URL_CUENTA_CONTASISCORP: Page.constants.URL_CUENTA_CONTASISCORP,
    URL_NEGOCIOS: Page.constants.URL_NEGOCIOS,
    EMPRESA_ID: Page.constants.EMPRESA_ID,
  },
  methods: {
    checkStatus: function (response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        if (response.status === 401) {
          localStorage.clear();
          document.location.href = '/';
        }

        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        throw error;
      }
    },
    parseJSON: function (response) {
      return response.json();
    },
    parseText: function (response) {
      return response.text();
    },
    error: function (error) {
      if (error.response) {
        return error.response.json();
      }
      return undefined;
    },
  },
};
