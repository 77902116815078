import React, { Component } from 'react';
import { Modal, Layout } from 'antd';

const { Content } = Layout;
class ImprimirModal extends Component {
  render() {
    const mimeType = this.props.mime_type || 'application/pdf';
    return (
      <Modal title="Vista Preliminar" visible={this.props.visible} onCancel={this.props.onCancel} width={830} footer={null}>
        <Layout>
          <Content id="pdf-print">
            <embed type={mimeType} src={this.props.url} style={{ width: 800, height: 500 }} />
          </Content>
        </Layout>
      </Modal>
    );
  }
}

export default ImprimirModal;
