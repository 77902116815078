import React, { Component } from 'react';
import { Form, Input, Button, message, Table, InputNumber, Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import Helpers from '../../utils/helper';
import './styles.css';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 8 },
  },
};

const dateFormat = 'DD/MM/YYYY';

class FraccionarFormulario extends Component {
  state = {
    id: this.props.fraccionar && this.props.fraccionar.id ? this.props.fraccionar.id : '',
    del: this.props.fraccionar && this.props.fraccionar.del ? this.props.fraccionar.del : moment().add(1, 'months').format('DD/MM/YYYY'),
    importe_total: this.props.importe_total ? this.props.importe_total: 0,
    porcentaje: 10,
    importe: this.props.importe_total ? parseFloat(this.props.importe_total) / 10 : 0,
    importe_final: this.props.importe_total ? this.props.importe_total - (parseFloat(this.props.importe_total) / 10): 0,
    numero_cuota: 1,
    del_temp: moment().add(1, 'months'),
    fraccionados: [],
    validators: {
      porcentaje: {
        rules: [
          {
            test: (value) => value > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: /^[0-9]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => parseFloat(value) <= 90,
            message: 'El campo debe ser menor igual a 90.',
          },
          {
            test: (value) => parseFloat(value) >= 10,
            message: 'El campo debe ser mayor igual a 10.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      importe: {
        rules: [
          {
            test: (value) => value > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      importe_final: {
        rules: [
          {
            test: (value) => value > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      del: {
        rules: [
          // {
          //   test: (value) => value.trim().length > 0,
          //   message: 'Campo obligatorio.'
          // },
          // {
          //   test: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ]*$/,
          //   message: 'El campo acepta solo alfanuméricos.'
          // },
          // {
          //   test: (value) => value.trim().length <= 3,
          //   message: 'El campo acepta 3 caracteres.'
          // }
        ],
        errors: [],
        valid: true,
        state: '',
      },
      importe_total: {
        rules: [
          {
            test: (value) => value > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      numero_cuota: {
        rules: [
          {
            test: (value) => {
              if (value < 13) {
                return true;
              } else {
                return false;
              }
            },
            message: 'Número máximo es 12',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  resetForm = () => {
    this.setState({
      id: '',
      del: '',
      importe_total: '',
    });
  };

  componentDidMount ()  {
  }

  handleChangeDel = (date, fieldName) => {
    let del;
    if (date) {
      del = moment(date).format('DD/MM/YYYY');
    }

    this.setState({
      del: del,
      del_temp: moment(date),
    });
  };

  handleChangeNumeroCuota = (value, fieldName) => {
    this.setState({
      numero_cuota: value,
    }, () => {
      this.handleClickCalcular();
    });

    this.uploadValidators(fieldName, value);
  };

  handleChangeImporte = (e, fieldName) => {
    this.setState({
      importe_total: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangePorcentaje = (e, fieldName) => {
    this.setState({
      porcentaje: parseInt(e.target.value),
      importe: this.props.importe_total ? parseFloat(this.props.importe_total) * (parseInt(e.target.value) / 100) : 0,
      importe_final: this.props.importe_total ? this.props.importe_total - (parseFloat(this.props.importe_total) * (parseInt(e.target.value) / 100)): 0,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeImporteFinal = (e, fieldName) => {
    this.setState({
      importe_final: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeImporteMonto = (e, fieldName) => {
    this.setState({
      importe: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleSave = (data) => {
    const valid = this.isFormValid();
    if (valid) {
      this.props.onFormSubmit({
        id: this.state.id,
        del: this.state.del || '',
        importe_total: this.state.importe_total || 0,
        porcentaje: this.state.porcentaje || 0,
        importe: this.state.importe || 0,
        importe_final: this.state.importe_final || 0,
        numero_cuota: this.state.numero_cuota,
        fraccionados: this.state.fraccionados,
      });
      this.nameInput.focus();
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleCancel = () => {
    this.props.onFormCancel();
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  handleClickCalcular = () => {
    this.setState({
      fraccionados: []
    }, () => {
      const objetos = [];
      for (let i = 1; i <= this.state.numero_cuota; i++) {
        const del_temp = moment().add(1, 'months');
        const objeto = { fecha_orden: del_temp.add(i-1, 'month').format('DD/MM/YYYY'),  total: this.state.importe_final / this.state.numero_cuota };
        objetos.push(objeto);
      }

      this.setState({
        fraccionados: objetos
      });
  });
  }

  render() {
    const columns = [
      {
        title: 'FECHA',
        dataIndex: 'fecha_orden',
        key: 'fecha_orden',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'IMPORTE',
        dataIndex: 'total',
        key: 'total',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      }
    ];

    return (
      <Form>
        <FormItem
          {...formItemLayout}
          label="Importe Total"
          validateStatus={this.state.validators.importe_total.valid ? 'success' : 'error'}
          help={this.state.errors}
          required={true}
        >
          <Input disabled={true} onChange={(e) => this.handleChangeImporte(e, 'importe_total')} value={this.state.importe_total} autoComplete="off" autoFocus id="importe_total" />
          {this.mostrarErroresValidacion('importe_total')}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Porcentaje %"
          validateStatus={this.state.validators.porcentaje.valid ? 'success' : 'error'}
          help={this.state.errors}
          required={true}
        >
          <Input style={{ width: 100 }} onChange={(e) => this.handleChangePorcentaje(e, 'porcentaje')} value={this.state.porcentaje} autoComplete="off" autoFocus id="porcentaje" />
          &nbsp;&nbsp;&nbsp;<span style={{ fontWeight: '500'}}>Monto:</span>&nbsp; <Input style={{ width: 100 }} value={this.state.importe} autoComplete="off" autoFocus id="porcentaje" />
          {this.mostrarErroresValidacion('porcentaje')}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Importe Final"
          validateStatus={this.state.validators.importe_final.valid ? 'success' : 'error'}
          help={this.state.errors}
          required={true}
        >
          <Input value={this.state.importe_final} autoComplete="off" autoFocus id="importe_final" />
          {this.mostrarErroresValidacion('importe_final')}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Del"
          validateStatus={this.state.validators.del.valid ? 'success' : 'error'}
          help={this.state.errors}
          // required={ true }
        >
          <DatePicker
            value={
              this.state.del && this.state.del !== 'Invalid date' ? moment(this.state.del, dateFormat) : null
            }
            format={dateFormat}
            id="del"
            placeholder="DD/MM/YYYY"
            onChange={(e) => this.handleChangeDel(e, 'del')}
            disabled={true}
          />
          {this.mostrarErroresValidacion('del')}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Nro. Cuotas"
          validateStatus={this.state.validators.numero_cuota.valid ? 'success' : 'error'}
          help={this.state.errors}
          // required={ true }
        >
          <Row gutter={24}>
            <Col span={12}>
              <InputNumber
                onChange={(e) => this.handleChangeNumeroCuota(e, 'numero_cuota')}
                value={this.state.numero_cuota}
                autoComplete="off"
                id="numero_cuota"
                style={{ width: '100%' }}
                max={12}
                ref={(input) => {
                  this.nameInput = input;
                }}
              />
            </Col>
            <Col span={12}>
              <Button type='primary' onClick={this.handleClickCalcular}>Calcular</Button>
            </Col>
          </Row>
          {this.mostrarErroresValidacion('numero_cuota')}
        </FormItem>
        <FormItem>
          <Table
            style={{ marginTop: 12 }}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.fraccionados}
            pagination={false}
            size="small"
            locale={{
              emptyText: 'No se ha encontrado registros',
            }}
          />
        </FormItem>
        <FormItem {...formItemLayoutWithOutLabel} label="">
          <Button type="primary" onClick={this.handleSave} htmlType="submit" loading={this.props.guardando} disabled={this.state.fraccionados.length === 0}>
            Guardar
          </Button>
          <Button style={{ marginLeft: 8 }} onClick={this.handleCancel}>
            Cancelar
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default FraccionarFormulario;
