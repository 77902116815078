import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, message, Pagination, Alert, Button, Select, DatePicker, Dropdown, Menu } from 'antd';
import ReporteService from '../../../services/api/reportes';
import helper from '../../../utils/helper';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import apiConfig from '../../../services/api/config';
import ImprimirModal from '../../../components/ImprimirModal/ImprimirModal';

class IndexPagoPendientes extends Component {
  state = {
    cargando: false,
    reportes: [],
    tipo_servicio_id: 'CuotaOrdinaria',
    tipo_servicio_formato: 'Cuota Ordinaria',
    de: moment().format('YYYY-MM-DD'),
    fecha_fin_temp: moment(),
    a: moment().format('YYYY-MM-DD'),
    fecha_inicio_temp: moment(),
    openImprimirModal: false,
    page: {
      number: 1,
      per_page: 15,
      order_column: 'created_at',
      order_type: 'desc',
      from: 0,
      to: 0,
      last_page: 0,
      total: 0,
      buscar_texto: '',
    },
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleChangeLocation);
    if (this.props.location && this.props.location.search) {
      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString) {
        const buscar_texto = queryString.get('buscar_texto');
        const configPage = Object.assign({}, this.state.page);
        configPage.buscar_texto = buscar_texto;
        this.setState({
          page: configPage,
        });
      }
    }

    this.cargarPagosPendientes();
  }

  handleChangeSearch = (value) => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = value.target.value;
    this.setState({
      page: configPage,
      buscar_texto: value.target.value
    }, () => {
      this.cargarPagosPendientes();
    });
  }

  handleChangeSearchIcon = () => {
    const configPage = Object.assign({}, this.state.page);
    configPage.buscar_texto = this.state.buscar_texto;
    this.setState({
      page: configPage,
      buscar_texto: this.state.buscar_texto
    }, () => {
      this.cargarPagosPendientes();
    });
  }


  cargarPagosPendientes = (path = undefined) => {
    this.setState({
      cargando: true,
    });

    if (!path || path === undefined) {
      path = `?page=${this.state.page.number}&per_page=${this.state.page.per_page}&order_column=${this.state.page.order_column}&order_type=${this.state.page.order_type}&tipo_servicio_id=${this.state.tipo_servicio_id}&&tipo_servicio_formato=${this.state.tipo_servicio_formato}&de=${this.state.de}&a=${this.state.a}`;
      if (this.state.page && this.state.page.buscar_texto && this.state.page.buscar_texto !== '') {
        path = `${path}&buscar_texto=${this.state.page.buscar_texto}`;
      }
    }

    ReporteService.getReportes(
      path,
      (page) => {
        if (page && page.data && page.data.length > 0) {
          const configPage = Object.assign({}, this.state.page);
          configPage.from = page.from;
          configPage.to = page.to;
          configPage.last_page = page.last_page;
          configPage.total = page.total;
          configPage.number = page.current_page;
          configPage.per_page = parseInt(page.per_page);
          configPage.buscar_texto = page.buscar_texto ? page.buscar_texto : '';

          this.setState({
            cargando: false,
            reportes: page.data,
            page: configPage,
          });
        } else {
          this.setState({
            reportes: [],
            cargando: false,
          });
        }
      },
      (error, result) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  handleChangeLocation = (location, action) => {
    const queryString = new URLSearchParams(location.search);
    const buscar_texto = queryString.get('buscar_texto');

    if (buscar_texto && buscar_texto != null) {
      const page = Object.assign({}, this.state.page);

      page.buscar_texto = buscar_texto;

      this.setState({
        page,
      });

      setTimeout(() => {
        this.cargarPagosPendientes();
      }, 10);
    }
  };

  handleChangePerPage = (current, pageSize) => {
    const page = Object.assign({}, this.state.page);
    page.number = current;
    page.per_page = pageSize;
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&tipo_servicio_id=${this.state.tipo_servicio_id}&tipo_servicio_formato=${this.state.tipo_servicio_formato}&de=${this.state.de}&a=${this.state.a}`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    this.props.history.push(path);
    this.cargarPagosPendientes(path);
  };

  showTotal = (total) => {
    return `Total ${total} registros`;
  };

  handleClickExportarPDF = () => {
    const page = Object.assign({}, this.state.page);
    let path = `?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&tipo_servicio_id=${this.state.tipo_servicio_id}&tipo_servicio_formato=${this.state.tipo_servicio_formato}&de=${this.state.de}&a=${this.state.a}`;
    if (page.buscar_texto !== undefined || page.buscar_texto !== '') {
      path = `${path}&buscar_texto=${page.buscar_texto}`;
    }
    const token =  null;
    const url_exportar = `${apiConfig.constants.URL_NEGOCIOS}/api/${apiConfig.constants.API_VERSION}/reporte/cobranzas/cuota-ordinaria/pdf${path}&authorization=${token}&empresa_id=${apiConfig.constants.EMPRESA_ID}`;
    window.open(url_exportar);
  };

  handleChangeTipo = (value, opt) => {
    this.setState({
      tipo_servicio_id: value,
      tipo_servicio_formato: opt.label
    }, () => {
      this.cargarPagosPendientes();
    });
  };

  onChangeInicio = (value) => {
    this.setState({
      de: value.format('YYYY-MM-DD'),
      fecha_inicio_temp: value
    }, () => {
      this.cargarPagosPendientes();
    });
  }

  onChangeFin = (value) => {
    this.setState({
      a: value.format('YYYY-MM-DD'),
      fecha_fin_temp: value
    }, () => {
      this.cargarPagosPendientes();
    });
  }

  handleExportar = (value) => {
    const page = Object.assign({}, this.state.page);
    const empresa_id =apiConfig.constants.EMPRESA_ID;
    const token = null;
    let printPDFUrl = null;

    if (value === '1') {
      printPDFUrl = `${apiConfig.constants.URL_NEGOCIOS}/api/${apiConfig.constants.API_VERSION}/reporte/cobranzas/forma-pago?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&tipo_servicio_id=${this.state.tipo_servicio_id}&tipo_servicio_formato=${this.state.tipo_servicio_formato}&de=${this.state.de}&a=${this.state.a}&empresa_id=${empresa_id}&authorization=${token}`;
    } else {
      printPDFUrl = `${apiConfig.constants.URL_NEGOCIOS}/api/${apiConfig.constants.API_VERSION}/reporte/cobranzas/tipo-servicios?page=${page.number}&per_page=${page.per_page}&order_column=${page.order_column}&order_type=${page.order_type}&tipo_servicio_id=${this.state.tipo_servicio_id}&tipo_servicio_formato=${this.state.tipo_servicio_formato}&de=${this.state.de}&a=${this.state.a}&empresa_id=${empresa_id}&authorization=${token}`;
    }
    this.setState({
      printPDFUrl,
      openImprimirModal: true
    })
  }

  handleClickCloseImprimirModal = (value) => {
    this.setState({
      openImprimirModal: false
    })
  }


  render() {
    const columns = [
      {
        title: 'N°',
        dataIndex: 'name',
        key: 'name',
        responsive: ['xl'],
        width: 50,
        render: (text, record, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        title: 'Comprobante',
        dataIndex: 'serie_formato',
        key: 'serie_formato',
        responsive: ['lg', 'xl'],
      },
      {
        title: 'Cliente',
        dataIndex: 'cuenta_formato',
        responsive: ['lg', 'xl'],
        ellipsis: true,
      },      
      {
        title: 'Fecha',
        dataIndex: 'fecha_cobranza',
        key: 'fecha_cobranza',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
      {
        title: 'S/. Importe',
        dataIndex: 'importe_cobrado',
        key: 'importe_cobrado',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
        render: (text) => (
          <div>
            {text}
          </div>
        ),
      },
      {
        title: 'Doc. Identidad',
        dataIndex: 'documento_identidad',
        key: 'documento_identidad',
        responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      },
    ];

    const menu = (
      <Menu>
        {
          <Menu.Item key="1" onClick={() => this.handleExportar('1')}>
            Reporte Resumen Agrupado por Forma de Pago
          </Menu.Item>
        }
        {
          <Menu.Item key="2" onClick={() => this.handleExportar('2')}>
            Informe Ingresos Percibidos - Detallado
          </Menu.Item>
        }
      </Menu>
    );

    return (
      <div>
        <div className="page-header">
          <h2 className="page-header">Informe Ingresos Percibidos - Detallado</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 6 }}>
            <div className="topnav__search">
              <input
                type="text"
                placeholder="Buscar..."
                onChange={this.handleChangeSearch}
                value={ this.state.buscar_texto }
              />
              <i onClick={() => this.handleChangeSearchIcon()} className="bx bx-search" style={{cursor: 'pointer'}}></i>
            </div>
            <div>
              <Select
              value={this.state.tipo_servicio_id}
                onChange={this.handleChangeTipo}
                options={[
                  {
                    value: 'CuotaOrdinaria',
                    label: 'Cuota Ordinaria',
                  },
                  {
                    value: 'Capacitaciones',
                    label: 'Capacitaciones',
                  },
                  {
                    value: 'HabilitacionDeColegiatura',
                    label: 'Habilitación de Colegiatura',
                  },
                  {
                    value: 'FondoMutual',
                    label: 'Fondo Mutual',
                  },
                  {
                    value: 'FondoMutualDeFallecido',
                    label: 'Fondo Mutual de Fallecido',
                  },
                  {
                    value: 'AdelantoFondoMutual',
                    label: 'Adelanto Fondo Mututal',
                  },
                  {
                    value: 'Fraccionamiento',
                    label: 'Fraccionamiento',
                  },
                ]}
              />
            </div>
            <div>
            <DatePicker value={this.state.fecha_inicio_temp} onChange={this.onChangeInicio} />
            </div>
            <div>
            <DatePicker value={this.state.fecha_fin_temp} onChange={this.onChangeFin} />
            </div>
            <div>
              <Button onClick={this.handleClickExportarPDF} type="primary" shape="round" icon={<DownloadOutlined />} size={null}>
                Descargar PDF
              </Button>
            </div>
            <div>
            <Dropdown overlay={menu} placement="bottomRight">
              <Button>Otros Reportes</Button>
            </Dropdown>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {!helper.tienePermiso(this.props.permisos, 'Ver registro pagos pendientes') ? (
                <Alert
                  message="Permiso"
                  description="No tiene permiso para ver lista de pagos pendientes"
                  type="error"
                />):
                <div className="card__body">
                  <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={this.state.reportes}
                    pagination={false}
                    loading={this.state.cargando}
                    size="small"
                  />
                  <div style={{ paddingTop: 12, textAlign: 'right' }}>
                    <Pagination
                      size="small"
                      showTotal={this.showTotal}
                      total={this.state.page.total}
                      current={this.state.page.number}
                      pageSize={this.state.page.per_page}
                      onChange={this.handleChangePerPage}
                      onShowSizeChange={this.handleChangePerPage}
                      pageSizeOptions={[10, 20, 30, 40]}
                      showQuickJumper
                      showSizeChanger
                    />
                  </div>
                </div>
              }              
            </div>
          </div>
        </div>
        {this.state.openImprimirModal ? (
          <ImprimirModal
            visible={this.state.openImprimirModal}
            onCancel={this.handleClickCloseImprimirModal}
            url={this.state.printPDFUrl}
            mime_type="application/pdf"
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permisos: state && state.user && state.user.permisos.length > 0 ? state.user.permisos : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexPagoPendientes);
