import React, { Component } from 'react';
import { Form, Input, Button, message, Select, Row, Col, InputNumber, DatePicker, Spin } from 'antd';
import ColegiadoService from '../../services/api/colegiados';
import UserService from '../../services/api/users';
import Helpers from '../../utils/helper';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 24, offset: 8 },
  },
};

const dateFormat = 'DD/MM/YYYY';

class ReciboPagoFormulario extends Component {
  state = {
    id: this.props.recibo_pago && this.props.recibo_pago.id ? this.props.recibo_pago.id : '',
    tipo_documento:
      this.props.recibo_pago && this.props.recibo_pago.tipo_documento ? this.props.recibo_pago.tipo_documento : 'Recibo de pago',
    tipo_documento_formato:
      this.props.recibo_pago && this.props.recibo_pago.tipo_documento_formato
        ? this.props.recibo_pago.tipo_documento_formato
        : 'Recibo de pago',
    tipo_recibo: this.props.recibo_pago && this.props.recibo_pago.tipo_recibo ? this.props.recibo_pago.tipo_recibo : '',
    tipo_recibo_formato:
      this.props.recibo_pago && this.props.recibo_pago.tipo_recibo_formato ? this.props.recibo_pago.tipo_recibo_formato : '',
    cliente_id: '',
    cliente_numero_documento:
      this.props.recibo_pago && this.props.recibo_pago.cliente_numero_documento
        ? this.props.recibo_pago.cliente_numero_documento
        : '',
    cliente_formato:
      this.props.recibo_pago && this.props.recibo_pago.cliente_formato ? this.props.recibo_pago.cliente_formato : '',
    forma_pago: this.props.recibo_pago && this.props.recibo_pago.forma_pago ? this.props.recibo_pago.forma_pago : '',
    forma_pago_formato:
      this.props.recibo_pago && this.props.recibo_pago.forma_pago_formato ? this.props.recibo_pago.forma_pago_formato : '',
    concepto: this.props.recibo_pago && this.props.recibo_pago.concepto ? this.props.recibo_pago.concepto : '',
    monto: this.props.recibo_pago && this.props.recibo_pago.monto ? this.props.recibo_pago.monto : '0',
    vendedor_id: this.props.recibo_pago && this.props.recibo_pago.vendedor_id ? this.props.recibo_pago.vendedor_id : '',
    vendedor_formato:
      this.props.recibo_pago && this.props.recibo_pago.vendedor_formato ? this.props.recibo_pago.vendedor_formato : '',
    serie_numero: this.props.recibo_pago && this.props.recibo_pago.serie_numero ? this.props.recibo_pago.serie_numero : '',
    descuentos: 0,
    fecha_emision: moment().format('DD/MM/YYYY'),
    fecha_aprobacion: '',
    porcentaje: 0,
    validators: {
      serie_numero: {
        rules: [
          // {
          //   test: (value) => value.trim().length > 0,
          //   message: 'Campo obligatorio.',
          // },
          {
            test: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ]*$/,
            message: 'El campo acepta solo alfanuméricos.',
          },
          {
            test: (value) => value.trim().length <= 255,
            message: 'El campo acepta 255 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      concepto: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
          {
            test: (value) => value.trim().length <= 500,
            message: 'El campo acepta 500 caracteres.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      forma_pago: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      tipo_recibo: {
        rules: [
          {
            test: (value) => value.trim().length > 0,
            message: 'Campo obligatorio.',
          },
        ],
        errors: [],
        valid: true,
        state: '',
      },
      monto: {
        rules: [
          // {
          //   test: (value) => value.toString().trim().length > 0,
          //   message: 'Campo obligatorio.',
          // },
          // {
          //   test: (value) => value.trim().length <= 30,
          //   message: 'El campo acepta 30 caracteres.',
          // },
        ],
        errors: [],
        valid: true,
        state: '',
      },
    },
  };

  componentDidMount() {
    this.cargarColegiado();
    this.cargarUsers();
    this.cargarColegiados();
  }

  cargarColegiado = () => {
    this.setState({
      cargando: true,
    });
    ColegiadoService.getColegiado(
      this.props.colegiado_id,
      (colegiado) => {
        this.setState(
          {
            cargando: false,
            colegiado,
            tipo_recibo: this.props.tipo_recibo_pago_id,
            tipo_recibo_formato: this.props.tipo_recibo_id,
            cliente_id: this.props.cliente_id,
            cliente_numero_documento: this.props.cliente_numero_documento
              ? this.props.cliente_numero_documento
              : colegiado.numero_documento,
            cliente_formato: this.props.cliente_nombres ? this.props.cliente_nombres : colegiado.nombres,
            porcentaje: this.props.porcentaje,
            vendedor_id: this.props.user && this.props.user.id ? this.props.user.id : '',
            vendedor_formato: this.props.user && this.props.user.name ? this.props.user.name : '',
            colegiado_formato: this.props.colegiado_formato,
            colegiado_id: this.props.colegiado_id,
          },
          () => {
            this.cambiarValorConcepto();
          }
        );
      },
      (err, result) => {
        if (err) {
          message.error(err.message);
          this.setState({
            cargando: false,
          });
        }
      }
    );
  };

  cargarUsers = () => {
    this.setState({
      cargando_users: true,
    });
    UserService.getUsers(
      '',
      (users) => {
        if (users) {
          this.setState({
            cargando_users: false,
            users: users.data,
          });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando_users: false,
          });
        }
      }
    );
  };

  cargarColegiados = () => {
    this.setState({
      cargando_colegiados: true,
    });
    ColegiadoService.getColegiados(
      '?per_page=1500',
      (colegiados) => {
        if (colegiados) {
          this.setState({
            cargando_colegiados: false,
            colegiados: colegiados.data,
          });
        }
      },
      (error) => {
        if (error) {
          message.error(error.message);
          this.setState({
            cargando_colegiados: false,
          });
        }
      }
    );
  };

  handleChangeNumero = (e, fieldName) => {
    this.setState({
      serie_numero: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeConcepto = (e, fieldName) => {
    this.setState({
      concepto: e.target.value,
    });

    this.uploadValidators(fieldName, e.target.value);
  };

  handleChangeMonto = (value, fieldName) => {
    this.setState({
      monto: value,
    });

    this.uploadValidators(fieldName, value);
  };

  handleChangeFechaDocumento = (date, fieldName) => {
    let fecha_emision = '';
    if (date) {
      fecha_emision = moment(date).format('DD/MM/YYYY');
    }
    this.setState({
      fecha_emision: fecha_emision,
    });
  };

  handleChangeFechaAprovacion = (date, fieldName) => {
    let fecha_aprobacion = '';
    if (date) {
      fecha_aprobacion = moment(date).format('DD/MM/YYYY');
    }
    this.setState({
      fecha_aprobacion: fecha_aprobacion,
    });
  };

  handleChangeCajero = (value, opt) => {
    this.setState({
      vendedor_id: opt.props.value,
      vendedor_formato: opt.props.children,
    });
  };

  handleChangeTipoDocumento = (value, opt) => {
    this.setState({
      tipo_documento: opt.props.value,
      tipo_documento_formato: opt.props.children,
    });
  };

  handleChangeTipoReciboPago = (value, opt) => {
    this.setState(
      {
        tipo_recibo: opt.props.value,
        tipo_recibo_formato: opt.props.children,
      },
      () => {
        this.cambiarValorConcepto();
      }
    );
    this.uploadValidators('tipo_recibo', opt.props.value);
  };

  handleChangeFormaPago = (value, opt) => {
    this.setState({
      forma_pago: opt.props.value,
      forma_pago_formato: opt.props.children,
    });
    this.uploadValidators('forma_pago', opt.props.value);
  };

  handleChangeCliente = (value, opt) => {
    this.setState({
      cliente_id: opt.props.value,
      cliente_formato: opt.props.children,
    });
  };

  cambiarValorConcepto = () => {
    if (this.state.tipo_recibo === 'Beneficio de Fondo Mutual' && this.state.colegiado) {
      const monto =
        (this.state.colegiado.beneficio_monto - this.state.colegiado.adelanto - this.state.colegiado.deuda) *
        (this.state.porcentaje / 100);

      const concepto = `Por fallecimiento de ${this.state.colegiado_formato}, le corresponde el ${
        this.state.porcentaje
      }% del Beneficio del Seguro Mutual, ${
        this.state.colegiado && this.state.colegiado.adelanto
          ? 'menos un adelanto de S/ ' + this.state.colegiado.adelanto + ' y'
          : ''
      }  una deuda de ${this.state.colegiado && this.state.colegiado.deuda ? this.state.colegiado.deuda : '0'}.`;
      this.setState({
        concepto,
        monto,
      });
    }
  };

  handleSave = (data) => {
    const valid = this.isFormValid();
    if (valid) {
      this.props.onFormSubmit({
        id: this.state.id || '',
        tipo_documento: this.state.tipo_documento || '',
        tipo_documento_formato: this.state.tipo_documento_formato || '',
        tipo_recibo: this.state.tipo_recibo || '',
        tipo_recibo_formato: this.state.tipo_recibo_formato || '',
        cliente_numero_documento: this.state.cliente_numero_documento || '',
        serie_numero: this.state.serie_numero,
        cliente_id: this.state.cliente_id || '',
        cliente_formato: this.state.cliente_formato || '',
        forma_pago: this.state.forma_pago || '',
        forma_pago_formato: this.state.forma_pago_formato || '',
        concepto: this.state.concepto || '',
        monto: this.state.monto || '',
        vendedor_id: this.state.vendedor_id || '',
        vendedor_formato: this.state.vendedor_formato || '',
        fecha_emision: this.state.fecha_emision || '',
        fecha_aprobacion: this.state.fecha_aprobacion || '',
        descuentos: this.state.descuentos || '',
        colegiado_formato: this.state.colegiado_formato,
        colegiado_id: this.state.colegiado_id,
      });
    } else {
      message.destroy();
      message.error('No se puede guardar registro, verifique que no haya campos vacíos.');
    }
  };

  handleCancel = () => {
    history.back();
  };

  uploadValidators(fieldName, value) {
    const validators = Helpers.validation.uploadValidators(fieldName, value, this.state.validators);
    this.setState({
      validators,
    });
  }

  actualizarListaErrores = (errors) => {
    Helpers.validation.actualizarListaErrores(errors, this.state.validators);
  };

  mostrarErroresValidacion = (fieldName) => {
    return Helpers.validation.mostrarErroresValidacion(fieldName, this.state.validators);
  };

  isFormValid = () => {
    const respuesta = Helpers.validation.isFormValid(this.state.validators, this.state);
    if (respuesta && respuesta.validators) {
      this.setState({
        validators: respuesta.validators,
      });
    }
    return respuesta.status;
  };

  render() {
    let colegiados = [];
    if (this.state.colegiados && this.state.colegiados.length > 0) {
      colegiados = this.state.colegiados.map((item) => {
        return (
          <Option key={item.id} value={item.id} colegiado={item}>
            {item.nombres}
          </Option>
        );
      });
    }

    let users = [];
    if (this.state.users && this.state.users.length > 0) {
      users = this.state.users.map((item) => {
        return (
          <Option key={item.id} value={item.id} user={item}>
            {item.name}
          </Option>
        );
      });
      console.log(users);
    }

    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current > moment().endOf('day');
    };

    return (
      <Form>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem {...formItemLayout} label="Documento" help={this.state.errors} required={true}>
              {/* <Select
                showSearch
                optionFilterProp="children"
                value={this.state.tipo_documento}
                onChange={this.handleChangeTipoDocumento}
                placeholder="Seleccione un tipo de recibo"
                notFoundContent={'No se ha encontrado registros'}
              >
                <Option value="Recibo de pago">Recibo de pago</Option>
              </Select> */}
              <Input value={this.state.tipo_documento} />
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Número"
              validateStatus={this.state.validators.serie_numero.valid ? 'success' : 'error'}
              help={this.state.errors}
              required={true}
            >
              <Input
                onChange={(e) => this.handleChangeNumero(e, 'serie_numero')}
                value={this.state.serie_numero}
                autoComplete="off"
                disabled={true}
                id="serie_numero"
              />
              {this.mostrarErroresValidacion('serie_numero')}
            </FormItem>
            <FormItem
              {...formItemLayout}
              validateStatus={this.state.validators.tipo_recibo.valid ? 'success' : 'error'}
              label="Tipo de Recibo de Pago"
              help={this.state.errors}
              required={true}
            >
              <Select
                showSearch
                optionFilterProp="children"
                value={this.state.tipo_recibo}
                onChange={this.handleChangeTipoReciboPago}
                placeholder="Seleccione un tipo de recibo de pago"
                notFoundContent={'No se ha encontrado registros'}
              >
                {/* <Option value="Adelanto de Seguro Mutual">Adelanto de Seguro Mutual</Option>
                <Option value="Beneficio de Fondo Mutual">Beneficio de Fondo Mutual</Option> */}
              </Select>
              {this.mostrarErroresValidacion('tipo_recibo')}
            </FormItem>
            <FormItem {...formItemLayout} label="Cliente" help={this.state.errors} required={true}>
              <Input.Group compact>
                <Select
                  showSearch
                  optionFilterProp="children"
                  value={this.state.cliente_numero_documento}
                  onChange={this.handleChangeCliente}
                  placeholder="Seleccione un cliente"
                  notFoundContent={this.props.cargando_colegiados ? <Spin size="small" /> : 'No se ha encontrado registros'}
                  style={{ width: '40%' }}
                  dropdownMatchSelectWidth={false}
                >
                  {colegiados}
                </Select>
                <Input style={{ width: '60%' }} value={this.state.cliente_formato} />
              </Input.Group>
            </FormItem>
            <FormItem
              {...formItemLayout}
              validateStatus={this.state.validators.forma_pago.valid ? 'success' : 'error'}
              label="Forma de Pago"
              help={this.state.errors}
              required={true}
            >
              <Select
                showSearch
                optionFilterProp="children"
                value={this.state.forma_pago}
                onChange={this.handleChangeFormaPago}
                placeholder="Seleccione una forma de pago"
                notFoundContent={'No se ha encontrado registros'}
              >
                <Option value="Contado">Contado</Option>
                <Option value="Transferencia Bancaria">Transferencia Bancaria</Option>
              </Select>
              {this.mostrarErroresValidacion('forma_pago')}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Concepto"
              validateStatus={this.state.validators.concepto.valid ? 'success' : 'error'}
              help={this.state.errors}
              required={true}
            >
              <TextArea
                onChange={(e) => this.handleChangeConcepto(e, 'concepto')}
                value={this.state.concepto}
                autoComplete="off"
                rows={4}
                placeholder="Ingrese un concepto"
                id="concepto"
              />
              {this.mostrarErroresValidacion('concepto')}
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Monto"
              validateStatus={this.state.validators.monto.valid ? 'success' : 'error'}
              help={this.state.errors}
              required={true}
            >
              <InputNumber
                disabled={ this.state.tipo_recibo === 'Beneficio de Fondo Mutual' }
                onChange={(e) => this.handleChangeMonto(e, 'monto')}
                value={this.state.monto}
                autoComplete="off"
                id="monto"
                min={0}
                ref={(input) => {
                  this.montoInput = input;
                }}
              />
              {this.mostrarErroresValidacion('monto')}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem {...formItemLayout} label="Fecha Documento" help={this.state.errors} required={true}>
              <DatePicker
                value={
                  this.state.fecha_emision && this.state.fecha_emision !== 'Invalid date'
                    ? moment(this.state.fecha_emision, dateFormat)
                    : null
                }
                format={dateFormat}
                disabledDate={disabledDate}
                id="fecha_emision"
                placeholder="DD/MM/YYYY"
                onChange={(e) => this.handleChangeFechaDocumento(e, 'fecha_emision')}
              />
            </FormItem>
            <FormItem {...formItemLayout} label="Cajero" help={this.state.errors} required={true}>
              <Input value={this.state.vendedor_formato} />
              {/* <Select
                showSearch
                optionFilterProp="children"
                value={this.state.vendedor_id}
                onChange={this.handleChangeCajero}
                placeholder="Seleccione un vendedor"
                notFoundContent={'No se ha encontrado registros'}
              >
                {users}
              </Select> */}
            </FormItem>
            <FormItem {...formItemLayout} label="Fecha Aprobación" help={this.state.errors} required={false}>
              <DatePicker
                value={
                  this.state.fecha_aprobacion && this.state.fecha_aprobacion !== 'Invalid date'
                    ? moment(this.state.fecha_aprobacion, dateFormat)
                    : null
                }
                format={dateFormat}
                id="fecha_aprobacion"
                placeholder="DD/MM/YYYY"
                onChange={(e) => this.handleChangeFechaAprovacion(e, 'fecha_aprobacion')}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <FormItem {...formItemLayoutWithOutLabel} label="">
              <Button type="primary" onClick={this.handleSave} disabled={ this.state.tipo_recibo === 'Beneficio de Fondo Mutual' && this.state.monto === 0 } htmlType="submit" loading={this.props.guardando}>
                Guardar
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleCancel}>
                Cancelar
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ReciboPagoFormulario;
